window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.edit_preferences = window.locales.am.settings.edit_preferences || {};window.locales.am.settings.edit_preferences = {...window.locales.am.settings.edit_preferences, ...{
    "decimal_delim_desc": "የዴሲማል ቁጥሮች እንዴት እንደሚጽፉ ይምረጡ። ማስታወሻ፡ በትምህርት ጽሁፍ ላይ ቁጥሮች እንዴት ብለው እንደሚታዩ ተጽእኖ አይፈጥርም።",           
    "decimal_delim_title": "የዴሲማል ገቢ",           
    "locale_desc": "ተመራጭ ቋንቋዎን ይምረጡ",           
    "locale_title": "ተመራጭ ቋንቋ",           
    "preferences": "ምርጫዎች",           
    "sound_enabled_desc_extra": "ምንም የድምጽ ውጤቶችን መስማት ካልቻሉ፣ መሳሪያዎ ድምጹ እንዳልጠፋ ያረጋግጡ።",           
    "sound_enabled_title": "የድምጽ ዉጤቶች",           
    "update_preference_failure": "የእርስዎን ምርጫዎች በማዘመን ላይ ችግር ተፈጥሮ ነበር። እባክዎ ቆየት ብለው ይሞክሩ።",           
    "update_preference_success": "'{{title}}’ በተሳካ ሁኔታ ዘምኗል።",           
    "show_photos_names_desc": "በደመነፍስ የሆነ አድልዎን ለመቀነስ ስሞችን እና ፎቶዎችን እንደ አማራጭ ይደብቁ።",           
    "show_photos_names_title": "እጩ ፎቶዎች እና ስሞች",           
    "show_photos_names_enabled": "ፎቶዎች እና ስሞችን ያሳዩ",           
    "show_photos_names_disabled": "ፎቶዎች እና ስሞችን ይደብቁ",           
    "keyboard_shortcuts_title": "የኪቦርድ አጭር መንገዶች",           
    "one_click_reach_out_title": "ባለአንድ ጠቅታ የእጩ መድረሻ",           
    "one_click_reach_out_desc": "መጀመሪያ አንድ ቦታን ሳይገልጹ እጩዎችን ለማግኘት ያብሩ።",           
    "one_click_reach_out_enabled": "ነቅቷል",           
    "one_click_reach_out_disabled": "ተሰናክሏል",           
    "experimental": "በሙከራ ላይ",           
    "warning_only_use_if_instructed": "ማስጠንቀቂያ፡ በ {{brandName}} ድጋፍ ከተመራ ይህንን ባህርይ ብቻ ይጠቀሙ።",           
    "clear_local_db": "አካባቢያዊ ዳታቤዝን ያጽዱ",           
    "database_cleared": "ዳታቤዝ ጸድቷል",           
    "sound_enabled_desc": "ሁሉንም የድምጽ ውጤቶች ያንቁ።",           
    "keyboard_shortcuts_desc": "ወደሚቀጥለው ማያ ገጽ ለመሄድ እና ቁጥሮችን (1, 2, 3, ወዘተ.) የመልስ ምርጫዎችዎን ለመይምረጡ የሚያስፈልጉ እንደ enter/return ቁልፍ ያሉ የኪቦርድ አጭር መንገዶችን ያንቁ።",           
    "offline_mode_title": "ከመስመር ውጪ ሁነታ",           
    "offline_mode_desc": "ከመስመር ውጪ ጥናት ኮርሶችን መጫን ያስችሉ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.edit_preferences = window.locales.ar.settings.edit_preferences || {};window.locales.ar.settings.edit_preferences = {...window.locales.ar.settings.edit_preferences, ...{
    "decimal_delim_desc": "حدد كيف ستكتب الأرقام العشرية. ملاحظة: هذا لن يؤثر على كيفية عرض الأرقام في نص الدرس ، ولن ينطبق على الدروس في اللغة العربية.",           
    "decimal_delim_title": "الإدخال العشري",           
    "locale_desc": "حدد لغتك المفضلة",           
    "locale_title": "اللغة المفضلة",           
    "preferences": "التفضيلات",           
    "sound_enabled_title": "المؤثرات الصوتية",           
    "update_preference_failure": "لقد كانت هناك مشكلة في تحديث تفضيلاتك. يرجى إعادة المحاولة مرةً أخرى في وقتٍ لاحق.",           
    "update_preference_success": "تم تحديث '{{title}}' بنجاح.",           
    "show_photos_names_desc": "أسماء اخفاء اختياريا والصور للحد من التحيز وعيه.",
    "show_photos_names_title": "مرشح صور وأسماء",
    "show_photos_names_enabled": "عرض صور وأسماء",
    "show_photos_names_disabled": "إخفاء صور وأسماء",
    "sound_enabled_desc_extra": "إذا كنت لا تسمع أي مؤثرات صوتية، تأكد من أن الجهاز هو إلغاء كتم.",
    "keyboard_shortcuts_title": "اختصارات لوحة المفاتيح",
    "one_click_reach_out_title": "بنقرة واحدة مرشح الوصول",
    "one_click_reach_out_desc": "تمكين الاتصال المرشحين دون تحديد موقف أولا.",
    "one_click_reach_out_enabled": "مفّعلة",
    "one_click_reach_out_disabled": "معطلة",
    "experimental": "تجريبي",
    "clear_local_db": "مسح قاعدة البيانات المحلية",
    "database_cleared": "مسح قاعدة البيانات",
    "warning_only_use_if_instructed": "تحذير: لا تستخدم هذه الميزة إلا إذا تم توجيهها إلى الدعم {{brandName}} .",
    "sound_enabled_desc": "تفعيل جميع المؤثرات الصوتية.",
    "keyboard_shortcuts_desc": "قم بتمكين اختصارات لوحة المفاتيح ، مثل مفتاح الإدخال / العودة للتقدم إلى الشاشة التالية والأرقام (1 ، 2 ، 3 ، إلخ) لتحديد خيارات الإجابة.",
    "offline_mode_title": "وضع غير متصل بالشبكة",
    "offline_mode_desc": "تمكين تنزيل الدورات للدراسة في وضع عدم الاتصال."
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.edit_preferences = window.locales.en.settings.edit_preferences || {};window.locales.en.settings.edit_preferences = {...window.locales.en.settings.edit_preferences, ...{
    "decimal_delim_desc": "Select how you will type decimal numbers. Note: this will not affect how numbers are displayed in lesson text.",
    "decimal_delim_title": "Decimal Input",
    "locale_desc": "Select your preferred language",
    "locale_title": "Preferred Language",
    "preferences": "PREFERENCES",
    "sound_enabled_desc": "Enable all sound effects.",
    "sound_enabled_desc_extra": "If you can't hear any sound effects, make sure your device is unmuted.",
    "sound_enabled_title": "Sound Effects",
    "update_preference_failure": "There was a problem updating your preferences. Please try again later.",
    "update_preference_success": "'{{title}}' successfully updated.",
    "show_photos_names_desc": "Optionally hide names and photos to reduce unconscious bias.",
    "show_photos_names_title": "Candidate Photos & Names",
    "show_photos_names_enabled": "Show Photos and Names",
    "show_photos_names_disabled": "Hide Photos and Names",
    "keyboard_shortcuts_desc": "Enable keyboard shortcuts, such as the enter/return key to advance to the next screen and numbers (1, 2, 3, etc.) to select answer choices.",
    "keyboard_shortcuts_title": "Keyboard Shortcuts",
    "one_click_reach_out_title": "One-click candidate reach out",
    "one_click_reach_out_desc": "Enable contacting candidates without specifying a position first.",
    "one_click_reach_out_enabled": "Enabled",
    "one_click_reach_out_disabled": "Disabled",
    "experimental": "Experimental",
    "warning_only_use_if_instructed": "Warning: only use this feature if directed to by {{brandName}} support.",
    "clear_local_db": "Clear local database",
    "database_cleared": "Database cleared",
    "offline_mode_title": "Offline Mode",
    "offline_mode_desc": "Enable downloading of courses for offline study."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.edit_preferences = window.locales.es.settings.edit_preferences || {};window.locales.es.settings.edit_preferences = {...window.locales.es.settings.edit_preferences, ...{
    "decimal_delim_desc": "Selecciona cómo vas a escribir los números decimales. Nota: Esto no afectará cómo se muestran los números en el texto de la lección.",           
    "decimal_delim_title": "Entrada decimal",           
    "locale_desc": "Selecciona tu idioma preferido",           
    "locale_title": "Idioma preferido",           
    "preferences": "PREFERENCIAS",           
    "sound_enabled_title": "Efectos de sonido",           
    "update_preference_failure": "Ha habido un problema al actualizar tus preferencias. Por favor, inténtalo de nuevo más tarde.",           
    "update_preference_success": "{{title}}' actualizado con éxito.",           
    "show_photos_names_desc": "ocultar los nombres y las fotos opcionalmente para reducir el sesgo inconsciente.",
    "show_photos_names_title": "Candidatos Fotos y Nombres",
    "show_photos_names_enabled": "Mostrar fotos y los nombres",
    "show_photos_names_disabled": "Ocultar fotos y los nombres",
    "sound_enabled_desc_extra": "Si no se oye ningún efectos de sonido, asegúrese de que su dispositivo esté anulado.",
    "keyboard_shortcuts_title": "Atajos de teclado",
    "one_click_reach_out_title": "Un candidato con un solo clic se comunica",
    "one_click_reach_out_desc": "Permita que los candidatos entren en contacto sin antes especificar una posición.",
    "one_click_reach_out_enabled": "Habilitado",
    "one_click_reach_out_disabled": "Discapacitado",
    "experimental": "Experimental",
    "clear_local_db": "Borrar base de datos local",
    "database_cleared": "Base de datos borrada",
    "warning_only_use_if_instructed": "Advertencia: solo use esta función si se lo indica el soporte de {{brandName}} .",
    "sound_enabled_desc": "Habilita todos los efectos de sonido.",
    "keyboard_shortcuts_desc": "Habilite los atajos de teclado, como la tecla Intro / Retorno para avanzar a la siguiente pantalla y números (1, 2, 3, etc.) para seleccionar opciones de respuesta.",
    "offline_mode_title": "Modo offline",
    "offline_mode_desc": "Habilite la descarga de cursos para estudiar sin conexión."
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.edit_preferences = window.locales.it.settings.edit_preferences || {};window.locales.it.settings.edit_preferences = {...window.locales.it.settings.edit_preferences, ...{
    "decimal_delim_desc": "Seleziona come digitare i numeri decimali. Attenzione: questo non modificherà il modo in cui vengono mostrati nel testo delle lezioni.",           
    "decimal_delim_title": "Digitazione Decimali",           
    "locale_desc": "Seleziona la tua lingua preferita",           
    "locale_title": "Lingua Preferita",           
    "preferences": "PREFERENZE",           
    "sound_enabled_desc_extra": "Se non senti alcun effetto sonoro, assicurati che il tuo dispositivo non sia impostato su muto.",           
    "sound_enabled_title": "Effetti sonori",           
    "update_preference_failure": "Si è verificato un problema durante l'aggiornamento delle tue preferenze. Per favore, riprova.",           
    "update_preference_success": "{{title}}' aggiornato con successo.",           
    "show_photos_names_desc": "Scegli se nascondere foto e nomi per evitare di esserne inconsciamente influenzato",           
    "show_photos_names_title": "Foto e nomi Candidati",           
    "show_photos_names_enabled": "Mostra foto e nomi",           
    "show_photos_names_disabled": "Nascondi foto e nomi",           
    "keyboard_shortcuts_title": "Scorciatoie Tastiera",           
    "one_click_reach_out_title": "Il candidato con un clic raggiunge",
    "one_click_reach_out_desc": "Abilita il contatto con i candidati senza specificare prima una posizione.",
    "one_click_reach_out_enabled": "Abilitato",
    "one_click_reach_out_disabled": "Disabilitato",
    "experimental": "Sperimentale",
    "clear_local_db": "Cancella database locale",
    "database_cleared": "Database cancellato",
    "warning_only_use_if_instructed": "Avviso: utilizzare questa funzione solo se richiesto dal supporto {{brandName}} .",
    "sound_enabled_desc": "Abilita tutti gli effetti sonori.",
    "keyboard_shortcuts_desc": "Abilita le scorciatoie da tastiera, come il tasto Invio/Invio per avanzare alla schermata successiva e i numeri (1, 2, 3, ecc.) per selezionare le opzioni di risposta.",
    "offline_mode_title": "Modalità offline",
    "offline_mode_desc": "Abilita il download dei corsi per lo studio offline."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.edit_preferences = window.locales.zh.settings.edit_preferences || {};window.locales.zh.settings.edit_preferences = {...window.locales.zh.settings.edit_preferences, ...{
    "decimal_delim_desc": "选择您如何键入小数。注意：这不会影响课文中的数字显示方式。",           
    "decimal_delim_title": "小数输入",           
    "locale_desc": "选择您的偏好语言",           
    "locale_title": "偏好语言",           
    "preferences": "偏好设置",           
    "sound_enabled_title": "音效",           
    "update_preference_failure": "更新您的偏好设置时出现问题。请稍后再试。",           
    "update_preference_success": "“{{title}}”已成功更新。",           
    "show_photos_names_desc": "可选择隐藏姓名和照片，以减少无意识的偏见。",           
    "show_photos_names_title": "候选人照片及姓名",           
    "show_photos_names_enabled": "显示照片和姓名",           
    "show_photos_names_disabled": "隐藏照片和姓名",           
    "sound_enabled_desc_extra": "如果你听不到任何声音效果，请检查你的设备是否处于静音状态。",           
    "keyboard_shortcuts_title": "快捷键",           
    "one_click_reach_out_title": "只需点击一下就可联系对方",           
    "one_click_reach_out_desc": "允许在不事先说明职位的情况下联系候选人。",           
    "one_click_reach_out_enabled": "允许",           
    "one_click_reach_out_disabled": "不允许",           
    "experimental": "试验性",           
    "clear_local_db": "清晰的本地数据库",           
    "database_cleared": "数据库清理",           
    "warning_only_use_if_instructed": "提示：仅当{{brandName}}支持时使用此特性。",           
    "sound_enabled_desc": "启用所有音效。",
    "keyboard_shortcuts_desc": "启用键盘快捷键，例如输入/返回键以前进到下一个屏幕和数字（1、2、3 等）以选择答案选项。",
    "offline_mode_title": "离线模式",
    "offline_mode_desc": "允许下载课程以供离线学习。"
}
};