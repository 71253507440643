window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.change_password = window.locales.am.settings.change_password || {};window.locales.am.settings.change_password = {...window.locales.am.settings.change_password, ...{
    "change_password": "የይለፍ ቃል ይለውጡ",           
    "password": "የይለፍ ቃል",           
    "password_confirmation": "የይለፍ ቃል ማረጋገጫ",           
    "re_type": "ደግመው ይጻፉ",           
    "save_changes": "ለውጦችን ያስቀምጡ",           
    "password_updated": "የይለፍ ቃል በተሳካ ሁኔታ ዘምኗል።",           
    "try_again": "እባክዎ የይለፍ ቃልዎን እና ማረጋገጫዎን ይፈትሹ እና ደግመው ይሞክሩ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.change_password = window.locales.ar.settings.change_password || {};window.locales.ar.settings.change_password = {...window.locales.ar.settings.change_password, ...{
    "password": "كلمة المرور",           
    "password_confirmation": "تأكيد كلمة المرور",           
    "re_type": "إعادة الكتابة",           
    "save_changes": "حفظ التغييرات",           
    "password_updated": "تم تحديث كلمة المرور بنجاح.",           
    "try_again": "يرجى التحقق من كلمة المرور وتأكيدها والمحاولة مرةً أخرى.",           
    "change_password": "غير كلمة السر"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.change_password = window.locales.en.settings.change_password || {};window.locales.en.settings.change_password = {...window.locales.en.settings.change_password, ...{
    "change_password": "Change Password",
    "password": "Password",
    "password_confirmation": "Password Confirmation",
    "re_type": "Retype",
    "save_changes": "SAVE CHANGES",
    "password_updated": "Password successfully updated.",
    "try_again": "Please check your password and confirmation and try again."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.change_password = window.locales.es.settings.change_password || {};window.locales.es.settings.change_password = {...window.locales.es.settings.change_password, ...{
    "change_password": "Cambiar la contraseña",           
    "password": "Contraseña",           
    "password_confirmation": "Confirmación de contraseña",           
    "re_type": "Volver a escribir",           
    "save_changes": "GUARDAR CAMBIOS",           
    "password_updated": "Contraseña actualizada correctamente.",           
    "try_again": "Por favor, verifica la contraseña y la confirmación y vuelve a intentarlo."           
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.change_password = window.locales.it.settings.change_password || {};window.locales.it.settings.change_password = {...window.locales.it.settings.change_password, ...{
    "change_password": "Cambia password",           
    "password": "Password",           
    "password_confirmation": "Conferma Password",           
    "re_type": "Digita nuovamente",           
    "save_changes": "SALVA MODIFICHE",           
    "password_updated": "Password aggiornata con successo.",           
    "try_again": "Per favore, ricontrolla la password e la conferma; quindi riprova."           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.change_password = window.locales.zh.settings.change_password || {};window.locales.zh.settings.change_password = {...window.locales.zh.settings.change_password, ...{
    "change_password": "更改密码",           
    "password": "密码",           
    "password_confirmation": "密码确认",           
    "re_type": "重新键入",           
    "save_changes": "保存更改",           
    "password_updated": "已成功更新密码。",           
    "try_again": "请检查您的密码和密码确认，然后重试。"           
}
};