window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.transcripts = window.locales.am.settings.transcripts || {};window.locales.am.settings.transcripts = {...window.locales.am.settings.transcripts, ...{
    "official_transcript": "ኦፊሻል ትራንስክሪፕት",           
    "partial_transcript": "ፓርሻል ትራንስክሪፕት",           
    "mobile_desc": "ትራንስክሪፕቶችን ለማውረድ በዴስክቶፕ ማሳሻ ላይ {{brandName}}ን ያስጀምሩ።",           
    "official_desc": "የእርስዎን ኦፊሻል ትራንስክሪፕት የዲጂታል ቅጂ ከስር ያውርዱ። ለጥያቄዎች እና ለልዩ ጥያቄዎች፣ በ <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> ላይ ያግኙን",           
    "partial_desc": "የእርስዎን ፓርሻል ትራንስክሪፕት የዲጂታል ቅጂ ከስር ያውርዱ።",           
    "download_official_transcript": "ኦፊሻል ትራንስክሪፕት ያውርዱ",           
    "download_partial_transcript": "ፓርሻል ትራንስክሪፕት ያውርዱ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.transcripts = window.locales.ar.settings.transcripts || {};window.locales.ar.settings.transcripts = {...window.locales.ar.settings.transcripts, ...{
    "official_transcript": "النسخة الرسمية",
    "partial_transcript": "نسخة جزئية",
    "partial_desc": "قم تحميل نسخة رقمية من النص الجزئي الخاص بك أدناه.",
    "download_official_transcript": "تحميل النسخة الرسمية",
    "download_partial_transcript": "تحميل النص الجزئي",
    "mobile_desc": "قم بتشغيل {{brandName}} على متصفح سطح المكتب لتنزيل النصوص.",
    "official_desc": "قم بتنزيل نسخة رقمية من النص الرسمي أدناه. لطرح الأسئلة والطلبات الخاصة ، اتصل بنا على <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.transcripts = window.locales.en.settings.transcripts || {};window.locales.en.settings.transcripts = {...window.locales.en.settings.transcripts, ...{
    "official_transcript": "Official Transcript",
    "partial_transcript": "Partial Transcript",
    "mobile_desc": "Launch {{brandName}} on a desktop browser to download transcripts.",
    "official_desc": "Download a digital copy of your official transcript below. For questions and special requests, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>",
    "partial_desc": "Download a digital copy of your partial transcript below.",
    "download_official_transcript": "Download Official Transcript",
    "download_partial_transcript": "Download Partial Transcript"
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.transcripts = window.locales.es.settings.transcripts || {};window.locales.es.settings.transcripts = {...window.locales.es.settings.transcripts, ...{
    "official_transcript": "Transcripción oficial",
    "partial_transcript": "Transcripción parcial",
    "partial_desc": "Descargue una copia digital de su transcripción parcial a continuación.",
    "download_official_transcript": "Descargar Official Transcript",
    "download_partial_transcript": "Descargar Transcripción Parcial",
    "mobile_desc": "Inicie {{brandName}} en un navegador de escritorio para descargar transcripciones.",
    "official_desc": "Descargue una copia digital de su transcripción oficial a continuación. Para preguntas y solicitudes especiales, contáctenos en <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>"
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.transcripts = window.locales.it.settings.transcripts || {};window.locales.it.settings.transcripts = {...window.locales.it.settings.transcripts, ...{
    "official_transcript": "Trascrizione ufficiale",
    "partial_transcript": "Trascrizione parziale",
    "partial_desc": "Scarica una copia digitale della tua trascrizione parziale di seguito.",
    "download_official_transcript": "Scarica Transcript ufficiale",
    "download_partial_transcript": "Scarica la trascrizione parziale",
    "mobile_desc": "Avviare {{brandName}} su un browser desktop per scaricare le trascrizioni.",
    "official_desc": "Scarica una copia digitale della tua trascrizione ufficiale di seguito. Per domande e richieste speciali, contattaci all'indirizzo <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.transcripts = window.locales.zh.settings.transcripts || {};window.locales.zh.settings.transcripts = {...window.locales.zh.settings.transcripts, ...{
    "official_transcript": "官方成绩单",           
    "partial_transcript": "部分成绩单",           
    "partial_desc": "下载电子版如下部分成绩单。",           
    "download_official_transcript": "下载官方成绩单",           
    "download_partial_transcript": "下载部分成绩单",           
    "mobile_desc": "在桌面浏览器上启动{{brandName}}下载成绩单。",           
    "official_desc": "下载电子版如下官方成绩单。如有任何问题或特殊要求，请发送邮件至<a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>联系我们。"           
}
};