import { type CurrentUserIguanaObject } from 'Users';
import { getImpactGlobalScholarshipTest } from './impactGlobalScholarshipTest';
import { getUpfrontPaymentRegistrationTest } from './upfrontPaymentRegistrationTest';

// NOTE: It is very likely that when adding a new test here that a new mobile build
// and min-version bump will be required for accurate metrics. That process should
// be started right after the new test rolls to reduce the risk of any issues.
const splitTestMap = {
    upfrontPaymentRegistrationTest: getUpfrontPaymentRegistrationTest,
    impactGlobalScholarshipTest: getImpactGlobalScholarshipTest,
} as const;

export function getSplitTestConfig<T extends keyof typeof splitTestMap>(
    testName: T,
    user: CurrentUserIguanaObject | null,
): ReturnType<typeof splitTestMap[T]> {
    const getConfig = splitTestMap[testName];

    return getConfig(user) as ReturnType<typeof splitTestMap[T]>;
}

export function getSplitTestConfigProperty<
    T extends keyof typeof splitTestMap,
    P extends keyof ReturnType<typeof splitTestMap[T]>,
>(testName: T, user: CurrentUserIguanaObject | null, property: P): ReturnType<typeof splitTestMap[T]>[P] {
    const config = getSplitTestConfig(testName, user);

    return config[property];
}
