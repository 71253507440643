window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.edit_documents = window.locales.am.settings.edit_documents || {};window.locales.am.settings.edit_documents = {...window.locales.am.settings.edit_documents, ...{
    "documents": "ሰነዶች",           
    "desktop_to_upload": "የእርስዎን ሰነዶች ለመስቀል እባክዎ በዲስክቶፕ መሳሪያው በመለያ ይግቡ።",           
    "upload": "ስቀል",           
    "identification": "መታወቂያ",           
    "identification_verified": "መታወቂያ ተረጋግጧል",           
    "identification_desc_when_can_upload": "ማንነትዎን ለማረጋገጥ እንድንችል የእርስዎን መንጃ ፈቃድ ወይም ፓስፖርት ጥርት ያለ ምስል እባክዎ ይስቀሉ።",           
    "identification_desc_when_cannot_upload": "ወደ ፕሮግራሙ ተቀባይነትን ሲያገኙ፣ማንነትዎን ለማረጋገጥ እንድንችል የእርስዎን መንጃ ፈቃድ ወይም ፓስፖርት ጥርት ያለ ምስል እባክዎ ይስቀሉ።",           
    "transcripts": "ትራንስክሪፕቶች",           
    "english_language_proficiency_documentation": "የእንግሊዝኛ ቋንቋ ብቃት ሰነድ ",           
    "english_language_proficiency_documents_desc": "የአፍ መፍቻ ቋንቋቸው እንግሊዝኛ ያልሆኑ እና እንግሊዝኛ የመማሪያ የመጀመሪያ ቋንቋ በሆነበት የታወቀ ተቋም ዲግሪያቸውን ያላገኙ ተማሪዎች ተገቢ ሰነዶችን በማቅረብ የእንግሊዝኛ ብቃታቸውን እንዲያሳዩ ይጠየቃሉ። ተቀባይነት ያላቸውን ምዘናዎች እና ሰነዶች ሙሉ ዝርዝር ለመገምገም እባክዎ <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">እዚህ ጋር ጠቅ ያድርጉ</a>።",           
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "ተማሪው የእንግሊዝኛ የአፍ መፍቻ ቋንቋ ተናጋሪ ነው እና የእንግሊዝኛ ቋንቋ ብቃት ሰነዶችን እንዲሰቅል አይጠየቅም እንዲሁም የሰነድ ተቀባይነት ማግኘት አይጠበቅበትም።",           
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "ተማሪው እንግሊዝኛ ዋነኛ የትምህርት መስጫ በነበረበት ተቋም እውቅና ያለው ዲግሪ አግኝቷል እና የእንግሊዝኛ ቋንቋ ብቃት ሰነዶችን እንዲሰቅል አይጠየቅም እንዲሁም የሰነድ ተቀባይነት ማግኘት አይጠበቅበትም።",           
    "minimum_required_scores": "ዝቅተኛ አስፈላጊ ነጥቦች እና ተጨማሪ መረጃ",           
    "select_documentation_type": "የሰነድ አይነት ይምረጡ",           
    "duolingo": "Duolingo የእንግሊዝኛ ፈተና",           
    "toefl_pbt": "Test of English as a Foreign Language (TOEFL PBT)",           
    "ibt": "Internet Based Test (iBT)",           
    "ielts": "International English Language Test (IELTS)",           
    "pte": "Pearson Test of English (PTE) Academic Score Report",           
    "act_compass": "ACT COMPASS",           
    "accuplacer_esl": "College Board Accuplacer ESL Exam Series",           
    "eiken": "Eiken English Proficiency Exam",           
    "cefr": "CEFR-aligned exam (e.g.: LCCI, Cambridge)",           
    "other": "ሌላ",           
    "comments_optional": "አስተያየቶች (እንደ አማራጭ)",           
    "uploaded_date": "{{uploadDate}}ተሰቅሏል",           
    "mailing_address": "የፖስታ መላኪያ አድራሻ",           
    "mailing_address_description": "በጥብቅ ምስጢራዊ። ለማንነት ማረጋገጫ እና ዲፕሎማ ማድረሻ።",           
    "address": "<i class=\"fa fa-search\"></i> የእርስዎን አድራሻ ይፈልጉ",           
    "address_help_text": "የተቻለውን ያህል የቀረበ አድራሻ እባክዎ ይምረጡ። ከፍለጋ በኋላ ውጤዎችን አርትዕ ማድረግ ይችላሉ።",           
    "address_placeholder": "አድራሻዎን ያስገቡ",           
    "address_line_1": "አድራሻ መስመር 1",           
    "address_line_1_placeholder": "የመንገድ አድራሻ፣ ፖስታ ሳጥን ቁጥ.፣ የኩባንያ ስም፣ c/o",           
    "address_line_2": "አድራሻ መስመር 2",           
    "address_line_2_placeholder": "አፓርትመንት፣ ክፍሎች፣ ክፍል፣ ህንፃ፣ ወለል፣ ወዘተ.",           
    "city": "ከተማ",           
    "state": "ስቴት / ክፍለ ሀገር / ክልል",           
    "zip": "የዚፕ/የፖስታ ቁጥር",           
    "country": "ሀገር",           
    "phone": "ስልክ",           
    "save_documents": "ሰነዶችን ያስቀምጡ",           
    "save_mailing_address": "የመላኪያ አድራሻን ያስቀምጡ",           
    "documents_saved": "ሰነዶች ተቀምጠዋል",           
    "mailing_address_saved": "የመልዕክት አድራሻ ተቀምጧል",           
    "approve": "ያጽድቃሉ?",           
    "no_sections_message": "ወደ ፕሮግራሙ ተቀባይነትን ሲያገኙ፣ ለሬጅስትራሩ ተጨማሪ ሰነዶችን እንዲያቀርቡ ሊጠየቁ ይችላሉ።",           
    "unofficial_transcripts_description_part_one": "የመግቢያ ጅማሮ ያግኙ! ለተቀበልኩት ለእያንዳንዱ ዲግሪ ኢፌሳላዊ ያልሆነ ትራንስክሪፕት ይስቀሉ። ስለ ትራንስክሪፕት መስፈርቶች የበለጠ ለማወቅ <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">እዚህ ጋር ጠቅ ያድርጉ</a>።",           
    "unofficial_transcripts_description_part_two": "ከስር ባለው በርካታ ሰነዶችን በአንድ ዲግሪ መስቀል ይችላሉ። አንዴ ከተሰቀለ፣ ሰነዶች ላይሰረዙ ይችላሉ። ሰነድ በስህተት ከሰቀሉ፣ እባክዎ <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a>ን ለእርዳታ ያግኙ።",           
    "unofficial_transcripts_final_note": "በፕሮግራም ቅበላው ጊዜ፣ ላገኙት ከፍተኛ መደበኛ ዲግሪ ኦፌሳላዊ ትራንስክሪፕት መጠየቅ ያስፈልግዎታል። ስለ ኦፌሳላዊ ትራንስክሪፕቶች <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">የበለጠ ይወቁ</a>።",           
    "unofficial_transcripts_upload": "ኦፊሳላዊ ያልሆነውን ትራንስክሪፕት ይስቀሉ።",           
    "unofficial_transcripts_upload_thank_you": "ኦፊሳላዊ ያልሆነውን ትራንስክሪፕት ስለሰቀሉ እናመሰግናለን!",           
    "unofficial_transcripts_please_upload": "የስቀል አዶውን ጠቅ በማድረግ እባክዎ የእርስዎን ኦፌሳላዊ ያልሆኑ ትራንስክሪፕቶች ከስር ይስቀሉ። ተቀባይነት ስላላቸው ቅጾች እና የሰነድ መስፈርቶች፣ እባክዎ <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">እዚህ ጋር ጠቅ ያድርጉ።</a>",           
    "official_transcripts_also_required": "ከእርስዎ የቀድሞ ተቋም በቀጥጣ የሚላኩ ኦፌሳላዊ ትራንስክሪፕቶች ያስፈልጋሉ። የትኞቹን ዲግሪዎች ኦፌሳላዊ ትራንስክሪፕቶች እንደሚፈልጉ ከስር ያረጋግጡ።",           
    "official_transcripts_approved": "ኦፊሳላዊ ትራንስክሪፕቶችዎን አጽድቀናቸዋል።",           
    "official_transcripts_waived": "ኦፊሳላዊ ትራንስክሪፕቶችዎን ትተናቸዋል።",           
    "official_transcripts_required": "ኦፌሳላዊ ትራንስክሪፕት ያስፈልጋል። ኦፌሳላዊ ትራንስክሪፕቶች ስለማስገባት <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">እዚህ ጋር</a>የበለጠ ይማሩ።",           
    "transcripts_section_admin_message": "ተማሪው መደበኛ የዲግሪ ፕሮግራምን አላጠናቀቀም ስለዚህ የብቁነት መስፈርቶችን አላሟላም።",           
    "english_language_proficiency_section_admin_message_for_exempt_user": "ተማሪ በአብዛኛው እንግሊዝኛ ተናጋሪ በሆነ ኩባንያ ውስጥ ቢያንስ ለአምስት ዓመታት ሰርቷል እና ስለዚህ የእንግሊዝኛ ቋንቋ ብቃት ሰነዶችን መጫን አይጠበቅበትም እና የሰነድ ማረጋገጫ አያስፈልገውም።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.edit_documents = window.locales.ar.settings.edit_documents || {};window.locales.ar.settings.edit_documents = {...window.locales.ar.settings.edit_documents, ...{
    "documents": "مستندات",
    "upload": "تحميل",
    "identification": "هوية",
    "identification_verified": "التحقق من الهوية",
    "identification_desc_when_can_upload": "يرجى تحميل صورة واضحة لرخصة القيادة الخاصة بك أو جواز السفر حتى نتمكن من التحقق من هويتك.",
    "identification_desc_when_cannot_upload": "عند قبول البرنامج ، يرجى تحميل صورة واضحة لرخصة القيادة الخاصة بك أو جواز السفر حتى نتمكن من التحقق من هويتك.",
    "transcripts": "النصوص",
    "english_language_proficiency_documentation": "توثيق إتقان اللغة الإنجليزية",
    "select_documentation_type": "اختر نوع التوثيق",
    "duolingo": "اختبار دوولينجو للغة الإنجليزية",
    "toefl_pbt": "اختبار اللغة الإنجليزية كلغة أجنبية (TOEFL PBT)",
    "ibt": "الاختبار على الإنترنت (iBT)",
    "ielts": "اختبار اللغة الإنجليزية الدولي (IELTS)",
    "pte": "Pearson Test of English (PTE) Academic Score Report",
    "act_compass": "ACT COMPASS",
    "accuplacer_esl": "مجلس الكلية Accuplacer ESL Exam Series",
    "eiken": "امتحان اللغة الإنجليزية Eiken امتحان",
    "other": "آخر",
    "comments_optional": "التعليقات (اختياري)",
    "uploaded_date": "تم التحميل {{uploadDate}}",
    "mailing_address": "العنوان البريدي",
    "mailing_address_description": "في سرية تامة. للتحقق من الهوية وتسليم الدبلوم.",
    "address_line_1": "العنوان الأول",
    "address_line_1_placeholder": "عنوان الشارع، ص. مربع، اسم الشركة، ج / س",
    "address_line_2": "سطر العنوان 2",
    "address_line_2_placeholder": "شقة، بذلة، وحدة، بناء، طابق، الخ.",
    "city": "مدينة",
    "state": "الدولة / المقاطعة / المنطقة",
    "zip": "الرمز البريدي / الرمز البريدي",
    "country": "بلد",
    "phone": "هاتف",
    "approve": "يوافق؟",
    "save_documents": "حفظ الوثائق",
    "save_mailing_address": "حفظ العنوان البريدي",
    "documents_saved": "المستندات المحفوظة",
    "mailing_address_saved": "عنوان البريد المحفوظة",
    "minimum_required_scores": "الحد الأدنى من الدرجات المطلوبة والمعلومات الإضافية",
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "المتعلم هو أحد المتحدثين الأصليين للغة الإنجليزية ولذلك فهو غير مطالب بتحميل مستندات إتقان اللغة الإنجليزية ولا يتطلب موافقة من المستند.",
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "حصل Learner على شهادة معتمدة من مؤسسة كانت اللغة الإنجليزية هي اللغة الرئيسية للتعليم فيها ، وبالتالي لا يُطلب منها تحميل مستندات إتقان اللغة الإنجليزية ولا تتطلب موافقة على المستند.",
    "cefr": "امتحان محاذاة CEFR (على سبيل المثال: LCCI ، Cambridge)",
    "desktop_to_upload": "الرجاء تسجيل الدخول على جهاز سطح مكتب لتحميل مستنداتك.",
    "no_sections_message": "عند قبولك للبرنامج ، قد يُطلب منك تقديم مستندات إضافية للمسجل.",
    "unofficial_transcripts_description_part_one": "الحصول على السبق! قم بتحميل نسخة غير رسمية لكل درجة حصلت عليها. <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">انقر هنا</a> لمعرفة المزيد حول متطلبات النص.",
    "unofficial_transcripts_final_note": "عند القبول في البرنامج ، ستحتاج إلى طلب نسخة رسمية لأعلى درجة حصلت عليها. <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">تعلم المزيد</a> عن النصوص الرسمية.",
    "unofficial_transcripts_upload": "تحميل نسخة غير رسمية.",
    "unofficial_transcripts_upload_thank_you": "شكرا لك لتحميل نسخة غير رسمية!",
    "official_transcripts_approved": "لقد وافقنا على النصوص الرسمية الخاصة بك.",
    "official_transcripts_required": "نسخة رسمية مطلوبة. تعرف على المزيد حول تقديم النصوص الرسمية <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">هنا</a> .",
    "official_transcripts_waived": "لقد تنازلنا عن النصوص الرسمية الخاصة بك.",
    "unofficial_transcripts_description_part_two": "يمكنك تحميل مستندات متعددة لكل درجة أدناه. بمجرد التحميل ، قد لا يتم حذف المستندات. إذا قمت بتحميل مستند عن طريق الخطأ ، فيرجى الاتصال بـ <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> للحصول على المساعدة.",
    "address": "<i class=\"fa fa-search\"></i>ابحث عن عنوانك",
    "address_placeholder": "أدخل عنوانك",
    "address_help_text": "الرجاء تحديد أقرب عنوان ممكن. يمكنك تعديل النتائج بعد البحث.",
    "unofficial_transcripts_please_upload": "يرجى تحميل النصوص غير الرسمية الخاصة بك أدناه من خلال النقر على أيقونة التحميل. لمزيد من المعلومات حول الأشكال المقبولة ومتطلبات المستندات ، يرجى <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">النقر هنا.</a>",
    "official_transcripts_also_required": "المستندات الرسمية المرسلة مباشرة من مؤسستك السابقة مطلوبة أيضًا. تحقق أدناه لمعرفة الدرجات التي تتطلب النصوص الرسمية.",
    "english_language_proficiency_documents_desc": "الطلاب الذين لغتهم الأم ليست اللغة الإنجليزية والذين لم يحصلوا على درجة علمية من مؤسسة معترف بها حيث اللغة الإنجليزية هي اللغة الأساسية للتعليم ، يُطلب منهم إثبات الكفاءة في اللغة الإنجليزية من خلال تقديم الوثائق المناسبة. الرجاء <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">النقر هنا</a> لمراجعة القائمة الكاملة للتقييمات والوثائق المقبولة.",
    "transcripts_section_admin_message": "لم يكمل الطالب برنامجًا رسميًا للحصول على درجة علمية ولا يستوفي شروط الأهلية.",
    "english_language_proficiency_section_admin_message_for_exempt_user": "لقد عمل المتعلم في شركة يتحدث معظمها باللغة الإنجليزية لمدة خمس سنوات على الأقل ، وبالتالي فهو غير ملزم بتحميل مستندات إجادة اللغة الإنجليزية ولا يتطلب الموافقة على المستند."
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.edit_documents = window.locales.en.settings.edit_documents || {};window.locales.en.settings.edit_documents = {...window.locales.en.settings.edit_documents, ...{
    "documents": "Documents",
    "desktop_to_upload": "Please sign in on a desktop device to upload your documents.",
    "upload": "Upload",
    "identification": "Identification",
    "identification_verified": "Identification Verified",
    "identification_desc_when_can_upload": "Please upload a clear image of your driver's license or passport so we can verify your identity.",
    "identification_desc_when_cannot_upload": "Upon acceptance to the program, please upload a clear image of your driver's license or passport so we can verify your identity.",
    "transcripts": "Transcripts",
    "transcripts_section_admin_message": "Student did not complete a formal degree program and does not meet eligibility requirements.",
    "english_language_proficiency_documentation": "English Language Proficiency Documentation",
    "english_language_proficiency_documents_desc": "Students whose native language is not English and who have not earned a degree from an accredited institution where English is the primary language of instruction are required to demonstrate proficiency in English by providing appropriate documentation. Please <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">click here</a> to review the complete list of acceptable assessments and documentation.",
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "Learner is a native English speaker and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "Learner has earned an accredited degree from an institution where English was the principal language of instruction and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "english_language_proficiency_section_admin_message_for_exempt_user": "Learner has worked in a predominantly English speaking company for at least five years and is therefore not required to upload English language proficiency documents and does not require document approval.",
    "minimum_required_scores": "Minimum required scores and additional information",
    "select_documentation_type": "Select documentation type",
    "duolingo": "Duolingo English Test",
    "toefl_pbt": "Test of English as a Foreign Language (TOEFL PBT)",
    "ibt": "Internet Based Test (iBT)",
    "ielts": "International English Language Test (IELTS)",
    "pte": "Pearson Test of English (PTE) Academic Score Report",
    "act_compass": "ACT COMPASS",
    "accuplacer_esl": "College Board Accuplacer ESL Exam Series",
    "eiken": "Eiken English Proficiency Exam",
    "cefr": "CEFR-aligned exam (e.g.: LCCI, Cambridge)",
    "other": "Other",
    "comments_optional": "Comments (optional)",
    "uploaded_date": "Uploaded {{uploadDate}}",
    "mailing_address": "Mailing Address",
    "mailing_address_description": "Strictly confidential. For identity verification and diploma delivery.",
    "address": "<i class=\"fa fa-search\"></i> Search For Your Address",
    "address_help_text": "Please select the closest address possible. You can edit the results after searching.",
    "address_placeholder": "Enter your address",
    "address_line_1": "Address line 1",
    "address_line_1_placeholder": "Street address, P.O. box, company name, c/o",
    "address_line_2": "Address line 2",
    "address_line_2_placeholder": "Apartment, suite, unit, building, floor, etc.",
    "city": "City",
    "state": "State / Province / Region",
    "zip": "Zip / Postal Code",
    "country": "Country",
    "phone": "Phone",
    "save_documents": "Save Documents",
    "save_mailing_address": "Save Mailing Address",
    "documents_saved": "Documents Saved",
    "mailing_address_saved": "Mailing Address Saved",
    "approve": "Approve?",
    "no_sections_message": "Upon acceptance to the program, you may be asked to provide additional documents to the registrar.",
    "unofficial_transcripts_description_part_one": "Get a head start! Upload an unofficial transcript for each degree you've earned. <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">Click here</a> to learn more about transcript requirements.",
    "unofficial_transcripts_description_part_two": "You may upload multiple documents per degree below. Once uploaded, documents may not be deleted. If you have uploaded a document in error, please contact <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> for assistance.",
    "unofficial_transcripts_final_note": "Upon acceptance into the program, you will need to request an official transcript for your highest degree earned. <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">Learn more</a> about official transcripts.",
    "unofficial_transcripts_upload": "Upload an unofficial transcript.",
    "unofficial_transcripts_upload_thank_you": "Thank you for uploading an unofficial transcript!",
    "unofficial_transcripts_please_upload": "Please upload your unofficial transcripts below by clicking on the Upload icon. For more information regarding acceptable formats and document requirements, please <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">click here.</a>",
    "official_transcripts_also_required": "Official transcripts sent directly from your prior institution are also required. Check below to see which degrees require official transcripts.",
    "official_transcripts_approved": "We've approved your official transcripts.",
    "official_transcripts_waived": "We've waived your official transcripts.",
    "official_transcripts_required": "Official transcript required. Learn more about submitting official transcripts <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">here</a>."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.edit_documents = window.locales.es.settings.edit_documents || {};window.locales.es.settings.edit_documents = {...window.locales.es.settings.edit_documents, ...{
    "documents": "Documentos",
    "upload": "Subir",
    "identification": "Identificación",
    "identification_verified": "Identificación verificada",
    "identification_desc_when_can_upload": "Cargue una imagen clara de su licencia de conducir o pasaporte para que podamos verificar su identidad.",
    "identification_desc_when_cannot_upload": "Una vez aceptado el programa, cargue una imagen clara de su licencia de conducir o pasaporte para que podamos verificar su identidad.",
    "transcripts": "Transcripciones",
    "english_language_proficiency_documentation": "Documentación de dominio del idioma inglés",
    "select_documentation_type": "Seleccione el tipo de documentación",
    "duolingo": "Prueba de inglés Duolingo",
    "toefl_pbt": "Prueba de Inglés como Lengua Extranjera (TOEFL PBT)",
    "ibt": "Prueba basada en Internet (iBT)",
    "ielts": "Prueba internacional del idioma inglés (IELTS)",
    "pte": "Informe de puntaje académico de Pearson Test of English (PTE)",
    "act_compass": "ACT COMPASS",
    "accuplacer_esl": "Serie Examen ESL de College Board Accuplacer",
    "eiken": "Eiken Examen de dominio del inglés",
    "other": "Otro",
    "comments_optional": "comentarios (opcional)",
    "uploaded_date": "Subido {{uploadDate}}",
    "mailing_address": "Dirección de envio",
    "mailing_address_description": "Estrictamente confidencial. Para la verificación de identidad y la entrega del diploma.",
    "address_line_1": "Dirección Línea 1",
    "address_line_1_placeholder": "Dirección, apartado postal, nombre de la empresa, c / o",
    "address_line_2": "Dirección línea 2",
    "address_line_2_placeholder": "apartamento, suite, unidad, edificio, piso, etc.",
    "city": "Ciudad",
    "state": "Estado / Provincia / Región",
    "zip": "Código postal / postal",
    "country": "País",
    "phone": "Teléfono",
    "approve": "¿Aprobar?",
    "save_documents": "Guardar documentos",
    "save_mailing_address": "Guardar dirección de correo",
    "documents_saved": "Documentos guardados",
    "mailing_address_saved": "Dirección de correo guardada",
    "minimum_required_scores": "Puntuaciones mínimas requeridas e información adicional.",
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "El alumno es un hablante nativo de inglés y, por lo tanto, no está obligado a cargar documentos de dominio del idioma inglés y no requiere aprobación de documentos.",
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "El alumno ha obtenido un título acreditado en una institución donde el inglés era el idioma principal de instrucción y, por lo tanto, no está obligado a cargar documentos de dominio del idioma inglés y no requiere la aprobación del documento.",
    "cefr": "Examen alineado por el CEFR (por ejemplo: LCCI, Cambridge)",
    "desktop_to_upload": "Inicie sesión en un dispositivo de escritorio para cargar sus documentos.",
    "no_sections_message": "Una vez aceptado el programa, se le puede pedir que proporcione documentos adicionales al registrador.",
    "unofficial_transcripts_description_part_one": "¡Conseguir una ventaja! Sube una transcripción no oficial de cada título que hayas obtenido. <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">Haga clic aquí</a> para obtener más información sobre los requisitos de transcripción.",
    "unofficial_transcripts_final_note": "Una vez aceptado en el programa, deberá solicitar una transcripción oficial de su título más alto obtenido. <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">Obtenga más información</a> sobre las transcripciones oficiales.",
    "unofficial_transcripts_upload": "Sube una transcripción no oficial.",
    "unofficial_transcripts_upload_thank_you": "¡Gracias por subir una transcripción no oficial!",
    "official_transcripts_approved": "Hemos aprobado sus transcripciones oficiales.",
    "official_transcripts_required": "Se requiere transcripción oficial. Obtenga más información sobre cómo enviar transcripciones oficiales <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">aquí</a> .",
    "official_transcripts_waived": "Renunciamos a sus transcripciones oficiales.",
    "unofficial_transcripts_description_part_two": "Puede cargar varios documentos por título a continuación. Una vez cargados, los documentos no se pueden eliminar. Si ha cargado un documento por error, comuníquese con <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> para obtener ayuda.",
    "address": "<i class=\"fa fa-search\"></i>Busque su dirección",
    "address_placeholder": "Ingrese su direccion",
    "address_help_text": "Seleccione la dirección más cercana posible. Puede editar los resultados después de la búsqueda.",
    "unofficial_transcripts_please_upload": "Cargue sus transcripciones no oficiales a continuación haciendo clic en el icono Cargar. Para obtener más información sobre los formatos aceptables y los requisitos de los documentos, <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">haga clic aquí.</a>",
    "official_transcripts_also_required": "También se requieren transcripciones oficiales enviadas directamente desde su institución anterior. Consulte a continuación para ver qué títulos requieren transcripciones oficiales.",
    "english_language_proficiency_documents_desc": "Los estudiantes cuyo idioma materno no es el inglés y que no hayan obtenido un título de una institución acreditada donde el inglés es el idioma principal de instrucción deben demostrar su dominio del inglés proporcionando la documentación adecuada. Haga <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">clic aquí</a> para revisar la lista completa de evaluaciones y documentación aceptables.",
    "transcripts_section_admin_message": "El estudiante no completó un programa de grado formal y no cumple con los requisitos de elegibilidad.",
    "english_language_proficiency_section_admin_message_for_exempt_user": "El alumno ha trabajado en una empresa predominantemente de habla inglesa durante al menos cinco años y, por lo tanto, no está obligado a cargar documentos de dominio del idioma inglés y no requiere aprobación de documentos."
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.edit_documents = window.locales.it.settings.edit_documents || {};window.locales.it.settings.edit_documents = {...window.locales.it.settings.edit_documents, ...{
    "documents": "Documenti",
    "upload": "Caricare",
    "identification": "Identificazione",
    "identification_verified": "Identificazione verificata",
    "identification_desc_when_can_upload": "Carica un'immagine chiara della patente o del passaporto del conducente in modo da poter verificare la tua identità.",
    "identification_desc_when_cannot_upload": "Dopo aver accettato il programma, si prega di caricare un'immagine chiara della patente o del passaporto del conducente in modo che possiamo verificare la tua identità.",
    "transcripts": "trascrizioni",
    "english_language_proficiency_documentation": "Documentazione sulle competenze linguistiche in inglese",
    "select_documentation_type": "Seleziona il tipo di documentazione",
    "duolingo": "Duolingo English Test",
    "toefl_pbt": "Test dell'inglese come lingua straniera (TOEFL PBT)",
    "ibt": "Internet Based Test (iBT)",
    "ielts": "Test di lingua inglese internazionale (IELTS)",
    "pte": "Rapporto sul punteggio accademico Pearson Test of English (PTE)",
    "act_compass": "AGGIUNGI COMPASS",
    "accuplacer_esl": "College Board Accuplacer ESL Exam Series",
    "eiken": "Eiken English Proficiency Exam",
    "other": "Altro",
    "comments_optional": "Commenti (facoltativo)",
    "uploaded_date": "Caricato {{uploadDate}}",
    "mailing_address": "Indirizzo di posta",
    "mailing_address_description": "Strettamente confidenziale. Per la verifica dell'identità e la consegna del diploma.",
    "address_line_1": "Indirizzo Linea 1",
    "address_line_1_placeholder": "Indirizzo, casella postale, nome dell'azienda, c / o",
    "address_line_2": "Indirizzo 2",
    "address_line_2_placeholder": "Appartamento, suite, unità, edificio, piano, ecc.",
    "city": "Città",
    "state": "Stato / Provincia / Regione",
    "zip": "Zip / Codice postale",
    "country": "Nazione",
    "phone": "Telefono",
    "approve": "Approvare?",
    "save_documents": "Salva documenti",
    "save_mailing_address": "Salva l'indirizzo postale",
    "documents_saved": "Documenti salvati",
    "mailing_address_saved": "Indirizzo postale salvato",
    "minimum_required_scores": "Punteggio minimo richiesto e informazioni aggiuntive",
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "Learner è un madrelingua inglese e non è quindi tenuto a caricare i documenti di conoscenza della lingua inglese e non richiede l'approvazione del documento.",
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "Lo studente ha conseguito una laurea accreditata presso un'istituzione in cui l'inglese era la principale lingua di insegnamento e pertanto non è tenuto a caricare documenti di conoscenza della lingua inglese e non richiede l'approvazione del documento.",
    "cefr": "Esame allineato al CEFR (es: LCCI, Cambridge)",
    "desktop_to_upload": "Accedi su un dispositivo desktop per caricare i tuoi documenti.",
    "no_sections_message": "All'accettazione del programma, potrebbe essere richiesto di fornire documenti aggiuntivi al registrar.",
    "unofficial_transcripts_description_part_one": "Parti in vantaggio! Carica una trascrizione non ufficiale per ogni laurea che hai conseguito. <a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">Fare clic qui</a> per ulteriori informazioni sui requisiti di trascrizione.",
    "unofficial_transcripts_final_note": "Dopo l'accettazione nel programma, dovrai richiedere una trascrizione ufficiale per il tuo massimo grado conseguito. <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">Ulteriori informazioni</a> sulle trascrizioni ufficiali.",
    "unofficial_transcripts_upload": "Carica una trascrizione non ufficiale.",
    "unofficial_transcripts_upload_thank_you": "Grazie per aver caricato una trascrizione non ufficiale!",
    "official_transcripts_approved": "Abbiamo approvato le tue trascrizioni ufficiali.",
    "official_transcripts_required": "È richiesta la trascrizione ufficiale. Ulteriori informazioni sull'invio delle trascrizioni ufficiali <a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">qui</a> .",
    "official_transcripts_waived": "Abbiamo rinunciato alle tue trascrizioni ufficiali.",
    "unofficial_transcripts_description_part_two": "Puoi caricare più documenti per laurea di seguito. Una volta caricati, i documenti non possono essere eliminati. Se hai caricato un documento per errore, contatta <a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> per assistenza.",
    "address": "<i class=\"fa fa-search\"></i>Cerca il tuo indirizzo",
    "address_placeholder": "Inserisci il tuo indirizzo",
    "address_help_text": "Seleziona l'indirizzo più vicino possibile. È possibile modificare i risultati dopo la ricerca.",
    "unofficial_transcripts_please_upload": "Carica le tue trascrizioni non ufficiali di seguito facendo clic sull'icona Carica. Per ulteriori informazioni sui formati accettabili e sui requisiti dei documenti, <a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">fare clic qui.</a>",
    "official_transcripts_also_required": "Sono necessarie anche le trascrizioni ufficiali inviate direttamente dal tuo istituto precedente. Controlla di seguito per vedere quali gradi richiedono trascrizioni ufficiali.",
    "english_language_proficiency_documents_desc": "Gli studenti la cui lingua madre non è l'inglese e che non hanno conseguito una laurea presso un istituto accreditato in cui l'inglese è la lingua di insegnamento principale sono tenuti a dimostrare la conoscenza della lingua inglese fornendo la documentazione appropriata. Fare <a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">clic qui</a> per consultare l'elenco completo delle valutazioni e della documentazione accettabili.",
    "transcripts_section_admin_message": "Lo studente non ha completato un corso di laurea formale e non soddisfa i requisiti di idoneità.",
    "english_language_proficiency_section_admin_message_for_exempt_user": "Lo studente ha lavorato in un'azienda prevalentemente di lingua inglese per almeno cinque anni e quindi non è tenuto a caricare documenti di conoscenza della lingua inglese e non richiede l'approvazione del documento."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.edit_documents = window.locales.zh.settings.edit_documents || {};window.locales.zh.settings.edit_documents = {...window.locales.zh.settings.edit_documents, ...{
    "documents": "文件",           
    "upload": "上传",           
    "identification": "身份",           
    "identification_verified": "身份已验证",           
    "identification_desc_when_can_upload": "请上传你驾照或护照的清晰图片，以便我们进行身份验证。",           
    "identification_desc_when_cannot_upload": "如被课程所录取，请上传你驾照或护照的清晰图片，以便我们进行身份验证。",           
    "transcripts": "成绩单",           
    "english_language_proficiency_documentation": "英语语言能力证明",           
    "select_documentation_type": "选择文件类型",           
    "duolingo": "多邻国英语测试",           
    "toefl_pbt": "英语外语测试（托福纸考）",           
    "ibt": "托福网考（iBT）",           
    "ielts": "雅思（IELTS）",           
    "pte": "培生学术英语考试（PTE）学术成绩报告",           
    "act_compass": "行动指南",           
    "accuplacer_esl": "College Board Accuplacer ESL考试系列",           
    "eiken": "英检实用英语水平测试",           
    "other": "其他",           
    "comments_optional": "评论（选填）",           
    "uploaded_date": "已上传 {{uploadDate}}",           
    "mailing_address": "邮寄地址",           
    "mailing_address_description": "绝密。用于身份验证和文凭发放。",           
    "address_line_1": "地址栏1",           
    "address_line_1_placeholder": "街道地址、邮政信箱、公司名称、转交人",           
    "address_line_2": "地址栏2",           
    "address_line_2_placeholder": "公寓、套房、单元、建筑物、楼层等。",           
    "city": "城市",           
    "state": "州/省/地区",           
    "zip": "邮政编码",           
    "country": "国家",           
    "phone": "电话",           
    "approve": "通过？",           
    "save_documents": "保存文件",           
    "save_mailing_address": "保存邮寄地址",           
    "documents_saved": "文件已保存",           
    "mailing_address_saved": "邮寄地址已保存",           
    "minimum_required_scores": "最低要求的分数和附加信息",           
    "english_language_proficiency_section_admin_message_for_native_english_speaker": "学员母语为英语，因此无需上传英语语言能力证明，也无需文件审批。",           
    "english_language_proficiency_section_admin_message_for_accredited_degree_holder": "学员已从以英语为主要教学语言的机构获得认证学位，因此无需上传英语语言能力证明，也无需文件审批。",           
    "cefr": "欧洲共同语言参考标准（例如：伦敦商会、剑桥）",           
    "desktop_to_upload": "请在桌面设备上登录以上传你的文件。",           
    "no_sections_message": "一经录取，你可能需要向注册官提供其他文件。",           
    "unofficial_transcripts_description_part_one": "开始吧！上传你已获得的每个学位的非官方成绩单。<a ng-click=\"loadFaqUrl('/help/student-transcript-policy', '_blank')\">点击此处</a>深入了解成绩单要求。",           
    "unofficial_transcripts_final_note": "一经录取，你需要提交最高学历的官方成绩单。<a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">了解更多</a>有关官方成绩单的事宜。",           
    "unofficial_transcripts_upload": "上传非官方成绩单。",           
    "unofficial_transcripts_upload_thank_you": "感谢上传非官方成绩单！",           
    "official_transcripts_approved": "你的官方成绩单已经我们审核通过。",           
    "official_transcripts_required": "要求提供官方成绩单。点击<a ng-click=\"loadFaqUrl('/help/transcript-faqs', '_blank')\">此处</a>了解更多关于提交官方成绩单的信息。",           
    "official_transcripts_waived": "你的官方成绩单未经我们审核通过。",           
    "unofficial_transcripts_description_part_two": "以下每一学位可上传多个文件。一旦上传，文件便不可删除。如果你上传了错误的文件，请联系<a href=\"mailto:registrar@quantic.edu\" target=\"_blank\">registrar@quantic.edu</a> 以寻求帮助。",           
    "address": "<i class=\"fa fa-search\"></i>搜索地址",           
    "address_placeholder": "输入你的地址",           
    "address_help_text": "请尽量选择最近的地址。你可以在搜索后编辑结果。",           
    "unofficial_transcripts_please_upload": "请点击上传图标上传你的非官方成绩单。有关可接受的格式和文件要求的更多信息，请<a ng-click=\"loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc#unofficial', '_blank')\">点击此处</a>进行了解。",           
    "official_transcripts_also_required": "我们还需要你所毕业的院校直接寄出的官方成绩单。查看以下内容，了解哪些学位需要官方成绩单。",           
    "english_language_proficiency_documents_desc": "如果学员不以英语为母语，并且未从以英语为主要教学语言的认证机构获得学位，则需要提供适当的证明文件来证明其英语水平。请<a ng-click=\"loadFaqUrl('/help/english-language-requirements', '_blank')\">点击此处</a>以浏览可接受评估和文件的完整列表。",           
    "transcripts_section_admin_message": "学员还未完成正式的学位课程，因此无需成绩单审批。",           
    "english_language_proficiency_section_admin_message_for_exempt_user": "学习者在一家以英语为主的公司工作了至少五年，因此不需要上传英语语言能力文件，也不需要文件批准。"
}
};