window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.select_program = window.locales.am.settings.select_program || {};window.locales.am.settings.select_program = {...window.locales.am.settings.select_program, ...{
    "section_degree_programs": "የዲግሪ ፕሮግራሞች",
    "section_exec_ed": "አስፈፃሚ ትምህርት",
    "section_exec_ed_intro": "የመግቢያ ጽሑፍ እዚህ ይሄዳል",
    "section_rise_to_c_suite": "ወደ C-Suite ይነሱ",
    "section_rise_to_c_suite_intro": "ወደ ከፍተኛ የአመራር እርከኖች ለመውጣት በሚያስፈልግ ሚና-ተኮር እውቀት እራስዎን ያስታጥቁ። እነዚህ ፕሮግራሞች 8 ወራት የሚረዝሙ ሲሆን ከአስደናቂ እኩዮች ጋር በአካል እና በአካል ለመገናኘት ሰፊ እድሎችን ያካትታሉ። በ7,600 ዶላር ብቻ ከሌሎች ከፍተኛ የC-suite የምስክር ወረቀቶች ጋር ሲነፃፀሩ በጣም ተመጣጣኝ ናቸው።",
    "section_finance_data": "ፋይናንስ እና ውሂብ",
    "section_finance_data_intro": "የእርስዎን ውሂብ፣ ፋይናንስ፣ ወይም የሂሳብ ችሎታን ለማሳደግ የተነደፉ የተለያዩ ኮርሶችን እናቀርባለን፣ የተለያዩ የቁጥር ብቃት ደረጃዎችን ለማስተናገድ ካሉ አማራጮች ጋር። እነዚህ የምስክር ወረቀቶች እንደ የትምህርት ክፍያ እና የቆይታ ጊዜ ይለያያሉ።",
    "section_ai_future_of_business": "AI እና የወደፊት የንግድ ሥራ",
    "section_ai_future_of_business_intro": "ከጉዲፈቻ ስልቶች፣ ፈጣን ምህንድስና፣ ኮድ እና ፕሮቶታይፕ ድረስ፣ እነዚህ ፕሮግራሞች AIን በንግድዎ ውስጥ በብቃት እና በብቃት ለመጠቀም የሚረዱ መሳሪያዎችን ለእርስዎ ለመስጠት የተነደፉ ናቸው።",
    "learn_more": "ተጨማሪ እወቅ",
    "apply_now": "አሁኑኑ ያመልክቱ",
    "application_pending": "ማመልከቻ በመጠባበቅ ላይ",
    "program_mba": "MBA",
    "program_mba_description": "ከፍተኛ እምቅ አቅም ላላቸው እና ለውጥ ለማምጣት ለሚጓጉ የመጀመሪያ ደረጃ ተማሪዎች በተዘጋጀ ጥብቅ ፕሮግራም ስራዎን ያፋጥኑ።",
    "program_emba": "አስፈፃሚ MBA",
    "program_emba_description": "በዓለም ዙሪያ ካሉ ከፍተኛ ኩባንያዎች ልምድ ካላቸው ባለሙያዎች ጋር በመተባበር ቁልፍ የአመራር እና የአስተዳደር ክህሎቶችን ያግኙ።",
    "program_msba": "MS የንግድ ትንታኔ",
    "program_msba_description": "ይህ ፕሮግራም በመረጃ ላይ ያተኮረ ዳራቸውን ከስልታዊ አመራር ጋር ለማዋሃድ ለሚፈልጉ ባለሙያዎች አበረታች ነው።",
    "program_msse": "MS ሶፍትዌር ምህንድስና",
    "program_msse_description": "ዘመናዊ የሶፍትዌር ምህንድስና አስፈላጊ ነገሮች፣ ከደመና መተግበሪያዎች፣ ከማሽን መማር፣ እስከ AI ምህንድስና እና ሌሎችንም ማስተር።",
    "program_cto_cert": "CTO ፕሮግራም",
    "program_cto_cert_description": "ለሚያድጉ ቴክኒካል መሪዎች እና የወደፊት ሲቲኦዎችን ለሚመኙ፣ ስልታዊ የመንገድ ካርታ ስራን ይማራሉ፣ አርክቴክት ጫፍ የቴክኖሎጂ መሠረተ ልማት፣ እና ፈጠራን ለመንዳት እና ቡድኖችን ወደ ስኬት ለመምራት የቴክኖሎጂ ውጥኖችን ከንግድ ግቦች ጋር ያመሳስሉ።",
    "program_founders_cert": "መስራቾች ፕሮግራም",
    "program_founders_cert_description": "ለተቋቋሙ ሥራ ፈጣሪዎች እና ገና የጅምር ጉዞአቸውን ለሚጀምሩ። በዚህ ፕሮግራም ስልታዊ አርቆ አስተዋይነት፣አስተሳሰብ የሚረብሽ እና ቀልጣፋ ችግር ፈቺ ክህሎቶችን ለመጀመር እና ቬንቸር ለመጀመር፣ደህንነቱ የተጠበቀ የገንዘብ ድጋፍ እና የዕደ ጥበብ ስራዎችን ያገኛሉ።",
    "program_executive_cert": "አስፈፃሚ ፕሮግራም",
    "program_executive_cert_description": "ቀጣዩ ትውልድ የንግድ መሪ ለመሆን ጉዞዎን ይጀምሩ። በዚህ ፕሮግራም በተለዋዋጭ ገበያዎች የላቀ ብቃትን ለማግኘት፣ በተለያዩ ተግባራት ለመምራት እና ለንግድ ስኬት የሚተረጎሙ ሁለገብ ችሎታዎችን ለማዳበር ዘመናዊ፣ ሁሉን አቀፍ የንግድ ችሎታን ያገኛሉ።",
    "program_cmo_cert": "CMO ፕሮግራም",
    "program_cmo_cert_description": "በመረጃ የተደገፉ ግንዛቤዎችን ሲጠቀሙ፣ ዲጂታል ለውጥን ሲቀበሉ እና ደንበኛን ያማከለ ስትራቴጂ ሲያዳብሩ የግብይት አመራርዎን ያሳድጉ። በፈጠራ እድገትን ማፋጠን እና በዛሬው ሁለገብ የግብይት ገጽታ ማበልፀግ ይማራሉ።",
    "program_fin_for_non_fin_managers_cert": "ፋይናንስ እና የሂሳብ አያያዝ ለአስተዳዳሪዎች",
    "program_fin_for_non_fin_managers_cert_description": "በልበ ሙሉነት የመግባቢያ ክህሎቶችን ማዳበር እና የንግድ ኢንቨስትመንቶችን ለውሳኔ ሰጭዎች እና የፋይናንስ ባለሙያዎች ሀሳብ ማቅረብ። በዚህ ፕሮግራም ውስጥ በሙያህ በሙሉ ለውጤታማ አመራር እና ብልህ ውሳኔ አሰጣጥ አስፈላጊ የሆነውን የፋይናንስ ትንተና አጠቃላይ ግንዛቤን ታገኛለህ።",
    "program_bus_analytics_leaders_cert": "ለመሪዎች የንግድ ትንታኔ",
    "program_bus_analytics_leaders_cert_description": "በንግድ ትንተና ሃይል የመሪነት ችሎታዎን ያሳድጉ። በውሂብ ላይ የተመሰረተ የውሳኔ አሰጣጥ ዋና፣ ጠቃሚ ግንዛቤዎችን በብቃት ማውጣት እና መረጃን ወደ ተፅዕኖ ስትራቴጂዎች መቀየር። ይህ ፕሮግራም ቢያንስ ለ 3 ዓመታት አግባብነት ያለው ልምድ ላላቸው መካከለኛ የሙያ ባለሙያዎች የተዘጋጀ ነው።",
    "program_data_science_foundations_cert": "የውሂብ ሳይንስ መሠረቶች",
    "program_data_science_foundations_cert_description": "የወደፊት ስራዎን በውሂብ እውቀት ያረጋግጡ። ማስተር ፓይዘን፣ SQL፣ ስታቲስቲክስ፣ ትንታኔ፣ የማሽን መማር እና ሌሎችም ጠንካራ ፅንሰ-ሃሳባዊ መሰረት ሲገነቡ እና የጄነሬቲቭ AIን በመጠቀም ከጫፍ እስከ ጫፍ የውሂብ ሳይንስ መፍትሄዎችን ሲሰሩ። ከመጀመሪያ እስከ መካከለኛ የሙያ ባለሙያዎች ተስማሚ።",
    "program_tyb_ai_cert": "ንግድዎን በ AI እና ChatGPT ይለውጡ",
    "program_tyb_ai_cert_description": "የንግድ ለውጥን ለመምራት በ AI ችሎታዎች እራስዎን ያስታጥቁ። የኤአይ እና የቻትጂፒቲ አፕሊኬሽኖችን ያስሱ፣ ስጋቶችን ይተንትኑ፣ ፈጣን ምህንድስና እና የአይአይ ውህደት የስነ-ህንጻ ተለዋዋጭ። ይህ እጅግ በጣም ተፅዕኖ ያለው ቴክኖሎጅ ሲዘረጋ እንደአሁኑ ይቆዩ።",
    "program_prototype_gpt_cert_description": "ማስተር ቻትጂፒቲ ያለቅድመ ኮድ የመስጠት ልምድ በብቃት ዲጂታል ቬንቸር ለመቅረጽ። በአይ-ተኮር የድር መተግበሪያ ልማት፣ ማረጋገጫ እና ሙከራ ውስጥ ተፈላጊ ችሎታዎችን ያሳድጉ። በዚህ መሣሪያ ስብስብ፣ በተሻሻለ ቅልጥፍና እና ግንዛቤ ንግድዎን ያፋጥኑታል።",
    "program_ai_for_technical_leaders_cert": "AI ለቴክኒካዊ መሪዎች",
    "program_ai_for_technical_leaders_cert_description": "በ AI እና ChatGPT ፣ LLM ላይ የተመሰረቱ ስርዓቶች ፣ የሞዴል ጥሩ ማስተካከያ እና የ AI ጉዲፈቻ ላይ ስለ የቅርብ ጊዜዎቹ የተራቀቀ ግንዛቤ ያግኙ። በድርጅትዎ ውስጥ AIን ለማሰማራት የሚያስፈልጉትን ስርዓቶች እና ችሎታዎች የስነ-ህንፃ ምርጫዎችን ማድረግ፣ ስጋቶችን መቀነስ እና ስትራቴጂ ማዘጋጀትን ይማራሉ።",
    "program_tyb_blockchain_cert": "ንግድዎን በብሎክቼይን ይለውጡ",
    "program_tyb_blockchain_cert_description": "የብሎክቼይን መሰረታዊ ነገሮችን ይማሩ፣ ቁልፍ አፕሊኬሽኖችን ይረዱ እና የብሎክቼይን ጉዲፈቻ እና ውህደትን ያቅዱ። ይህ ፕሮግራም ለድርጅታቸው ተግባራዊ blockchain መተግበሪያዎችን ለመረዳት ለሚፈልጉ አስተዳዳሪዎች እና ባለሙያዎች ተስማሚ ነው።",
    "program_prototype_gpt_cert": "AI ለግንባታ ሶፍትዌር",
    "unavailable": "አይገኝም",
    "pending_degree_application_message": "በአንድ የመግቢያ ዑደት አንድ ማመልከቻ ብቻ መቅረብ ይችላል። የማመልከቻውን ፕሮግራም መቀየር ከፈለጉ እባክዎ admissions በ <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> ያግኙ።",
    "register": "ይመዝገቡ",
    "program_msba_preapproved_description": "በመረጃ ላይ ያተኮረ አስተሳሰብን ከስትራቴጂካዊ የንግድ አመራር ጋር በማዋሃድ እንደ የሙያ ማበረታቻ የሚያገለግል የ13-ወር ዲግሪ ፕሮግራም።",
    "program_msse_preapproved_description": "ዘመናዊ የሶፍትዌር ምህንድስና አስፈላጊ ነገሮችን ለመቆጣጠር የ13 ወር ዲግሪ ፕሮግራም ከCloud መተግበሪያዎች እና ከማሽን መማር እስከ AI ምህንድስና።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.select_program = window.locales.ar.settings.select_program || {};window.locales.ar.settings.select_program = {...window.locales.ar.settings.select_program, ...{
    "section_degree_programs": "برامج الشهادة",
    "section_exec_ed": "التعليم التنفيذي",
    "section_exec_ed_intro": "نص المقدمة يذهب هنا",
    "section_rise_to_c_suite": "ارتق إلى جناح C",
    "section_rise_to_c_suite_intro": "جهز نفسك بالخبرة الخاصة بالأدوار اللازمة للصعود إلى أعلى مستويات القيادة. تبلغ مدة هذه البرامج 8 أشهر وتتضمن فرصًا كبيرة للتواصل مع أقران رائعين افتراضيًا وشخصيًا. بسعر 7600 دولار فقط، فهي ميسورة التكلفة بشكل جذري مقارنة بشهادات C-suite الأخرى.",
    "section_finance_data": "التمويل والبيانات",
    "section_finance_data_intro": "نحن نقدم مجموعة متنوعة من الدورات التدريبية المصممة لتعزيز مهاراتك في مجال البيانات أو التمويل أو المحاسبة، مع خيارات متاحة لاستيعاب مستويات مختلفة من الكفاءة الكمية. تختلف هذه الشهادات من حيث الرسوم الدراسية والمدة.",
    "section_ai_future_of_business": "الذكاء الاصطناعي ومستقبل الأعمال",
    "section_ai_future_of_business_intro": "بدءًا من استراتيجيات الاعتماد والهندسة السريعة وحتى البرمجة والنماذج الأولية، تم تصميم هذه البرامج لتمنحك الأدوات اللازمة للاستفادة من الذكاء الاصطناعي في عملك بفعالية وكفاءة.",
    "learn_more": "يتعلم أكثر",
    "apply_now": "قدم الآن",
    "application_pending": "التطبيق قيد الانتظار",
    "program_mba": "ماجستير إدارة الأعمال",
    "program_mba_description": "قم بتسريع حياتك المهنية من خلال برنامج صارم مصمم للمتعلمين ذوي الإمكانات العالية في بداية حياتهم المهنية والذين يتوقون إلى إحداث فرق.",
    "program_emba": "ماجستير في إدارة الأعمال التنفيذية",
    "program_emba_description": "اكتسب مهارات القيادة والإدارة الأساسية أثناء التعاون مع محترفين ذوي خبرة من أفضل الشركات حول العالم.",
    "program_msba": "تحليلات الأعمال ماجستير",
    "program_msba_description": "يعد هذا البرنامج حافزًا للمهنيين الذين يسعون إلى مزج خلفيتهم الموجهة نحو البيانات مع القيادة الإستراتيجية.",
    "program_msse": "هندسة البرمجيات ماجستير",
    "program_msse_description": "إتقان أساسيات هندسة البرمجيات الحديثة، بدءًا من التطبيقات السحابية والتعلم الآلي وهندسة الذكاء الاصطناعي والمزيد.",
    "program_cto_cert": "برنامج CTO",
    "program_cto_cert_description": "يُعد البرنامج مثاليًا للقادة التقنيين الصاعدين ومديري التكنولوجيا التنفيذيين الطموحين في المستقبل، حيث ستتعلم رسم خرائط الطريق الإستراتيجية، وتصميم البنية التحتية التقنية المتطورة، ومواءمة مبادرات التكنولوجيا مع أهداف العمل لدفع الابتكار وقيادة الفرق إلى النجاح.",
    "program_founders_cert": "برنامج المؤسسين",
    "program_founders_cert_description": "لرواد الأعمال الراسخين وأولئك الذين بدأوا للتو رحلاتهم الناشئة. ستكتسب في هذا البرنامج البصيرة الاستراتيجية، والتفكير التخريبي، ومهارات حل المشكلات المرنة لإطلاق المشاريع وتوسيع نطاقها، وتأمين التمويل، وصياغة العروض التقديمية.",
    "program_executive_cert": "البرنامج التنفيذي",
    "program_executive_cert_description": "انطلق في رحلتك لتصبح قائد أعمال من الجيل التالي. ستكتسب في هذا البرنامج فطنة تجارية حديثة وشاملة للتفوق في الأسواق الديناميكية، والقيادة عبر الوظائف، وتطوير مهارات متعددة الاستخدامات تترجم إلى نجاح تجاري.",
    "program_cmo_cert": "برنامج كبير مسؤولي التسويق",
    "program_cmo_cert_description": "شحذ قيادتك التسويقية بينما تقوم بتسخير الرؤى المستندة إلى البيانات، وتبني التحول الرقمي، وتطوير الاستراتيجيات التي تركز على العملاء. ستتعلم كيفية تسريع النمو من خلال الابتكار والازدهار في المشهد التسويقي متعدد الأوجه اليوم.",
    "program_fin_for_non_fin_managers_cert": "المالية والمحاسبة للمديرين",
    "program_fin_for_non_fin_managers_cert_description": "تطوير المهارات اللازمة للتواصل بثقة واقتراح الاستثمارات التجارية لصناع القرار والخبراء الماليين. ستكتسب في هذا البرنامج فهمًا شاملاً للتحليل المالي، وهو أمر ضروري للقيادة الفعالة واتخاذ القرارات الذكية طوال حياتك المهنية.",
    "program_bus_analytics_leaders_cert": "تحليلات الأعمال للقادة",
    "program_bus_analytics_leaders_cert_description": "ارفع مستوى براعتك القيادية بفضل قوة تحليلات الأعمال. إتقان عملية صنع القرار المستندة إلى البيانات، واستخراج الرؤى القيمة بكفاءة، وتحويل البيانات إلى استراتيجيات مؤثرة. تم تصميم هذا البرنامج للمهنيين في منتصف حياتهم المهنية مع خبرة لا تقل عن 3 سنوات في هذا المجال.",
    "program_data_science_foundations_cert": "أسس علم البيانات",
    "program_data_science_foundations_cert_description": "قم بتأمين حياتك المهنية في المستقبل من خلال الخبرة في البيانات. أتقن لغة Python وSQL والإحصائيات والتحليلات والتعلم الآلي والمزيد بينما تقوم ببناء أساس مفاهيمي قوي وصياغة حلول علوم البيانات الشاملة باستخدام الذكاء الاصطناعي التوليدي. مثالية للمهنيين في وقت مبكر إلى منتصف حياتهم المهنية.",
    "program_tyb_ai_cert": "قم بتحويل أعمالك باستخدام الذكاء الاصطناعي وChatGPT",
    "program_tyb_ai_cert_description": "زود نفسك بمهارات الذكاء الاصطناعي لقيادة تحول الأعمال. استكشف التطبيقات المتطورة للذكاء الاصطناعي وChatGPT، وقم بتحليل المخاطر والهندسة السريعة والديناميكيات المعمارية لتكامل الذكاء الاصطناعي. ابق على اطلاع دائم حيث تتكشف هذه التكنولوجيا ذات التأثير الكبير.",
    "program_prototype_gpt_cert_description": "Master ChatGPT لإنشاء نماذج أولية للمشاريع الرقمية بكفاءة دون خبرة سابقة في البرمجة. تنمية المهارات المطلوبة في تطوير تطبيقات الويب المعتمدة على الذكاء الاصطناعي والتحقق من صحتها واختبارها. باستخدام مجموعة الأدوات هذه، ستتمكن من تسريع أعمالك بكفاءة ورؤية محسنة.",
    "program_ai_for_technical_leaders_cert": "الذكاء الاصطناعي للقادة الفنيين",
    "program_ai_for_technical_leaders_cert_description": "احصل على فهم متطور لأحدث أنظمة الذكاء الاصطناعي وChatGPT والأنظمة المستندة إلى LLM وضبط النماذج واعتماد الذكاء الاصطناعي. ستتعلم كيفية اتخاذ الخيارات الهيكلية، وتخفيف المخاطر، ووضع إستراتيجيات حول الأنظمة والقدرات اللازمة لنشر الذكاء الاصطناعي داخل مؤسستك.",
    "program_tyb_blockchain_cert": "تحويل عملك مع Blockchain",
    "program_tyb_blockchain_cert_description": "تعلم أساسيات blockchain، وفهم التطبيقات الرئيسية، ووضع استراتيجية لاعتماد blockchain والتكامل. يعد هذا البرنامج مثاليًا للمديرين والمهنيين الذين يسعون إلى فهم تطبيقات blockchain العملية لمؤسساتهم.",
    "program_prototype_gpt_cert": "الذكاء الاصطناعي لبناء البرمجيات",
    "unavailable": "غير متاح",
    "pending_degree_application_message": "لا يجوز تقديم أكثر من طلب واحد لكل دورة قبول. يرجى الاتصال بقسم القبول على <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> إذا كنت ترغب في تغيير برنامج التقديم الخاص بك.",
    "register": "يسجل",
    "program_msba_preapproved_description": "برنامج درجة جامعية مدته 13 شهرًا يعمل كمحفز وظيفي، ويجمع بين التفكير الموجه نحو البيانات والقيادة الاستراتيجية للأعمال.",
    "program_msse_preapproved_description": "برنامج درجة مدته 13 شهرًا لإتقان أساسيات هندسة البرمجيات الحديثة، من تطبيقات السحابة والتعلم الآلي إلى هندسة الذكاء الاصطناعي."
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.select_program = window.locales.en.settings.select_program || {};window.locales.en.settings.select_program = {...window.locales.en.settings.select_program, ...{
    "section_degree_programs": "Degree Programs",
    "section_exec_ed": "Executive Education",
    "section_exec_ed_intro": "Intro text goes here",
    "section_rise_to_c_suite": "Rise to the C-Suite",
    "section_rise_to_c_suite_intro": "Equip yourself with role-specific expertise needed to ascend to the highest levels of leadership. These programs are 8 months long and include ample opportunities to connect with amazing peers virtually and in-person. At just $7,600, they are radically affordable compared to other top C-suite certifications.",
    "section_finance_data": "Finance & Data",
    "section_finance_data_intro": "We offer a variety of courses designed to enhance your data, finance, or accounting skills, with options available to accommodate varying levels of quantitative proficiency. These certifications vary in terms of tuition fees and duration.",
    "section_ai_future_of_business": "AI & Future of Business",
    "section_ai_future_of_business_intro": "From adoption strategies, prompt engineering, to coding and prototyping, these programs are designed to give you the tools to leverage AI in your business effectively and efficiently.",
    "learn_more": "Learn More",
    "apply_now": "Apply Now",
    "register": "Register",
    "application_pending": "Application Pending",
    "unavailable": "Unavailable",
    "pending_degree_application_message": "Only one application may be submitted per admissions cycle. Please contact admissions at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> if you would like to change your program of application.",
    "program_mba": "The MBA",
    "program_mba_description": "Accelerate your career with a rigorous program designed for high-potential, early-career learners eager to make a difference.",
    "program_emba": "The Executive MBA",
    "program_emba_description": "Gain key leadership and management skills while collaborating with experienced professionals from top companies around the world.",
    "program_msba": "MS Business Analytics",
    "program_msba_description": "This program is a catalyst for professionals seeking to blend their data-oriented background with strategic leadership.",
    "program_msba_preapproved_description": "A 13-month degree program thats serves as a career catalyst, blending data-oriented thinking with strategic business leadership.",
    "program_msse": "MS Software Engineering",
    "program_msse_description": "Master modern software engineering essentials, from cloud apps, machine learning, to AI engineering, and more.",
    "program_msse_preapproved_description": "A 13-month degree program to master modern software engineering essentials, from cloud apps and machine learning to AI engineering.",
    "program_cto_cert": "CTO Program",
    "program_cto_cert_description": "Ideal for rising technical leaders and aspiring future CTOs, you'll learn strategic roadmapping, architect cutting-edge tech infrastructure, and align technology initiatives with business goals to drive innovation and lead teams to success.",
    "program_founders_cert": "Founders Program",
    "program_founders_cert_description": "For established entrepreneurs and those just beginning their start-up journeys. In this program you’ll gain strategic foresight, disruptive thinking, and agile problem-solving skills to launch and scale ventures, secure funding, and craft pitches.",
    "program_executive_cert": "Executive Program",
    "program_executive_cert_description": "Embark upon your journey to becoming a next-gen business leader. In this program you’ll gain modern, holistic business acumen to excel in dynamic markets, lead across functions, and develop versatile skills that translate to commercial success.",
    "program_cmo_cert": "CMO Program",
    "program_cmo_cert_description": "Sharpen your marketing leadership as you harness data-driven insights, embrace digital transformation, and cultivate customer-centric strategies. You’ll learn to accelerate growth through innovation and thrive in today’s multifaceted marketing landscape.",
    "program_fin_for_non_fin_managers_cert": "Finance and Accounting for Managers",
    "program_fin_for_non_fin_managers_cert_description": "Develop skills to confidently communicate and propose business investments to decision-makers and financial experts. In this program you will gain a holistic understanding of financial analysis, essential for effective leadership and smart decision-making throughout your career.",
    "program_bus_analytics_leaders_cert": "Business Analytics for Leaders",
    "program_bus_analytics_leaders_cert_description": "Elevate your leadership prowess with the power of business analytics. Master data-driven decision-making, efficiently extract valuable insights, and turn data into impactful strategies. This program is tailored for mid-career professionals with a minimum of 3 years relevant experience.",
    "program_data_science_foundations_cert": "Data Science Foundations",
    "program_data_science_foundations_cert_description": "Future-proof your career with data expertise. Master Python, SQL, statistics, analytics, machine learning, and more as you build a strong conceptual foundation and craft end-to-end data science solutions using generative AI. Ideal for early to mid career professionals.",
    "program_tyb_ai_cert": "Transform Your Business with AI and ChatGPT",
    "program_tyb_ai_cert_description": "Equip yourself with AI skills to lead business transformation. Explore cutting-edge applications of AI and ChatGPT, analyze risks, prompt engineering, and architectural dynamics for AI integration. Stay current as this immensely impactful tech unfolds.",
    "program_prototype_gpt_cert": "AI for Building Software",
    "program_prototype_gpt_cert_description": "Master ChatGPT to efficiently prototype digital ventures without prior coding experience. Cultivate in-demand skills in AI-driven Web application development, validation, and testing. With this toolkit, you’ll accelerate your business with enhanced efficiency and insight.",
    "program_ai_for_technical_leaders_cert": "AI for Technical Leaders",
    "program_ai_for_technical_leaders_cert_description": "Gain a sophisticated understanding of the latest in AI and ChatGPT, LLM-based systems, model fine-tuning, and AI adoption. You’ll learn to make architectural choices, mitigate risks, and strategize around the systems and capabilities needed to deploy AI within your organization.",
    "program_tyb_blockchain_cert": "Transform Your Business with Blockchain",
    "program_tyb_blockchain_cert_description": "Learn blockchain fundamentals, understand key applications, and strategize blockchain adoption and integration. This program is ideal for managers and professionals seeking to understand  practical blockchain applications for their organization."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.select_program = window.locales.es.settings.select_program || {};window.locales.es.settings.select_program = {...window.locales.es.settings.select_program, ...{
    "section_degree_programs": "Programas de grado",
    "section_exec_ed": "Educación ejecutiva",
    "section_exec_ed_intro": "El texto de introducción va aquí.",
    "section_rise_to_c_suite": "Ascienda a la C-Suite",
    "section_rise_to_c_suite_intro": "Equípese con la experiencia específica necesaria para ascender a los niveles más altos de liderazgo. Estos programas tienen una duración de 8 meses e incluyen amplias oportunidades para conectarse con compañeros increíbles de forma virtual y en persona. Por sólo $7,600, son radicalmente asequibles en comparación con otras certificaciones de alto nivel C-suite.",
    "section_finance_data": "Finanzas y datos",
    "section_finance_data_intro": "Ofrecemos una variedad de cursos diseñados para mejorar sus habilidades en datos, finanzas o contabilidad, con opciones disponibles para adaptarse a diferentes niveles de dominio cuantitativo. Estas certificaciones varían en términos de tasas de matrícula y duración.",
    "section_ai_future_of_business": "IA y el futuro de los negocios",
    "section_ai_future_of_business_intro": "Desde estrategias de adopción, ingeniería rápida hasta codificación y creación de prototipos, estos programas están diseñados para brindarle las herramientas para aprovechar la IA en su negocio de manera efectiva y eficiente.",
    "learn_more": "Aprende más",
    "apply_now": "Aplica ya",
    "application_pending": "Aplicación pendiente",
    "program_mba": "El MBA",
    "program_mba_description": "Acelere su carrera con un programa riguroso diseñado para estudiantes de alto potencial que inician su carrera y están ansiosos por marcar la diferencia.",
    "program_emba": "El MBA Ejecutivo",
    "program_emba_description": "Obtenga habilidades clave de liderazgo y gestión mientras colabora con profesionales experimentados de las principales empresas de todo el mundo.",
    "program_msba": "Análisis empresarial de MS",
    "program_msba_description": "Este programa es un catalizador para profesionales que buscan combinar su experiencia orientada a datos con liderazgo estratégico.",
    "program_msse": "Maestría en Ingeniería de Software",
    "program_msse_description": "Domine los conceptos básicos de la ingeniería de software moderna, desde aplicaciones en la nube, aprendizaje automático hasta ingeniería de inteligencia artificial y más.",
    "program_cto_cert": "Programa CTO",
    "program_cto_cert_description": "Ideal para líderes técnicos en ascenso y aspirantes a futuros CTO, aprenderá hojas de ruta estratégicas, diseñará infraestructura tecnológica de vanguardia y alineará iniciativas tecnológicas con objetivos comerciales para impulsar la innovación y liderar equipos hacia el éxito.",
    "program_founders_cert": "Programa de fundadores",
    "program_founders_cert_description": "Para emprendedores establecidos y aquellos que recién comienzan su andadura como emprendedores. En este programa, obtendrá previsión estratégica, pensamiento disruptivo y habilidades ágiles de resolución de problemas para lanzar y escalar empresas, asegurar financiación y elaborar presentaciones.",
    "program_executive_cert": "Programa Ejecutivo",
    "program_executive_cert_description": "Embárquese en su viaje para convertirse en un líder empresarial de próxima generación. En este programa, obtendrá una visión empresarial moderna y holística para sobresalir en mercados dinámicos, liderar funciones y desarrollar habilidades versátiles que se traduzcan en éxito comercial.",
    "program_cmo_cert": "Programa CMO",
    "program_cmo_cert_description": "Perfeccione su liderazgo en marketing mientras aprovecha los conocimientos basados en datos, adopta la transformación digital y cultiva estrategias centradas en el cliente. Aprenderá a acelerar el crecimiento a través de la innovación y a prosperar en el multifacético panorama del marketing actual.",
    "program_fin_for_non_fin_managers_cert": "Finanzas y Contabilidad para Gerentes",
    "program_fin_for_non_fin_managers_cert_description": "Desarrollar habilidades para comunicar con confianza y proponer inversiones comerciales a tomadores de decisiones y expertos financieros. En este programa obtendrá una comprensión holística del análisis financiero, esencial para un liderazgo eficaz y una toma de decisiones inteligente a lo largo de su carrera.",
    "program_bus_analytics_leaders_cert": "Análisis de negocios para líderes",
    "program_bus_analytics_leaders_cert_description": "Eleve su capacidad de liderazgo con el poder del análisis empresarial. Domine la toma de decisiones basada en datos, extraiga eficientemente información valiosa y convierta los datos en estrategias impactantes. Este programa está diseñado para profesionales de mitad de carrera con un mínimo de 3 años de experiencia relevante.",
    "program_data_science_foundations_cert": "Fundamentos de la ciencia de datos",
    "program_data_science_foundations_cert_description": "Prepare su carrera para el futuro con experiencia en datos. Domine Python, SQL, estadísticas, análisis, aprendizaje automático y más mientras construye una base conceptual sólida y crea soluciones de ciencia de datos de un extremo a otro utilizando IA generativa. Ideal para profesionales de inicio y mitad de carrera.",
    "program_tyb_ai_cert": "Transforme su negocio con IA y ChatGPT",
    "program_tyb_ai_cert_description": "Equípese con habilidades de IA para liderar la transformación empresarial. Explore aplicaciones de vanguardia de IA y ChatGPT, analice riesgos, proponga ingeniería y dinámicas arquitectónicas para la integración de IA. Manténgase actualizado a medida que se desarrolla esta tecnología inmensamente impactante.",
    "program_prototype_gpt_cert_description": "Domine ChatGPT para crear prototipos eficientes de empresas digitales sin experiencia previa en codificación. Cultive las habilidades más demandadas en el desarrollo, la validación y las pruebas de aplicaciones web impulsadas por IA. Con este conjunto de herramientas, acelerará su negocio con mayor eficiencia y conocimiento.",
    "program_ai_for_technical_leaders_cert": "IA para líderes técnicos",
    "program_ai_for_technical_leaders_cert_description": "Obtenga una comprensión sofisticada de lo último en IA y ChatGPT, sistemas basados en LLM, ajuste de modelos y adopción de IA. Aprenderá a tomar decisiones arquitectónicas, mitigar riesgos y elaborar estrategias en torno a los sistemas y capacidades necesarios para implementar IA dentro de su organización.",
    "program_tyb_blockchain_cert": "Transforme su negocio con Blockchain",
    "program_tyb_blockchain_cert_description": "Aprenda los fundamentos de blockchain, comprenda las aplicaciones clave y diseñe estrategias para la adopción e integración de blockchain. Este programa es ideal para gerentes y profesionales que buscan comprender las aplicaciones prácticas de blockchain para su organización.",
    "program_prototype_gpt_cert": "IA para software de construcción",
    "unavailable": "Indisponible",
    "pending_degree_application_message": "Solo se puede enviar una solicitud por ciclo de admisión. Comuníquese con el departamento de admisiones a <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> si desea cambiar su programa de solicitud.",
    "register": "Registro",
    "program_msba_preapproved_description": "Un programa de grado de 13 meses que sirve como catalizador profesional, combinando el pensamiento orientado a datos con el liderazgo empresarial estratégico.",
    "program_msse_preapproved_description": "Un programa de grado de 13 meses para dominar los conceptos básicos de la ingeniería de software moderna, desde aplicaciones en la nube y aprendizaje automático hasta ingeniería de inteligencia artificial."
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.select_program = window.locales.it.settings.select_program || {};window.locales.it.settings.select_program = {...window.locales.it.settings.select_program, ...{
    "section_degree_programs": "Corsi di laurea",
    "section_exec_ed": "Formazione esecutiva",
    "section_exec_ed_intro": "Il testo introduttivo va qui",
    "section_rise_to_c_suite": "Salita alla C-Suite",
    "section_rise_to_c_suite_intro": "Dotatevi delle competenze specifiche del ruolo necessarie per ascendere ai più alti livelli di leadership. Questi programmi durano 8 mesi e includono ampie opportunità per entrare in contatto con colleghi straordinari virtualmente e di persona. A soli $ 7.600, sono radicalmente convenienti rispetto ad altre certificazioni di alto livello.",
    "section_finance_data": "Finanza e dati",
    "section_finance_data_intro": "Offriamo una varietà di corsi progettati per migliorare le tue competenze in materia di dati, finanza o contabilità, con opzioni disponibili per soddisfare diversi livelli di competenza quantitativa. Queste certificazioni variano in termini di tasse universitarie e durata.",
    "section_ai_future_of_business": "IA e futuro del business",
    "section_ai_future_of_business_intro": "Dalle strategie di adozione, all'ingegneria tempestiva, alla codifica e alla prototipazione, questi programmi sono progettati per fornirti gli strumenti per sfruttare l'intelligenza artificiale nella tua azienda in modo efficace ed efficiente.",
    "learn_more": "Saperne di più",
    "apply_now": "Applica ora",
    "application_pending": "Domanda in sospeso",
    "program_mba": "L'MBA",
    "program_mba_description": "Accelera la tua carriera con un programma rigoroso progettato per studenti ad alto potenziale all'inizio della carriera desiderosi di fare la differenza.",
    "program_emba": "L'Executive MBA",
    "program_emba_description": "Acquisisci competenze chiave di leadership e gestione collaborando con professionisti esperti delle migliori aziende di tutto il mondo.",
    "program_msba": "Analisi aziendale MS",
    "program_msba_description": "Questo programma è un catalizzatore per i professionisti che cercano di fondere il loro background orientato ai dati con la leadership strategica.",
    "program_msse": "Ingegneria del software MS",
    "program_msse_description": "Padroneggia gli elementi essenziali dell'ingegneria del software moderno, dalle app cloud, al machine learning, all'ingegneria dell'intelligenza artificiale e altro ancora.",
    "program_cto_cert": "Programma CTO",
    "program_cto_cert_description": "Ideale per leader tecnici emergenti e aspiranti futuri CTO, imparerai la roadmapping strategica, architetterai infrastrutture tecnologiche all'avanguardia e allineerai le iniziative tecnologiche con gli obiettivi aziendali per guidare l'innovazione e guidare i team verso il successo.",
    "program_founders_cert": "Programma dei Fondatori",
    "program_founders_cert_description": "Per imprenditori affermati e per coloro che hanno appena iniziato il loro percorso di start-up. In questo programma acquisirai lungimiranza strategica, pensiero dirompente e agili capacità di risoluzione dei problemi per lanciare e ampliare iniziative imprenditoriali, garantire finanziamenti e presentazioni artigianali.",
    "program_executive_cert": "Programma esecutivo",
    "program_executive_cert_description": "Intraprendi il tuo viaggio per diventare un leader aziendale di nuova generazione. In questo programma acquisirai un senso degli affari moderno e olistico per eccellere in mercati dinamici, guidare diverse funzioni e sviluppare competenze versatili che si traducono in successo commerciale.",
    "program_cmo_cert": "Programma OCM",
    "program_cmo_cert_description": "Affina la tua leadership nel marketing sfruttando informazioni basate sui dati, abbracciando la trasformazione digitale e coltivando strategie incentrate sul cliente. Imparerai ad accelerare la crescita attraverso l'innovazione e a prosperare nel multiforme panorama del marketing di oggi.",
    "program_fin_for_non_fin_managers_cert": "Finanza e contabilità per dirigenti",
    "program_fin_for_non_fin_managers_cert_description": "Sviluppare competenze per comunicare con sicurezza e proporre investimenti aziendali a decisori ed esperti finanziari. In questo programma acquisirai una comprensione olistica dell'analisi finanziaria, essenziale per una leadership efficace e un processo decisionale intelligente durante la tua carriera.",
    "program_bus_analytics_leaders_cert": "Analisi aziendale per leader",
    "program_bus_analytics_leaders_cert_description": "Migliora la tua abilità di leadership con la potenza dell'analisi aziendale. Padroneggia il processo decisionale basato sui dati, estrai in modo efficiente informazioni preziose e trasforma i dati in strategie di grande impatto. Questo programma è pensato per professionisti a metà carriera con un minimo di 3 anni di esperienza nel settore.",
    "program_data_science_foundations_cert": "Fondamenti della scienza dei dati",
    "program_data_science_foundations_cert_description": "Rendi la tua carriera a prova di futuro grazie alla competenza in materia di dati. Padroneggia Python, SQL, statistica, analisi, machine learning e altro ancora mentre costruisci una solida base concettuale e crei soluzioni di data science end-to-end utilizzando l'intelligenza artificiale generativa. Ideale per professionisti dall'inizio alla metà della carriera.",
    "program_tyb_ai_cert": "Trasforma il tuo business con AI e ChatGPT",
    "program_tyb_ai_cert_description": "Acquisisci competenze di intelligenza artificiale per guidare la trasformazione aziendale. Esplora applicazioni all'avanguardia di AI e ChatGPT, analizza i rischi, la progettazione tempestiva e le dinamiche architetturali per l'integrazione dell'IA. Rimani aggiornato mentre questa tecnologia di immenso impatto si sviluppa.",
    "program_prototype_gpt_cert_description": "Padroneggia ChatGPT per prototipare in modo efficiente iniziative digitali senza precedenti esperienze di codifica. Coltiva le competenze richieste nello sviluppo, nella convalida e nei test di applicazioni Web basati sull'intelligenza artificiale. Con questo toolkit accelererai il tuo business con maggiore efficienza e insight.",
    "program_ai_for_technical_leaders_cert": "Intelligenza artificiale per leader tecnici",
    "program_ai_for_technical_leaders_cert_description": "Ottieni una conoscenza sofisticata delle ultime novità in fatto di intelligenza artificiale e ChatGPT, sistemi basati su LLM, messa a punto dei modelli e adozione dell'intelligenza artificiale. Imparerai a fare scelte architetturali, mitigare i rischi e definire strategie per i sistemi e le capacità necessarie per implementare l'intelligenza artificiale all'interno della tua organizzazione.",
    "program_tyb_blockchain_cert": "Trasforma il tuo business con Blockchain",
    "program_tyb_blockchain_cert_description": "Apprendi i fondamenti della blockchain, comprendi le applicazioni chiave e definisci strategie per l'adozione e l'integrazione della blockchain. Questo programma è ideale per manager e professionisti che cercano di comprendere le applicazioni pratiche della blockchain per la propria organizzazione.",
    "program_prototype_gpt_cert": "Intelligenza artificiale per la creazione di software",
    "unavailable": "Non disponibile",
    "pending_degree_application_message": "È possibile inviare una sola domanda per ciclo di ammissione. Contattare admissions all'indirizzo <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> se si desidera modificare il programma di candidatura.",
    "register": "Registro",
    "program_msba_preapproved_description": "Un corso di laurea di 13 mesi che funge da catalizzatore di carriera, unendo il pensiero orientato ai dati alla leadership aziendale strategica.",
    "program_msse_preapproved_description": "Un corso di laurea di 13 mesi per padroneggiare i fondamenti dell'ingegneria del software moderno, dalle app cloud all'apprendimento automatico fino all'ingegneria dell'intelligenza artificiale."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.select_program = window.locales.zh.settings.select_program || {};window.locales.zh.settings.select_program = {...window.locales.zh.settings.select_program, ...{
    "section_degree_programs": "学位课程",
    "section_exec_ed": "高管教育",
    "section_exec_ed_intro": "此处显示简介文字",
    "section_rise_to_c_suite": "晋升至高管层",
    "section_rise_to_c_suite_intro": "掌握晋升至最高领导层所需的特定角色专业知识。这些课程为期 8 个月，包括大量与优秀同事进行线上和线下交流的机会。这些课程仅需 7,600 美元，与其他顶级 C 级认证相比，价格非常实惠。",
    "section_finance_data": "财务与数据",
    "section_finance_data_intro": "我们提供各种课程，旨在提高您的数据、财务或会计技能，并提供多种选项以适应不同级别的量化能力。这些认证的学费和持续时间各不相同。",
    "section_ai_future_of_business": "人工智能与商业的未来",
    "section_ai_future_of_business_intro": "从采用策略、快速工程到编码和原型设计，这些程序旨在为您提供在业务中有效、高效地利用人工智能的工具。",
    "learn_more": "了解更多",
    "apply_now": "现在申请",
    "application_pending": "申请待决",
    "program_mba": "工商管理硕士",
    "program_mba_description": "通过专为渴望有所作为的高潜力早期职业学习者设计的严格计划来加速您的职业生涯。",
    "program_emba": "高级工商管理硕士",
    "program_emba_description": "与来自世界各地顶尖公司的经验丰富的专业人士合作，获得关键的领导和管理技能。",
    "program_msba": "商业分析硕士",
    "program_msba_description": "该项目对于寻求将数据导向背景与战略领导力相结合的专业人士来说是一个催化剂。",
    "program_msse": "软件工程硕士",
    "program_msse_description": "掌握现代软件工程基础知识，从云应用、机器学习到人工智能工程等。",
    "program_cto_cert": "首席技术官计划",
    "program_cto_cert_description": "这对于新兴的技术领导者和有抱负的未来首席技术官来说是理想的选择，您将学习战略路线图，构建尖端技术基础设施，并将技术计划与业务目标相结合，以推动创新并带领团队走向成功。",
    "program_founders_cert": "创始人计划",
    "program_founders_cert_description": "适合成熟企业家和刚刚开始创业之旅的企业家。在本课程中，您将获得战略远见、颠覆性思维和敏捷的解决问题的技能，以启动和扩大企业规模、获得资金和制定宣传方案。",
    "program_executive_cert": "执行计划",
    "program_executive_cert_description": "踏上成为下一代商业领袖的旅程。在本课程中，您将获得现代、全面的商业敏锐度，在动态市场中脱颖而出，领导跨职能部门，并培养转化为商业成功的多才多艺的技能。",
    "program_cmo_cert": "CMO 计划",
    "program_cmo_cert_description": "提高您的营销领导力，利用数据驱动的洞察力，拥抱数字化转型，培养以客户为中心的战略。您将学会通过创新加速增长，并在当今多元化的营销环境中蓬勃发展。",
    "program_fin_for_non_fin_managers_cert": "管理人员财务与会计",
    "program_fin_for_non_fin_managers_cert_description": "培养自信地与决策者和金融专家沟通并提出商业投资建议的技能。在本课程中，您将全面了解财务分析，这对于您整个职业生涯中有效的领导和明智的决策至关重要。",
    "program_bus_analytics_leaders_cert": "领导者的商业分析",
    "program_bus_analytics_leaders_cert_description": "利用商业分析的力量提升您的领导能力。掌握数据驱动的决策，高效提取有价值的见解，并将数据转化为有影响力的战略。本课程专为拥有至少 3 年相关经验的中期职业人士量身定制。",
    "program_data_science_foundations_cert": "数据科学基础",
    "program_data_science_foundations_cert_description": "利用数据专业知识为您的职业生涯做好未来准备。掌握 Python、SQL、统计、分析、机器学习等知识，构建强大的概念基础，并使用生成式 AI 构建端到端数据科学解决方案。非常适合职业生涯早期至中期的专业人士。",
    "program_tyb_ai_cert": "利用 AI 和 ChatGPT 改变您的业务",
    "program_tyb_ai_cert_description": "掌握 AI 技能，引领业务转型。探索 AI 和 ChatGPT 的前沿应用，分析风险，促进工程设计和 AI 集成的架构动态。随时关注这项影响深远的技术的发展。",
    "program_prototype_gpt_cert_description": "掌握 ChatGPT 后，无需任何编码经验即可高效地为数字企业制作原型。培养 AI 驱动的 Web 应用程序开发、验证和测试方面的热门技能。借助此工具包，您将以更高的效率和洞察力加速业务发展。",
    "program_ai_for_technical_leaders_cert": "面向技术领导者的 AI",
    "program_ai_for_technical_leaders_cert_description": "深入了解最新的 AI 和 ChatGPT、基于 LLM 的系统、模型微调和 AI 采用。您将学习如何做出架构选择、降低风险并制定在组织内部署 AI 所需的系统和功能的战略。",
    "program_tyb_blockchain_cert": "利用区块链改变你的业务",
    "program_tyb_blockchain_cert_description": "学习区块链基础知识、了解关键应用并制定区块链采用和集成战略。本课程非常适合希望了解组织中实用区块链应用的管理人员和专业人士。",
    "program_prototype_gpt_cert": "用于构建软件的人工智能",
    "unavailable": "不可用",
    "pending_degree_application_message": "每个招生周期只能提交一份申请。如果您想更改申请课程，请联系招生办公室<a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> 。",
    "register": "登记",
    "program_msba_preapproved_description": "为期 13 个月的学位课程可作为职业催化剂，将数据导向思维与战略商业领导力融为一体。",
    "program_msse_preapproved_description": "为期 13 个月的学位课程，旨在掌握现代软件工程基础知识，从云应用和机器学习到人工智能工程。"
}
};