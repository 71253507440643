import { type FC } from 'react';
import { twMerge } from 'tailwind-merge';

const variantBaseClasses = {
    h1: 'text-[25px] font-semibold text-black',
    h2: 'text-[25px] font-semibold text-black',
    h3: 'text-[25px] font-semibold text-black',
    h4: 'text-lg font-semibold text-black',
    h5: 'text-lg font-medium text-black',
    h6: 'text-lg font-medium text-black',
    p: 'text-base leading-[1.4em] text-slate-grey',
    small: 'text-xs leading-[1.4em] text-slate-grey',
};

type Props = {
    as?: keyof typeof variantBaseClasses;
    variant?: keyof typeof variantBaseClasses;
    className?: string;
    children: string;
    color?: 'black' | 'slate-grey';
    uppercase?: boolean;
};

export const Text: FC<Props> = ({
    as: Component = 'p',
    variant,
    uppercase = false,
    color = 'black',
    children,
    className,
}) => (
    <Component
        className={twMerge(
            variantBaseClasses[variant || Component],
            color && `text-${color}`,
            uppercase && 'uppercase',
            className,
        )}
    >
        {children}
    </Component>
);
