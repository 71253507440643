import { type FC, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSuspenseFeatureGate } from 'FrontRoyalStatsig';
import { TopMessageV1, BookmarkedCompletedCoursesv1 } from './studentDashboardNgDirectives';
import { StudyBox } from './StudyBox';

function Placeholder({ className, children }: { className?: string; children: React.ReactNode }) {
    // merge "rounded border border-gray-300 bg-white" into the className
    return <div className={twMerge('rounded border border-gray-300 bg-white p-2', className)}>{children}</div>;
}

function WelcomeMessageGate() {
    const welcomeMessageVersion = useSuspenseFeatureGate('sd_welcome_message_v2') ? 2 : 1;

    return welcomeMessageVersion === 1 ? (
        <TopMessageV1 />
    ) : (
        <Placeholder className="min-h-[94px]">Welcome Message</Placeholder>
    );
}

function BookmarkedCompletedCoursesGate() {
    const bookmarkedCoursesVersion = useSuspenseFeatureGate('sd_bookmarked_courses_v2') ? 2 : 1;

    return bookmarkedCoursesVersion === 1 ? (
        <BookmarkedCompletedCoursesv1 />
    ) : (
        <Placeholder className="min-h-[280px]">Bookmarked Courses</Placeholder>
    );
}

// eslint-disable-next-line arrow-body-style
const StudentDashboardComponent: FC = () => {
    // const featuredStudentsAndEventsVersion = StatsigSidecar?.checkGate('sd_featured_students_and_events_v2') ? 2 : 1;
    // const nextUpAndCurrentlyStudyingVersion = StatsigSidecar?.checkGate('sd_next_up_and_currently_studying_v2') ? 2 : 1;
    // const sidebarVersion = StatsigSidecar?.checkGate('sd_sidebar_v2') ? 2 : 1;

    // // The app menu has to be updated before the new curriculum version can
    // // be live, because we want to remove the courses section of the app at
    // // the same time as we update the curriculum version here
    // let curriculumVersion: number;
    // if (StatsigSidecar?.checkGate('app_menu_tabs_v2')) {
    //     curriculumVersion = StatsigSidecar?.checkGate('sd_curriculum_v2') ? 2 : 1;
    // }

    return (
        <div className="mx-auto flex max-w-[966px] flex-col gap-4 sm:py-7">
            {/* First Row */}
            <div className="flex flex-col gap-4 sm:flex-row">
                {/* Main Content */}
                <div className="flex w-full flex-col gap-4 sm:w-3/4">
                    <WelcomeMessageGate />
                    <Placeholder className="min-h-[120px]">Next Up</Placeholder>
                    <StudyBox />
                    <Placeholder className="min-h-[280px]">Meet Your Class</Placeholder>
                    <Placeholder className="min-h-[454px]">Upcoming Events</Placeholder>
                </div>

                {/* Sidebar */}
                <Placeholder className="sm:hide w-full max-w-[301px]">Sidebar</Placeholder>
            </div>

            {/* Second Row */}
            <div className="bookmarked-courses-container w-full">
                <BookmarkedCompletedCoursesGate />
            </div>
        </div>
    );
};

export const StudentDashboard = memo(StudentDashboardComponent) as typeof StudentDashboardComponent;
