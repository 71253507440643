window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.telephone = window.locales.am.telephone || {};window.locales.am.telephone.tel_input = window.locales.am.telephone.tel_input || {};window.locales.am.telephone.tel_input = {...window.locales.am.telephone.tel_input, ...{
    "invalid_phone": "ልክ ያልሆነ የስልክ ቁጥር",           
    "extension_placeholder": "ቅጥያ",           
    "please_enter_your_phone_number": "እባክዎን ስልክ ቁጥርዎን ያስገቡ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.telephone = window.locales.ar.telephone || {};window.locales.ar.telephone.tel_input = window.locales.ar.telephone.tel_input || {};window.locales.ar.telephone.tel_input = {...window.locales.ar.telephone.tel_input, ...{
    "invalid_phone": "رقم الهاتف غير صحيح",           
    "extension_placeholder": "الهاتف الفرعي",           
    "please_enter_your_phone_number": "يرجى إدخال رقم الهاتف الخاص بك"           
}
};window.locales.en = window.locales.en || {};window.locales.en.telephone = window.locales.en.telephone || {};window.locales.en.telephone.tel_input = window.locales.en.telephone.tel_input || {};window.locales.en.telephone.tel_input = {...window.locales.en.telephone.tel_input, ...{
    "invalid_phone": "Invalid phone number",
    "extension_placeholder": "Ext.",
    "please_enter_your_phone_number": "Please enter your phone number"
}
};window.locales.es = window.locales.es || {};window.locales.es.telephone = window.locales.es.telephone || {};window.locales.es.telephone.tel_input = window.locales.es.telephone.tel_input || {};window.locales.es.telephone.tel_input = {...window.locales.es.telephone.tel_input, ...{
    "invalid_phone": "Número de teléfono inválido",           
    "extension_placeholder": "Ext.",           
    "please_enter_your_phone_number": "Por favor, introduzca su número de teléfono"           
}
};window.locales.it = window.locales.it || {};window.locales.it.telephone = window.locales.it.telephone || {};window.locales.it.telephone.tel_input = window.locales.it.telephone.tel_input || {};window.locales.it.telephone.tel_input = {...window.locales.it.telephone.tel_input, ...{
    "invalid_phone": "Numero di telefono non valido",           
    "extension_placeholder": "Est.",           
    "please_enter_your_phone_number": "Per favore, inserisca il suo numero di telefono"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.telephone = window.locales.zh.telephone || {};window.locales.zh.telephone.tel_input = window.locales.zh.telephone.tel_input || {};window.locales.zh.telephone.tel_input = {...window.locales.zh.telephone.tel_input, ...{
    "invalid_phone": "电话号码无效",           
    "extension_placeholder": "Ext.",           
    "please_enter_your_phone_number": "请输入您的电话号码"           
}
};