window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.settings = window.locales.am.settings.settings || {};window.locales.am.settings.settings = {...window.locales.am.settings.settings, ...{
    "notifications": "ማሳወቂያዎች",           
    "preferences": "ምርጫዎች",           
    "network": "አውታረ መረብ",           
    "profile": "ግለ-መገለጫ",           
    "sign_out": "ይዉጡ",           
    "billing": "ክፍያ",           
    "account": "መለያ",           
    "transcripts": "ትራንስክሪፕቶች",           
    "documents": "ሰነዶች",           
    "settings_title": "ቅንብሮች",           
    "my-profile": "ግለ-መገለጫ",           
    "application_status": "የማመልከቻ ሁኔታ",           
    "deferred_application_status_x": "ለ {{programTitle}}ተላልፏል",           
    "congratulations_you_are_in": "እንኳን ደስ ያለዎት፣ እርስዎ ተቀላቅለዋል!",           
    "you_are_now_registered": "እርስዎ አሁን ተመዝግበዋል!",           
    "you_have_secured_enrollment_in_x": "እርስዎ በ <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> ክፍል ውስጥ ምዝገባዎን አስጠብቀዋል።<br class=\"hidden-xs\"> ማንኛዉም ጥያቄዎች ካልዎት፣ እባክዎ በ<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.ላይ ኢሜይል ያድርጉልን።",           
    "you_have_secured_enrollment_in_x_no_guide": "በ {{cohortTitle}}ክፍል ውስጥ ምዝገባዎን አስጠብቀዋል።<br> ማንኛዉም ጥያቄዎች ካለዎት፣ እባክዎ በ<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ላይ ኢሜይል ያድርጉልን።",           
    "registration_opens_on": "ምዝገባ የሚከፈተው በ <strong>{{registrationOpen}}</strong>።",           
    "class_begins_on_x": "ክፍል በ{{startDate}} ላይ ተጀምሯል",           
    "graduation_requirements_not_met": "የምረቃ መስፈርቶች አልተሟሉም",           
    "mba": "MBA",           
    "application": "ለ {{brandName}} ያመልክቱ",           
    "mba_documentation": "ሰነድ",           
    "team_header": "የቡድን መገለጫ",           
    "candidates_header": "የእጩዎች ምርጫዎች",           
    "preview_header": "የግለ-መገለጫዎን ይመልከቱ",           
    "company_sub_header": "የእርስዎን የኩባንያ መገለጫ ያጠናቅቁ",           
    "team_sub_header": "እርስዎ ስለሚቀጥሩት ቡድን ለእጩ ሰዎች ትንሽ ይናገሩ",           
    "you_sub_header": "የእርስዎን የቅጥር ሃላፊ መገለጫ ያጠናቅቁ",           
    "candidates_sub_header": "ይህ የተሻል እጩዎችን ለእርስዎ እንድናቀርብ ይረዳናል",           
    "preview_sub_header": "የእጩዎችን መገለጫዎች ሲወዷቸው እነርሱ ይህንን ያያሉ",           
    "your_account_is_locked": "የእርስዎ መለያ ተቆልፏል",           
    "please_contact_the_bursar": "የእኛ መዛግብት እንደሚያሳዩት የእርሶ መለያ አንድ ወይም ከዚያ በላይ አስፈላጊ ክፍያዎችን ዘልሏል። ይህን ችግር ለመፍታት በ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ላይ Bursar ን በኢሜይል ያግኙ።",           
    "registration_incomplete": "የእርስዎ ምዝገባ የተሟላ አይደለም።",           
    "follow_steps_to_secure": "በ {{cohortTitle}} ያለዎትን ቦታ ለማስጠበቅ ከስር ያሉትን ደረጃዎች እባክዎን ይከተሉ።",           
    "faq": "በተደጋጋሚ የሚጠየቁ ጥያቄዎች",           
    "program_status": "የፕሮግራም ሁኔታ",
    "feedback": "ምላሽ ይስጡ",
    "switch_program": "ፕሮግራም መቀየር",
    "apply_to_a_program": "ለአንድ ፕሮግራም ያመልክቱ",
    "now_secure_place_in_x": "አሁን በ <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> ክፍል ውስጥ ቦታዎን በማስጠበቅ የክፍል ጓደኞችዎን ይቀላቀሉ።",
    "now_secure_place_in_x_no_guide": "አሁን በ {{cohortTitle}} ክፍል ውስጥ ቦታዎን በማስጠበቅ የክፍል ጓደኞችዎን ይቀላቀሉ።",
    "application_deadline_reminder": "መጪው የማመልከቻ ቀነ-ገደብ {{deadline}} ነው።",
    "register_or_review_exec_ed": "አሁን ለ {{cohortTitle}} ይመዝገቡ።<br /> ወይም ሁሉንም ብቁ የሆኑ <a ng-click=\"openCongratsModal()\">የአስፈፃሚ ትምህርት ሰርተፊኬቶችን</a> ይከልሱ።",
    "nominations": "እጩዎች",
    "menu_title": "ዝርዝር",
    "grades": "ደረጃዎች",
    "select-program": "ለአንድ ፕሮግራም ያመልክቱ",
    "application_program_type_mba_emba": "MBA/EMBA መተግበሪያ",
    "application_program_type_msba": "MSBA መተግበሪያ",
    "application_program_type_msse": "የ MSSE መተግበሪያ",
    "application_program_type_ai_for_technical_leaders_cert": "AI ለቴክኒክ መሪዎች የምስክር ወረቀት ማመልከቻ",
    "application_program_type_bus_analytics_leaders_cert": "የንግድ ትንታኔ ለመሪዎች የምስክር ወረቀት ማመልከቻ",
    "application_program_type_cmo_cert": "የCMO ፕሮግራም የምስክር ወረቀት ማመልከቻ",
    "application_program_type_cto_cert": "የ CTO ፕሮግራም የምስክር ወረቀት ማመልከቻ",
    "application_program_type_data_science_foundations_cert": "የውሂብ ሳይንስ መሠረቶች የምስክር ወረቀት ማመልከቻ",
    "application_program_type_executive_cert": "የሥራ አስፈፃሚ ፕሮግራም የምስክር ወረቀት ማመልከቻ",
    "application_program_type_founders_cert": "መስራቾች ፕሮግራም የምስክር ወረቀት ማመልከቻ",
    "application_program_type_learn_code_gpt_cert": "የ ChatGPT የምስክር ወረቀት መተግበሪያን በመጠቀም ኮድ ማድረግን ይማሩ",
    "application_program_type_tyb_ai_cert": "ንግድዎን በ AI እና ChatGPT የምስክር ወረቀት መተግበሪያ ይለውጡ",
    "application_program_type_tyb_blockchain_cert": "ንግድዎን በብሎክቼይን የምስክር ወረቀት መተግበሪያ ይለውጡ",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">ፕሮግራም ቀይር</a>",
    "application_program_type_prototype_gpt_cert": "AI ለግንባታ የሶፍትዌር የምስክር ወረቀት ማመልከቻ",
    "edit_application": "መተግበሪያን ያርትዑ",
    "affordable_tuition_modal": "ለቴክኖሎጂ እና ለድጋፍ ፈጠራዎች ምስጋና ይግባውና በፕሮግራሙ ሂደት በወር በ$ {{standardMonthlyPayment}} የአሜሪካ ዶላር ከባህላዊ ዲግሪዎች ክፍልፋይ ነው።",
    "application_program_type_fin_for_managers_cert": "የገንዘብ እና የሂሳብ አያያዝ ለአስተዳዳሪዎች የምስክር ወረቀት ማመልከቻ",
    "select-preapproved-program": "ፕሮግራም ይቀላቀሉ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.settings = window.locales.ar.settings.settings || {};window.locales.ar.settings.settings = {...window.locales.ar.settings.settings, ...{
    "notifications": "الإخطارات",           
    "preferences": "التفضيلات",           
    "profile": "الملف الشخصي",           
    "sign_out": "تسجيل الخروج",           
    "account": "الحساب",           
    "settings_title": "الإعدادات",           
    "mba": "MBA",           
    "team_header": "الملف الفريق",
    "candidates_header": "تفضيلات المرشحين",
    "preview_header": "معاينة ملف التعريف الخاص بك",
    "company_sub_header": "استكمال بيانات الشركة الخاصة بك",
    "team_sub_header": "أخبر المرشحين أكثر قليلا عن الفريق الذي ظائف شاغرة ل",
    "you_sub_header": "إكمال الخاص توظيف مدير الملف الشخصي",
    "candidates_sub_header": "هذا وسوف تساعدنا على توفير أفضل المرشحين لك",
    "preview_sub_header": "سوف مرشحين يرون هذا عندما تريد ملفاتهم الشخصية",
    "mba_documentation": "توثيق",
    "application_status": "حالة التطبيق",
    "deferred_application_status_x": "مؤجل لـ {{programTitle}}",
    "congratulations_you_are_in": "تهانينا ، أنت في!",
    "you_are_now_registered": "أنت الآن مسجل!",
    "class_begins_on_x": "تبدأ الفئة في {{startDate}}",
    "network": "شبكة الاتصال",
    "registration_opens_on": "يفتح التسجيل على <strong>{{registrationOpen}}</strong>.",
    "billing": "الفواتير",
    "transcripts": "النصوص",
    "documents": "مستندات",
    "my-profile": "الملف الشخصي",
    "application": "تنطبق على {{brandName}}",
    "you_have_secured_enrollment_in_x": "لقد قمت بتأمين <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> فئة <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> . <br class=\"hidden-xs\"> إذا كانت لديك أي أسئلة ، فيرجى مراسلتنا عبر البريد الإلكتروني على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "you_have_secured_enrollment_in_x_no_guide": "لقد قمت بتأمين {{cohortTitle}} فئة {{cohortTitle}} . <br> إذا كانت لديك أي أسئلة ، فيرجى مراسلتنا عبر البريد الإلكتروني على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "graduation_requirements_not_met": "متطلبات التخرج لم يتم الوفاء بها",
    "your_account_is_locked": "حسابك مغلق",
    "please_contact_the_bursar": "تظهر سجلاتنا أن حسابك يفتقد دفعة واحدة أو أكثر من المدفوعات المطلوبة. يرجى الاتصال بـ Bursar عبر البريد الإلكتروني على <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> لحل هذه المشكلة.",
    "registration_incomplete": "تسجيلك غير مكتمل.",
    "follow_steps_to_secure": "الرجاء اتباع الخطوات أدناه لتأمين مكانك في {{cohortTitle}} .",
    "faq": "الأسئلة الشائعة",           
    "program_status": "حالة البرنامج",
    "feedback": "إعطاء ردود الفعل",
    "switch_program": "برنامج التبديل",
    "apply_to_a_program": "تقدم إلى البرنامج",
    "now_secure_place_in_x": "انضم الآن إلى زملائك في الفصل عن طريق تأمين مكانك في فئة <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> .",
    "now_secure_place_in_x_no_guide": "انضم الآن إلى زملائك في الفصل عن طريق تأمين مكانك في فئة {{cohortTitle}} .",
    "application_deadline_reminder": "الموعد النهائي القادم للتقديم هو {{deadline}} .",
    "register_or_review_exec_ed": "سجل الآن في {{cohortTitle}} .<br /> أو قم بمراجعة جميع <a ng-click=\"openCongratsModal()\">شهادات التعليم التنفيذي</a> المؤهلة لديك.",
    "nominations": "الترشيحات",
    "menu_title": "قائمة",
    "grades": "درجات",
    "select-program": "تنطبق على البرنامج",
    "application_program_type_mba_emba": "تطبيق ماجستير إدارة الأعمال/EMBA",
    "application_program_type_msba": "تطبيق مسبا",
    "application_program_type_msse": "تطبيق MSSE",
    "application_program_type_ai_for_technical_leaders_cert": "الذكاء الاصطناعي لتطبيق شهادة القادة التقنيين",
    "application_program_type_bus_analytics_leaders_cert": "تحليلات الأعمال لتطبيق شهادة القادة",
    "application_program_type_cmo_cert": "طلب شهادة برنامج CMO",
    "application_program_type_cto_cert": "تطبيق شهادة برنامج CTO",
    "application_program_type_data_science_foundations_cert": "طلب شهادة أساسيات علوم البيانات",
    "application_program_type_executive_cert": "طلب شهادة البرنامج التنفيذي",
    "application_program_type_founders_cert": "طلب شهادة برنامج المؤسسين",
    "application_program_type_learn_code_gpt_cert": "تعلم البرمجة باستخدام تطبيق شهادة ChatGPT",
    "application_program_type_tyb_ai_cert": "قم بتحويل عملك باستخدام تطبيق شهادة AI وChatGPT",
    "application_program_type_tyb_blockchain_cert": "قم بتحويل عملك باستخدام تطبيق شهادة Blockchain",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">برنامج التغيير</a>",
    "application_program_type_prototype_gpt_cert": "الذكاء الاصطناعي لبناء تطبيق شهادة البرمجيات",
    "edit_application": "تحرير التطبيق",
    "affordable_tuition_modal": "بفضل ابتكارات التكنولوجيا والتمويل، تعد الرسوم الدراسية جزءًا صغيرًا من الدرجات التقليدية التي تبلغ قيمتها {{standardMonthlyPayment}} دولارًا أمريكيًا فقط شهريًا على مدار البرنامج.",
    "application_program_type_fin_for_managers_cert": "طلب الحصول على شهادة في المالية والمحاسبة للمديرين",
    "select-preapproved-program": "انضم إلى البرنامج"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.settings = window.locales.en.settings.settings || {};window.locales.en.settings.settings = {...window.locales.en.settings.settings, ...{
    "notifications": "Notifications",
    "preferences": "Preferences",
    "network": "Network",
    "profile": "Profile",
    "sign_out": "Sign Out",
    "faq": "FAQ",
    "feedback": "Give Feedback",
    "switch_program": "Switch Program",
    "billing": "Billing",
    "nominations": "Nominations",
    "account": "Account",
    "transcripts": "Transcripts",
    "documents": "Documents",
    "settings_title": "SETTINGS",
    "menu_title": "MENU",
    "my-profile": "Profile",
    "grades": "Grades",
    "select-program": "Apply to a Program",
    "select-preapproved-program": "Join a Program",
    "application_program_type_mba_emba": "MBA/EMBA Application",
    "application_program_type_msba": "MSBA Application",
    "application_program_type_msse": "MSSE Application",
    "application_program_type_ai_for_technical_leaders_cert": "AI for Technical Leaders Certificate Application",
    "application_program_type_bus_analytics_leaders_cert": "Business Analytics for Leaders Certificate Application",
    "application_program_type_cmo_cert": "CMO Program Certificate Application",
    "application_program_type_cto_cert": "CTO Program Certificate Application",
    "application_program_type_data_science_foundations_cert": "Data Science Foundations Certificate Application",
    "application_program_type_executive_cert": "Executive Program Certificate Application",
    "application_program_type_fin_for_managers_cert": "Finance & Accounting for Managers Certificate Application",
    "application_program_type_founders_cert": "Founders Program Certificate Application",
    "application_program_type_learn_code_gpt_cert": "Learn to Code Using ChatGPT Certificate Application",
    "application_program_type_prototype_gpt_cert": "AI for Building Software Certificate Application",
    "application_program_type_tyb_ai_cert": "Transform Your Business with AI and ChatGPT Certificate Application",
    "application_program_type_tyb_blockchain_cert": "Transform Your Business with Blockchain Certificate Application",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">Change Program</a>",

    "application_status": "Application Status",
    "program_status": "Program Status",
    "apply_to_a_program": "Apply to a Program",
    "edit_application": "Edit Application",
    "deferred_application_status_x": "Deferred for {{programTitle}}",
    "congratulations_you_are_in": "Congratulations, you're in!",
    "you_are_now_registered": "You are now registered!",
    "now_secure_place_in_x": "Now join your classmates by securing your place in the <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> class.",
    "now_secure_place_in_x_no_guide": "Now join your classmates by securing your place in the {{cohortTitle}} class.",
    "register_or_review_exec_ed": "Register now for {{cohortTitle}}.<br /> Or review all your eligible <a ng-click=\"openCongratsModal()\">Executive Education certificates</a>.",
    "you_have_secured_enrollment_in_x": "You have secured your enrollment in the <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> class.<br class=\"hidden-xs\"> If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "you_have_secured_enrollment_in_x_no_guide": "You have secured your enrollment in the {{cohortTitle}} class.<br>If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "registration_opens_on": "Registration opens on <strong>{{registrationOpen}}</strong>.",
    "class_begins_on_x": "Class begins on {{startDate}}",
    "graduation_requirements_not_met": "Graduation requirements not met",
    "registration_incomplete": "Your registration is incomplete.",
    "follow_steps_to_secure": "Please follow the steps below to secure your place in the {{cohortTitle}}.",
    "application_deadline_reminder": "The upcoming deadline to apply is {{deadline}}.",

    "mba": "MBA",
    "application": "Apply to {{brandName}}",
    "mba_documentation": "Documentation",

    "team_header": "Team Profile",
    "candidates_header": "Candidates Preferences",
    "preview_header": "Preview Your Profile",

    "company_sub_header": "Complete your company profile",
    "team_sub_header": "Tell candidates a little more about the team you're hiring for",
    "you_sub_header": "Complete your hiring manager profile",
    "candidates_sub_header": "This will help us deliver better candidates to you",
    "preview_sub_header": "Candidates will see this when you like their profiles",

    "your_account_is_locked": "Your Account is Locked",
    "please_contact_the_bursar": "Our records show your account is missing one or more required payments. Please contact the Bursar by email at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> to resolve this issue.",
    "affordable_tuition_modal": "Thanks to technology and funding innovations, tuition is a fraction of traditional degrees at just ${{standardMonthlyPayment}} USD per month over the course of the program."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.settings = window.locales.es.settings.settings || {};window.locales.es.settings.settings = {...window.locales.es.settings.settings, ...{
    "notifications": "Notificaciones",           
    "preferences": "Preferencias",           
    "profile": "Perfil",           
    "sign_out": "Desconectarse",           
    "settings_title": "AJUSTES",           
    "account": "Cuenta",           
    "mba": "MBA",           
    "team_header": "Perfil del equipo",
    "candidates_header": "Preferencias de los candidatos",
    "preview_header": "Una vista previa de su perfil",
    "company_sub_header": "Complete su perfil de la empresa",
    "team_sub_header": "Dile a los candidatos un poco más sobre el equipo que está contratando para",
    "you_sub_header": "Complete su perfil de director de recursos humanos",
    "candidates_sub_header": "Esto nos ayudará a ofrecer mejores candidatos para usted",
    "preview_sub_header": "Los candidatos a ver esto cuando te gusta sus perfiles",
    "mba_documentation": "Documentación",
    "application_status": "Estado de la aplicación",
    "deferred_application_status_x": "Deferido para {{programTitle}}",
    "congratulations_you_are_in": "Felicidades, estás adentro!",
    "you_are_now_registered": "¡Ahora estás registrado!",
    "class_begins_on_x": "La clase comienza el {{startDate}}",
    "network": "Red",
    "registration_opens_on": "El registro se abre el <strong>{{registrationOpen}}</strong> .",
    "billing": "Facturación",
    "transcripts": "Transcripciones",
    "documents": "Documentos",
    "my-profile": "Perfil",
    "application": "Aplicar a {{brandName}}",
    "you_have_secured_enrollment_in_x": "Ha asegurado su inscripción en la clase <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> . <br class=\"hidden-xs\"> Si tiene alguna pregunta, envíenos un correo electrónico a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "you_have_secured_enrollment_in_x_no_guide": "Ha asegurado su inscripción en la clase {{cohortTitle}} . <br> Si tiene alguna pregunta, envíenos un correo electrónico a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "graduation_requirements_not_met": "Requisitos de graduación no cumplidos",
    "your_account_is_locked": "Tu cuenta esta bloqueada",
    "please_contact_the_bursar": "Nuestros registros muestran que a su cuenta le faltan uno o más pagos obligatorios. Comuníquese con el Tesorero por correo electrónico a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> para resolver este problema.",
    "registration_incomplete": "Su registro está incompleto.",
    "follow_steps_to_secure": "Siga los pasos a continuación para asegurar su lugar en el {{cohortTitle}} .",
    "faq": "Preguntas frecuentes",           
    "program_status": "Estado del programa",
    "feedback": "Dar opinion",
    "switch_program": "Cambiar programa",
    "apply_to_a_program": "Aplicar a un programa",
    "now_secure_place_in_x": "Ahora únase a sus compañeros de clase asegurando su lugar en la clase <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> .",
    "now_secure_place_in_x_no_guide": "Ahora únase a sus compañeros de clase asegurando su lugar en la clase {{cohortTitle}} .",
    "application_deadline_reminder": "La próxima fecha límite para aplicar es {{deadline}} .",
    "register_or_review_exec_ed": "Regístrese ahora para {{cohortTitle}} .<br /> O revise todos sus <a ng-click=\"openCongratsModal()\">certificados de educación ejecutiva</a> elegibles.",
    "nominations": "Nominaciones",
    "menu_title": "MENÚ",
    "grades": "Los grados",
    "select-program": "Aplicar a un programa",
    "application_program_type_mba_emba": "Solicitud de MBA/EMBA",
    "application_program_type_msba": "Aplicación MSBA",
    "application_program_type_msse": "Solicitud MSSE",
    "application_program_type_ai_for_technical_leaders_cert": "Solicitud de certificado de IA para líderes técnicos",
    "application_program_type_bus_analytics_leaders_cert": "Solicitud de certificado de análisis empresarial para líderes",
    "application_program_type_cmo_cert": "Solicitud de certificado del programa CMO",
    "application_program_type_cto_cert": "Solicitud de certificado del programa CTO",
    "application_program_type_data_science_foundations_cert": "Solicitud de certificado de fundamentos de ciencia de datos",
    "application_program_type_executive_cert": "Solicitud de certificado del programa ejecutivo",
    "application_program_type_founders_cert": "Solicitud de certificado del programa de fundadores",
    "application_program_type_learn_code_gpt_cert": "Aprenda a codificar utilizando la aplicación de certificado ChatGPT",
    "application_program_type_tyb_ai_cert": "Transforme su negocio con IA y la solicitud de certificado ChatGPT",
    "application_program_type_tyb_blockchain_cert": "Transforme su negocio con la solicitud de certificado Blockchain",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">Cambiar programa</a>",
    "application_program_type_prototype_gpt_cert": "Solicitud de certificado de IA para software de construcción",
    "edit_application": "Editar aplicación",
    "affordable_tuition_modal": "Gracias a la tecnología y las innovaciones financieras, la matrícula es una fracción de la de los títulos tradicionales y cuesta solo $ {{standardMonthlyPayment}} USD por mes durante el transcurso del programa.",
    "application_program_type_fin_for_managers_cert": "Solicitud de certificación en finanzas y contabilidad para gerentes",
    "select-preapproved-program": "Únase a un programa"
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.settings = window.locales.it.settings.settings || {};window.locales.it.settings.settings = {...window.locales.it.settings.settings, ...{
    "notifications": "Notifiche",           
    "preferences": "Preferenze",           
    "profile": "Profilo",           
    "sign_out": "Esci",           
    "account": "Account",           
    "settings_title": "IMPOSTAZIONI",           
    "application_status": "Stato Candidatura",           
    "mba": "MBA",           
    "mba_documentation": "Documentazione",           
    "team_header": "Profilo Team",           
    "candidates_header": "Preferenze Candidati",           
    "preview_header": "Anteprima del tuo Profilo",           
    "company_sub_header": "Completa il tuo profilo aziendale",           
    "team_sub_header": "Racconta ai candidati qualcosa in più sul team per il quale stai reclutando",           
    "you_sub_header": "Completa il tuo profilo da manager di reclutamento",           
    "candidates_sub_header": "Questo ci aiuterà a proporti candidati più adatti a te",           
    "preview_sub_header": "Questo è ciò che vedranno i candidati quando approverai il loro profilo",           
    "deferred_application_status_x": "Rimandato per {{programTitle}}",
    "congratulations_you_are_in": "Congratulazioni, sei in!",
    "you_are_now_registered": "Ora sei registrato!",
    "class_begins_on_x": "La lezione inizia il {{startDate}}",
    "network": "Rete",
    "registration_opens_on": "La registrazione si apre su <strong>{{registrationOpen}}</strong> .",
    "billing": "Fatturazione",
    "transcripts": "trascrizioni",
    "documents": "Documenti",
    "my-profile": "Profilo",
    "application": "Fai domanda per {{brandName}}",
    "you_have_secured_enrollment_in_x": "Hai assicurato la tua iscrizione alla classe <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> . <br class=\"hidden-xs\"> In caso di domande, inviare un'e-mail a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "you_have_secured_enrollment_in_x_no_guide": "Hai assicurato la tua iscrizione alla classe {{cohortTitle}} . <br> In caso di domande, inviare un'e-mail a <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> .",
    "graduation_requirements_not_met": "Requisiti di laurea non soddisfatti",
    "your_account_is_locked": "Il tuo account è bloccato",
    "please_contact_the_bursar": "I nostri dati mostrano che nel tuo account mancano uno o più pagamenti richiesti. Contatta l'economo tramite e-mail all'indirizzo <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> per risolvere questo problema.",
    "registration_incomplete": "La tua registrazione è incompleta.",
    "follow_steps_to_secure": "Segui i passaggi seguenti per assicurarti il tuo posto nel {{cohortTitle}} .",
    "faq": "FAQ",           
    "program_status": "Stato del programma",
    "feedback": "Dare un feedback",
    "switch_program": "Cambia programma",
    "apply_to_a_program": "Applica a un programma",
    "now_secure_place_in_x": "Ora unisciti ai tuoi compagni di classe assicurandoti un posto nella classe <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> .",
    "now_secure_place_in_x_no_guide": "Ora unisciti ai tuoi compagni di classe assicurandoti un posto nella classe {{cohortTitle}} .",
    "application_deadline_reminder": "La prossima scadenza per presentare domanda è {{deadline}} .",
    "register_or_review_exec_ed": "Registrati ora per {{cohortTitle}} .<br /> Oppure esamina tutti <a ng-click=\"openCongratsModal()\">i certificati di formazione executive</a> idonei.",
    "nominations": "Nomine",
    "menu_title": "MENÙ",
    "grades": "Gradi",
    "select-program": "Candidati a un programma",
    "application_program_type_mba_emba": "Applicazione MBA/EMBA",
    "application_program_type_msba": "Applicazione MSBA",
    "application_program_type_msse": "Applicazione MSSE",
    "application_program_type_ai_for_technical_leaders_cert": "Richiesta di certificato AI for Technical Leaders",
    "application_program_type_bus_analytics_leaders_cert": "Applicazione per il certificato Business Analytics for Leaders",
    "application_program_type_cmo_cert": "Richiesta di certificato del programma CMO",
    "application_program_type_cto_cert": "Richiesta di certificato del programma CTO",
    "application_program_type_data_science_foundations_cert": "Richiesta di certificato per fondazioni di scienza dei dati",
    "application_program_type_executive_cert": "Richiesta di certificato del programma esecutivo",
    "application_program_type_founders_cert": "Richiesta di certificato del programma Fondatori",
    "application_program_type_learn_code_gpt_cert": "Impara a programmare utilizzando l'applicazione di certificato ChatGPT",
    "application_program_type_tyb_ai_cert": "Trasforma il tuo business con la richiesta di certificato AI e ChatGPT",
    "application_program_type_tyb_blockchain_cert": "Trasforma il tuo business con la richiesta di certificato Blockchain",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">Cambia programma</a>",
    "application_program_type_prototype_gpt_cert": "Applicazione di certificato software AI for Building",
    "edit_application": "Modifica applicazione",
    "affordable_tuition_modal": "Grazie alle innovazioni tecnologiche e di finanziamento, le tasse scolastiche sono una frazione rispetto ai titoli di studio tradizionali a soli $ {{standardMonthlyPayment}} USD al mese nel corso del programma.",
    "application_program_type_fin_for_managers_cert": "Domanda di certificazione per manager in finanza e contabilità",
    "select-preapproved-program": "Partecipa a un programma"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.settings = window.locales.zh.settings.settings || {};window.locales.zh.settings.settings = {...window.locales.zh.settings.settings, ...{
    "notifications": "通知",           
    "preferences": "偏好设置",           
    "profile": "资料",           
    "sign_out": "注销",           
    "settings_title": "设置",           
    "account": "帐户",           
    "mba": "MBA",           
    "team_header": "团队资料",           
    "candidates_header": "候选人偏好",           
    "preview_header": "预览你的资料",           
    "company_sub_header": "完善你的公司资料",           
    "team_sub_header": "详细告知候选人你要招聘的团队的情况",           
    "you_sub_header": "完善你的招聘经理的资料",           
    "candidates_sub_header": "这将有助于我们为你提供更好的候选人",           
    "preview_sub_header": "如果你点赞候选人的资料，他们会看到",           
    "mba_documentation": "证明文件",           
    "application_status": "申请状态",           
    "deferred_application_status_x": "{{programTitle}}延期",           
    "congratulations_you_are_in": "恭喜你已成功加入！",           
    "you_are_now_registered": "你已注册成功！",           
    "class_begins_on_x": "课程将于{{startDate}}开始。",           
    "network": "网络",           
    "registration_opens_on": "注册将于<strong>{{registrationOpen}}</strong>中开启。",           
    "billing": "计费",           
    "transcripts": "成绩单",           
    "documents": "文件",           
    "my-profile": "简介",           
    "application": "申请{{brandName}}",           
    "you_have_secured_enrollment_in_x": "你已成功注册了<a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a>班级。<br class=\"hidden-xs\">如有任何疑问，请发送邮件至<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>。",           
    "you_have_secured_enrollment_in_x_no_guide": "你已成功注册了{{cohortTitle}}班级。<br>如有任何疑问，请发送邮件至<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>。",           
    "graduation_requirements_not_met": "未达到毕业要求",           
    "your_account_is_locked": "你的账号已被锁定",           
    "please_contact_the_bursar": "我们的记录显示您的账户缺少一笔或多笔需要支付的款项。请发送电子邮件至 <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>联系财务处解决此问题。",           
    "registration_incomplete": "您的注册不完整。",
    "follow_steps_to_secure": "请按照以下步骤确保您在{{cohortTitle}} 。",
    "faq": "FAQ",           
    "program_status": "计划状态",
    "feedback": "给予反馈",
    "switch_program": "切换程序",
    "apply_to_a_program": "适用于程序",
    "now_secure_place_in_x": "现在加入您的同学，确保您在<a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a>班级中的位置。",
    "now_secure_place_in_x_no_guide": "现在加入您的同学，确保您在{{cohortTitle}}班级中的位置。",
    "application_deadline_reminder": "即将到来的申请截止日期是{{deadline}} 。",
    "register_or_review_exec_ed": "立即注册{{cohortTitle}} 。<br />或者查看您所有符合资格的<a ng-click=\"openCongratsModal()\">高管教育证书</a>。",
    "nominations": "提名",
    "menu_title": "菜单",
    "grades": "成绩",
    "select-program": "申请项目",
    "application_program_type_mba_emba": "MBA/EMBA 申请",
    "application_program_type_msba": "MSBA 申请",
    "application_program_type_msse": "MSSE 应用程序",
    "application_program_type_ai_for_technical_leaders_cert": "人工智能技术领袖证书申请",
    "application_program_type_bus_analytics_leaders_cert": "领导者商业分析证书申请",
    "application_program_type_cmo_cert": "CMO 项目证书申请",
    "application_program_type_cto_cert": "CTO 项目证书申请",
    "application_program_type_data_science_foundations_cert": "数据科学基础证书申请",
    "application_program_type_executive_cert": "执行计划证书申请",
    "application_program_type_founders_cert": "创始人计划证书申请",
    "application_program_type_learn_code_gpt_cert": "使用 ChatGPT 证书应用程序学习编码",
    "application_program_type_tyb_ai_cert": "利用人工智能和聊天改变您的业务GPT 证书申请",
    "application_program_type_tyb_blockchain_cert": "利用区块链证书应用程序改变您的业务",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">更改程序</a>",
    "application_program_type_prototype_gpt_cert": "人工智能建筑软件证书申请",
    "edit_application": "編輯應用",
    "affordable_tuition_modal": "得益于技术和资金创新，学费只是传统学位的一小部分，在整个课程期间每月仅为 $ {{standardMonthlyPayment}}美元。",
    "application_program_type_fin_for_managers_cert": "经理财务会计证书申请",
    "select-preapproved-program": "加入计划"
}
};