import 'Lessons/angularModule';
import angularDirectiveToReact from 'Angular2reactHelper';

// These are defined in a separate file so they can be mocked out in storybook
export const TopMessageV1 = angularDirectiveToReact('FrontRoyal.Lessons', 'topMessage', {});
export const BookmarkedCompletedCoursesv1 = angularDirectiveToReact(
    'FrontRoyal.Lessons',
    'bookmarkedCompletedCourses',
    {},
);
