// Since this is used in gatsby, we rely on the js libary instead of the react library
import { type StatsigClient } from '@statsig/js-client';

class StatsigClientProvider {
    client: StatsigClient | null = null;

    require() {
        if (!this.client) {
            throw new Error('Statsig client is not set');
        }
        return this.client;
    }
}

const statsigClientProvider = new StatsigClientProvider();

export { statsigClientProvider };
