window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.program_achievement_graphic = window.locales.am.program_achievement_graphic || {};window.locales.am.program_achievement_graphic.program_achievement_graphic = window.locales.am.program_achievement_graphic.program_achievement_graphic || {};window.locales.am.program_achievement_graphic.program_achievement_graphic = {...window.locales.am.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "እንኳን ደስ ያለዎት",           
    "you_have": "እርስዎ",           
    "class_of": "የክብር",           
    "graduated": "ድህረ ምረቃ",           
    "with_honors": "ክፍል አለዎት",           
    "awarded_degree": "እና የሚከተለውን ዲግሪ ተሰጥትዎታል",           
    "select_a_design": "አንድ ንድፍ ይምረጡ",           
    "download_and_share": "<br />ን ያውርዱ እና ያጋሩ",           
    "downloading": "በማውረድ ላይ...",           
    "you_have_been_accepted_into_the": "ውስጥ ተቀባይነት አግኝተዋል",
    "completed": "ተጠናቀቀ",
    "exec_ed_cert": "የሥራ አስፈፃሚ ትምህርት የምስክር ወረቀት"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.program_achievement_graphic = window.locales.ar.program_achievement_graphic || {};window.locales.ar.program_achievement_graphic.program_achievement_graphic = window.locales.ar.program_achievement_graphic.program_achievement_graphic || {};window.locales.ar.program_achievement_graphic.program_achievement_graphic = {...window.locales.ar.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "تهانينا",
    "you_have": "لديك",
    "class_of": "فئة من",
    "graduated": "تخرج",
    "with_honors": "مع مرتبة الشرف",
    "awarded_degree": "ويحصلون على درجة",
    "select_a_design": "اختر تصميمًا",
    "download_and_share": "تحميل<br /> و شارك",
    "downloading": "جارى التحميل...",
    "you_have_been_accepted_into_the": "لقد تم قبولك في",
    "completed": "مكتمل",
    "exec_ed_cert": "شهادة التعليم التنفيذي"
}
};window.locales.en = window.locales.en || {};window.locales.en.program_achievement_graphic = window.locales.en.program_achievement_graphic || {};window.locales.en.program_achievement_graphic.program_achievement_graphic = window.locales.en.program_achievement_graphic.program_achievement_graphic || {};window.locales.en.program_achievement_graphic.program_achievement_graphic = {...window.locales.en.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "Congratulations",
    "you_have": "You have",
    "class_of": "Class of",
    "graduated": "Graduated",
    "completed": "Completed",
    "with_honors": "With honors",
    "awarded_degree": "and are awarded the degree of",
    "exec_ed_cert": "the Executive Education certificate",
    "select_a_design": "Select a design",
    "download_and_share": "Download<br />and Share",
    "downloading": "Downloading...",
    "you_have_been_accepted_into_the": "You have been accepted into the"
}
};window.locales.es = window.locales.es || {};window.locales.es.program_achievement_graphic = window.locales.es.program_achievement_graphic || {};window.locales.es.program_achievement_graphic.program_achievement_graphic = window.locales.es.program_achievement_graphic.program_achievement_graphic || {};window.locales.es.program_achievement_graphic.program_achievement_graphic = {...window.locales.es.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "Felicidades",
    "you_have": "Tienes",
    "class_of": "Clase de",
    "graduated": "Graduado",
    "with_honors": "Con honores",
    "awarded_degree": "y se les otorga el grado de",
    "select_a_design": "Seleccione un diseño",
    "download_and_share": "Descargar<br /> y compartir",
    "downloading": "Descargando...",
    "you_have_been_accepted_into_the": "Has sido aceptado en el",
    "completed": "Terminado",
    "exec_ed_cert": "el certificado de educación ejecutiva"
}
};window.locales.it = window.locales.it || {};window.locales.it.program_achievement_graphic = window.locales.it.program_achievement_graphic || {};window.locales.it.program_achievement_graphic.program_achievement_graphic = window.locales.it.program_achievement_graphic.program_achievement_graphic || {};window.locales.it.program_achievement_graphic.program_achievement_graphic = {...window.locales.it.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "Congratulazioni",
    "you_have": "Hai",
    "class_of": "Classe di",
    "graduated": "Laureato",
    "with_honors": "Con onore",
    "awarded_degree": "e ottengono il grado di",
    "select_a_design": "Seleziona un disegno",
    "download_and_share": "Scaricamento<br /> e condividi",
    "downloading": "Download...",
    "you_have_been_accepted_into_the": "Sei stato accettato nel",
    "completed": "Completato",
    "exec_ed_cert": "il certificato di formazione esecutiva"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.program_achievement_graphic = window.locales.zh.program_achievement_graphic || {};window.locales.zh.program_achievement_graphic.program_achievement_graphic = window.locales.zh.program_achievement_graphic.program_achievement_graphic || {};window.locales.zh.program_achievement_graphic.program_achievement_graphic = {...window.locales.zh.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "恭喜你！",           
    "you_have": "你已",           
    "class_of": "班级",           
    "graduated": "优异的成绩",           
    "with_honors": "毕业",           
    "awarded_degree": "并被授予学位",           
    "select_a_design": "选择一项设计",           
    "download_and_share": "下载<br />并分享",           
    "downloading": "下载中...",           
    "you_have_been_accepted_into_the": "你已经被录取了",
    "completed": "完全的",
    "exec_ed_cert": "高管教育证书"
}
};