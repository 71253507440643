window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.application_status = window.locales.am.settings.application_status || {};window.locales.am.settings.application_status = {...window.locales.am.settings.application_status, ...{
    "title": "{{programTitle}} ማመልከቻ",           
    "pending_primary_message_rounds": "ለ {{brandName}} ስላመለከቱ እናመሰግናለን። ማመልከቻዎች በአሁኑ የማስገቢያ ኡደት መጨረሻ ላይ ይገመገማሉ። {{applicationDeadlineWithDecision}}",           
    "pending_primary_message_rolling": "ለ {{brandName}} ስላመለከቱ እናመሰግናለን! ማመልከቻዎ በቅርቡ ይገመገማል።",           
    "pending_secondary_message_degree_program": "እስከዛው፣ የ <strong>{{foundationsPlaylistTitle}}</strong> ኮርሶችን ለመጀመር የዋናው ገጽ ትርን ይጎብኙ። የእርስዎን ማመልከቻ ስንገመግም፣ በእነርሱ ላይ ያለ የእርስዎ መሻሻል ከግምት ውስጥ ይገባል።",           
    "pending_secondary_message_other": "እስከዛው፣ በመግቢያው <strong>{{foundationsPlaylistTitle}}</strong> ክምችት ውስጥ በዋናው ገጽ ማያ ገጽ ላይ የሚገኙትን ነጻ ኮርሶች ማሰስ ይችላሉ።",           
    "deadline_message_network_only": "እኛ የምንገመግመው በቅደም ተከተል ሲሆን አብዛኛውን ጊዜ በጥቂት ቀናት ውስጥ ወደ አመልካቾች እንመለሳለን።",           
    "deadline_message_rolling_basis": "ደረጃ በደረጃ መሰረት ላይ ሆነን እንገመግማለን፣ እና ብዙዉን ጊዜ ከ 5-10 ቀናት ውስጥ ለአመልካቾች ምላሽ እንሰጣለን።",           
    "edit_application": "መተግበሪያን አርትዕ",           
    "return_to_home": "ወደ ዋናው ገጽ ይመለሱ",           
    "reapply": "ለ {{brandName}} ደግመው ያመልክቱ!",           
    "rejected_primary_message_career_network_only": "በ Career Network. ውስጥ የእርስዎን መገለጫ ለማስተናገድ ባለመቻላችን እናዝናለን። ጥራት ያላቸው እድሎችን ለእርስዎ ማቅረብ በቻልንበት ፍጥነት እናገኝዎታለን።<br><br> እስከዛው፣ መገለጫዎ ከተቀየረ ዳግመኛ ለማመልከት፣ ወይም ከእኛ የ MBA ፕሮግራሞች አንዱ ላይ ለማመልከት ከስር ያለውን አዝራር መጠቀም ይችላሉ።",           
    "rejected_primary_message_certificates": "ይቅርታ፣ የእርስዎ ማመልከቻ ለ{{cohortTitle}} ፕሮግራም ተቀባይነትን ሳያገኝ በመቅረቱ እናዝናለን። ከሌሎቹ ፕሮግራሞች በአንዱ ዳግም ለማመልከት፣ ወይም ለሰርተፍኬት ፕሮግራሙ መገለጫዎን በመገምገም ዳግመኛ እንዲሞክሩ ለማድረግ ከስር ያለውን አዝራር መጠቀም ይችላሉ።",           
    "rejected_primary_message_certificates_enable_quantic": "ይቅርታ፣ የእርስዎ ማመልከቻ ለ{{cohortTitle}} ፕሮግራም ተቀባይነትን ሳያገኝ በመቅረቱ እናዝናለን። ከሌሎቹ ፕሮግራሞች በአንዱ ዳግም ለማመልከት ከስር ያለውን አዝራር መጠቀም ይችላሉ።",           
    "rejected_primary_message_reapply_to_next": "ቀጣይ ክፍል በቅርቡ ይጀመራል! ለአሁኑ ክፍል ተቀባይነትን ሳያገኙ ቢቀሩም፣ ለሚቀጥለው ዳግም ለማመልከት እድሉን ይጠቀሙ።{{reapplyAfterDeadline}}",           
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "ቀጣይ ክፍል በቅርቡ ይጀመራል! ምንም እንኳን ለአሁኑ ክፍል ከእኛ ጋር ለመቅላቀል ሳይችሉ ቢቀሩም፣ ለሚቀጥለው ዳግም ለማመልከት እድሉን ይጠቀሙ። {{reapplyAfterDeadline}}",           
    "expelled_primary_message": "ቀጣይ ክፍል በቅርቡ ይጀመራል! ምንም እንኳን ለአሁኑ ክፍል ከእኛ ጋር ለመቅላቀል ቢችሉyour.email@address.comም፣ ለሚቀጥለው ዳግም ለማመልከት እድሉን ይጠቀሙ። {{reapplyAfterDeadline}}",           
    "accepted_primary_message_default": "{{acceptedPrimaryMessageDefaultBrandName}} {{programTitle}} ፕሮግራም ተቀባይነት ስላገኘን እንኳን ደስ አለዎት!",
    "accepted_primary_message_career_network_only": "ወደ {{brandName}} Career Network ተቀባይነት በማግኘትዎ እንኳን ደስ አለዎት!",           
    "application_deadline_single_round": "ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ።",           
    "application_deadline_multiple_rounds": "የዙር {{roundIndex}}ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ።",           
    "application_deadline_multiple_rounds_final": "የመጨረሻ የዙር ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ።",           
    "application_deadline_single_round_with_decision_date": "ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "application_deadline_multiple_rounds_with_decision_date": "የዙር {{roundIndex}}ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "application_deadline_multiple_rounds_final_with_decision_date": "የመጨረሻ የዙር ማመልከቻዎች <strong>{{applicationDeadline}}</strong> ይዘጋሉ። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "application_deadline_single_round_closed": "ማመልከቻዎች በ {{applicationDeadline}} ላይ ተዘግተዋል እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው።",           
    "application_deadline_single_round_closed_with_decision_date": "ማመልከቻዎች በ {{applicationDeadline}} ላይ ተዘግተዋል እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "application_deadline_multiple_rounds_closed": "የዙር ማመልከቻዎች በ {{roundIndex}} ላይ ተዘግተዋል{{applicationDeadline}} እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው።",           
    "application_deadline_multiple_rounds_closed_with_decision_date": "የዙር ማመልከቻዎች በ {{roundIndex}} ላይ ተዘግተዋል{{applicationDeadline}} እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "application_deadline_multiple_rounds_closed_final": "የመጨረሻ የዙር ማመልከቻዎች በ ላይ ተዘግተዋል{{applicationDeadline}} እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው።",           
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "የመጨረሻ የዙር ማመልከቻዎች በ ላይ ተዘግተዋል{{applicationDeadline}} እና በአሁኑ ጊዜ በግምገማ ላይ ናቸው። የቅበላ ውሳኔዎች በ <strong>{{decisionDate}}</strong> ላይ በኢሜይል ይደርሳሉ።",           
    "re_apply_after_single_round": "የአሁን ማመልከቻ የመጨረሻ ጊዜ {{applicationDeadline}} በኋላ ዳግም ማመልከት ይችላሉ።",           
    "re_apply_after_multiple_rounds": "ከዙር{{roundIndex}} ማመልከቻ ጊዜ {{applicationDeadline}} በኋላ ዳግም ማመልከት ይችላሉ።",           
    "deadline_passed_with_pending_application": "ለ{{brandName}} ስላመለከቱ እናመሰግናለን።{{applicationDeadlineWithDecision}}",           
    "delete_identification": "የ '{{file_name}}’ ማንነትን ለመሰረዝ እንደሚፈልጉ እርግጠኛ ነዎት?",           
    "delete_transcript": "የ '{{file_name}}’ ትራንስክሪፕትን ለመሰረዝ እንደሚፈልጉ እርግጠኛ ነዎት?",           
    "delete_english_language_proficiency_document": "የ '{{file_name}}’ የእንግሊዝኛ ቋንቋ ብቃት ሰነድን ለመሰረዝ እንደሚፈልጉ እርግጠኛ ነዎት?",           
    "verify_identity": "የ '{{email}}’ ማንነትን ለማረጋገጥ እንደሚፈልጉ እርግጠኛ ነዎት? ይህ የእነርሱን ማንነት ከእኛ ስርአት ውስጥ እንዲሰረዝ ያደርጋል።",           
    "thank_you": "እናመሰግናለን",           
    "cn_thank_you": "የእርስዎን የስራ ትስስር መተግበሪያ ስላስገቡ እናመሰግናለን። በአንድ ሳምንት አካባቢ ውስጥ ምላሽ ያገኛሉ!",           
    "share_with_friends": "ከጓደኞች ጋር ይጋሩ",           
    "cn_share_with_friends": "ለጊዜው፣ ከጓደኞችዎ እና ከስራ ባልደረቦችዎ ጋር ግላዊ የሪፈራል አገናኝዎን ያጋሩ።",           
    "failed_unfortunately": "በሚያሳዝን ሁኔታ፣ የምርቃት መስፈርቶቹን አላሟሉም ነበር። ለእርስዎ የኮርስ ቤተመጻህፍት ማግኘትን ያቆያሉ። ለበለጠ መረጃ፣ እኛን በ <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> ላይ ያግኙን።",           
    "rejected_primary_message_reapply_to_next_cant_reapply": "የዲግሪ ፕሮግራሙን ባይቀላቀሉም ከ {{reapplicationDate}} ጀምሮ እንደገና ማመልከት ይችላሉ። እስከዚያው ድረስ፣ ክፍት ኮርሶችን በነፃ ማግኘት ትችላለህ።",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "የዲግሪ ፕሮግራሙን ባይቀላቀሉም ከ {{reapplicationDate}} ጀምሮ እንደገና ማመልከት ይችላሉ። እስከዚያው ድረስ፣ ክፍት ኮርሶችን በነፃ ማግኘት ትችላለህ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.application_status = window.locales.ar.settings.application_status || {};window.locales.ar.settings.application_status = {...window.locales.ar.settings.application_status, ...{
    "deadline_message_network_only": "نحن نراجع على أساس متجدد، وعادة ما نعود إلى مقدمي الطلبات في غضون أيام قليلة.",
    "pending_secondary_message_degree_program": "لإثبات استعدادك للدرجة، أكمل الدورات في <strong>{{foundationsPlaylistTitle}}</strong> .",
    "pending_secondary_message_other": "وفي هذه الأثناء، يمكنك استكشاف دوراتنا المجانية في التركيز التمهيدي <strong>{{foundationsPlaylistTitle}}</strong> ، المتوفر على الشاشة الرئيسية.",
    "edit_application": "تحرير التطبيق",
    "return_to_home": "العودة إلى الصفحة الرئيسية",
    "application_deadline_single_round": "التطبيقات إغلاق <strong>{{applicationDeadline}}</strong>.",
    "delete_identification": "هل تريد بالتأكيد حذف التعريف ' {{file_name}} '؟",
    "delete_transcript": "هل تريد بالتأكيد حذف النص \" {{file_name}} \"؟",
    "verify_identity": "هل تريد بالتأكيد إثبات ملكية {{email}} إمايل {{email}} ؟ سيؤدي ذلك إلى حذف تعريفهم من نظامنا.",
    "application_deadline_multiple_rounds": "جولة {{roundIndex}} التطبيقات إغلاق <strong>{{applicationDeadline}}</strong> .",
    "application_deadline_single_round_with_decision_date": "التطبيقات إغلاق <strong>{{applicationDeadline}}</strong> . سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_with_decision_date": "جولة {{roundIndex}} التطبيقات إغلاق <strong>{{applicationDeadline}}</strong> . سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "application_deadline_single_round_closed": "تم إغلاق التطبيقات في {{applicationDeadline}} وهي الآن قيد المراجعة.",
    "application_deadline_single_round_closed_with_decision_date": "تم إغلاق التطبيقات في {{applicationDeadline}} وهي الآن قيد المراجعة. سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_closed": "تم إغلاق تطبيقات الجولة {{roundIndex}} على {{applicationDeadline}} وهي الآن قيد المراجعة.",
    "application_deadline_multiple_rounds_closed_with_decision_date": "تم إغلاق تطبيقات الجولة {{roundIndex}} على {{applicationDeadline}} وهي الآن قيد المراجعة. سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "expelled_primary_message": "سوف تبدأ الطبقة القادمة قريبا! على الرغم من أنك لم تتمكن من إكمال الصف الحالي، أغتنم هذه الفرصة لإعادة التقدم بطلب للحصول على الدرجة التالية. {{reapplyAfterDeadline}}",
    "re_apply_after_single_round": "سوف تكون قادرة على إعادة تطبيق بعد الموعد النهائي التطبيق الحالي من {{applicationDeadline}} .",
    "re_apply_after_multiple_rounds": "ستتمكن من إعادة التقدم بعد انتهاء مهلة {{applicationDeadline}} من {{applicationDeadline}} .",
    "deadline_message_rolling_basis": "نراجع على أساس متجدد، وعادة ما نعود إلى مقدمي الطلبات في غضون 5-10 أيام.",
    "rejected_primary_message_career_network_only": "نأسف لعدم تمكننا من استيعاب ملفك الشخصي في الشبكة الوظيفية. سنكون على اتصال بأسرع ما يمكن أن توفر لك مع فرص الجودة. <br><br> في غضون ذلك، يمكنك استخدام الزر أدناه لإعادة تطبيق إذا تغير ملفك الشخصي، أو إذا كنت ترغب في التقدم بطلب للحصول على أحد برامج ماجستير إدارة الأعمال لدينا.",
    "application_deadline_multiple_rounds_final": "تختتم تطبيقات الجولة النهائية <strong>{{applicationDeadline}}</strong> .",
    "application_deadline_multiple_rounds_final_with_decision_date": "تختتم تطبيقات الجولة النهائية <strong>{{applicationDeadline}}</strong> . سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_closed_final": "تم إغلاق تطبيقات الجولة النهائية في {{applicationDeadline}} وهي الآن قيد المراجعة.",
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "تم إغلاق تطبيقات الجولة النهائية في {{applicationDeadline}} وهي الآن قيد المراجعة. سيتم تسليم قرارات القبول عن طريق البريد الإلكتروني على <strong>{{decisionDate}}</strong> .",
    "thank_you": "شكرا",
    "cn_thank_you": "شكرا لك على تقديم تطبيق شبكة حياتك المهنية. سوف تسمع مرة أخرى في حوالي أسبوع!",
    "share_with_friends": "شارك مع الاصدقاء",
    "title": "{{programTitle}} التطبيق",
    "rejected_primary_message_certificates": "نأسف لعدم قبول طلبك في برنامج {{cohortTitle}} . يمكنك استخدام الزر أدناه لإعادة التقديم إلى أحد برامجنا الأخرى، أو مراجعة ملفك الشخصي ومحاولة التقدم مرة أخرى إلى برنامج الشهادة.",
    "rejected_primary_message_reapply_to_next": "سوف تبدأ الطبقة القادمة قريبا! على الرغم من أنك لم تقبل للفئة الحالية، أغتنم هذه الفرصة لإعادة التقدم بطلب للحصول على الدرجة التالية. {{reapplyAfterDeadline}}",
    "cn_share_with_friends": "في هذه الأثناء ، شارك رابط الإحالة الشخصي الخاص بك مع أصدقائك وزملائك.",
    "delete_english_language_proficiency_document": "هل أنت متأكد من أنك تريد حذف مستند إتقان اللغة الإنجليزية ' {{file_name}} '؟",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "سيتم بدء الفصل التالي قريبًا! على الرغم من أنك لم تتمكن من الانضمام إلينا للصف الحالي ، أغتنم هذه الفرصة لإعادة التقديم للصف التالي. {{reapplyAfterDeadline}}",
    "pending_primary_message_rounds": "شكرًا لك على التقدم إلى {{brandName}} . سيتم مراجعة الطلبات في نهاية دورة التقديم الحالية. {{applicationDeadlineWithDecision}}",
    "pending_primary_message_rolling": "شكرًا لك على التقدم إلى {{brandName}} . سيتم مراجعة طلبك قريبًا.",
    "reapply": "{{brandName}} تطبيق {{brandName}}",
    "accepted_primary_message_default": "تهانينا على قبولك في برنامج {{acceptedPrimaryMessageDefaultBrandName}} {{programTitle}} {{brandName}} !",
    "accepted_primary_message_career_network_only": "تهانينا على قبولك في شبكة التوظيف {{brandName}} !",
    "deadline_passed_with_pending_application": "شكرًا لك على التقدم إلى {{brandName}} . {{applicationDeadlineWithDecision}}",
    "rejected_primary_message_certificates_enable_quantic": "نأسف لعدم قبول طلبك في برنامج {{cohortTitle}} . يمكنك استخدام الزر أدناه لإعادة التقدم إلى أحد برامجنا الأخرى.",
    "failed_unfortunately": "لسوء الحظ ، لم تلبي متطلبات التخرج. سوف تحتفظ بالوصول إلى مكتبة الدورة التدريبية الخاصة بك. لمزيد من المعلومات ، اتصل بنا على <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "rejected_primary_message_reapply_to_next_cant_reapply": "على الرغم من أنك لن تنضم إلى برنامج الشهادة ، إلا أنه يمكنك إعادة التقديم بدءًا من {{reapplicationDate}} . في غضون ذلك ، ستحتفظ بإمكانية الوصول المجاني إلى الدورات التدريبية المفتوحة.",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "على الرغم من أنك لن تنضم إلى برنامج الشهادة ، إلا أنه يمكنك إعادة التقديم بدءًا من {{reapplicationDate}} . في غضون ذلك ، ستحتفظ بإمكانية الوصول المجاني إلى الدورات التدريبية المفتوحة."
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.application_status = window.locales.en.settings.application_status || {};window.locales.en.settings.application_status = {...window.locales.en.settings.application_status, ...{
    "title": "{{programTitle}} Application",
    "pending_primary_message_rounds": "Thank you for applying to {{brandName}}. Applications will be reviewed at the end of the current submission cycle. {{applicationDeadlineWithDecision}}",
    "pending_primary_message_rolling": "Thank you for applying to {{brandName}}. Your application will be reviewed soon.",
    "pending_secondary_message_degree_program": "In the meantime, visit the Home tab to get started on the <strong>{{foundationsPlaylistTitle}}</strong> courses. Your progress in them is considered when we evaluate your application.",
    "pending_secondary_message_other": "In the meantime, you may explore our free courses in the introductory <strong>{{foundationsPlaylistTitle}}</strong> concentration, available on the home screen.",
    "deadline_message_network_only": "We review on a rolling basis, and usually get back to applicants within a few days.",
    "deadline_message_rolling_basis": "We review on a rolling basis, and usually get back to applicants within 5-10 days.",
    "edit_application": "Edit Application",
    "return_to_home": "Return to Home",
    "reapply": "Reapply to {{brandName}}",
    "rejected_primary_message_career_network_only": "We’re sorry we couldn’t accommodate your profile in the Career Network. We’ll be in touch as soon as we can provide you with quality opportunities.<br><br>In the meantime, you can use the button below to re-apply if your profile has changed, or if you’d like to apply to one of our MBA programs.",
    "rejected_primary_message_certificates": "We're sorry your application wasn't accepted into the {{programTitle}} program. You can use the button below to re-apply to one of our other programs, or to revise your profile and try applying again to the certificate program.",
    "rejected_primary_message_certificates_enable_quantic": "We're sorry your application wasn't accepted into the {{programTitle}} program. You can use the button below to re-apply to one of our other programs.",
    "rejected_primary_message_reapply_to_next": "The next class will be starting soon! Although you weren’t accepted for the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "The next class will be starting soon! Although you weren’t able to join us for the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "expelled_primary_message": "The next class will be starting soon! Although you were unable to complete the current class, take the opportunity to reapply for the next one. {{reapplyAfterDeadline}}",
    "accepted_primary_message_default": "Congratulations on being accepted to the {{acceptedPrimaryMessageDefaultBrandName}} {{programTitle}} program!",
    "accepted_primary_message_career_network_only": "Congratulations on being accepted to the {{brandName}} Career Network!",
    "application_deadline_single_round": "Applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_multiple_rounds": "Round {{roundIndex}} applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_multiple_rounds_final": "Final round applications close <strong>{{applicationDeadline}}</strong>.",
    "application_deadline_single_round_with_decision_date": "Applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_with_decision_date": "Round {{roundIndex}} applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_final_with_decision_date": "Final round applications close <strong>{{applicationDeadline}}</strong>. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_single_round_closed": "Applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_single_round_closed_with_decision_date": "Applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_closed": "Round {{roundIndex}} applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_multiple_rounds_closed_with_decision_date": "Round {{roundIndex}} applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "application_deadline_multiple_rounds_closed_final": "Final round applications closed on {{applicationDeadline}} and are now in review.",
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "Final round applications closed on {{applicationDeadline}} and are now in review. Admissions decisions will be delivered by email on <strong>{{decisionDate}}</strong>.",
    "re_apply_after_single_round": "You will be able to re-apply after the current application deadline of {{applicationDeadline}}.",
    "re_apply_after_multiple_rounds": "You will be able to re-apply after the round {{roundIndex}} application deadline of {{applicationDeadline}}.",
    "deadline_passed_with_pending_application": "Thank you for applying to {{brandName}}. {{applicationDeadlineWithDecision}}",
    "delete_identification": "Are you sure you want to delete identification '{{file_name}}'?",
    "delete_transcript": "Are you sure you want to delete transcript '{{file_name}}'?",
    "delete_english_language_proficiency_document": "Are you sure you want to delete English language proficiency document '{{file_name}}'?",
    "verify_identity": "Are you sure you want to verify identification for {{email}}? This will delete their identification from our system.",
    "thank_you": "Thank You",
    "cn_thank_you": "Thank you for submitting your career network application. You’ll hear back in about a week!",
    "share_with_friends": "Share with Friends",
    "cn_share_with_friends": "In the meantime, share your personal referral link with your friends and colleagues.",
    "failed_unfortunately": "Unfortunately, you did not meet the graduation requirements. You will retain access to your course library. For more information, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>.",
    "rejected_primary_message_reapply_to_next_cant_reapply": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.application_status = window.locales.es.settings.application_status || {};window.locales.es.settings.application_status = {...window.locales.es.settings.application_status, ...{
    "deadline_message_network_only": "Repasamos en una base de balanceo, y por lo general volver a los solicitantes dentro de unos días.",
    "pending_secondary_message_degree_program": "Para demostrar su preparación para el grado, complete los cursos en la <strong>{{foundationsPlaylistTitle}}</strong> .",
    "pending_secondary_message_other": "Mientras tanto, puede explorar nuestros cursos gratuitos en la concentración introductoria <strong>{{foundationsPlaylistTitle}}</strong> , disponible en la pantalla de inicio.",
    "edit_application": "Editar aplicación",
    "return_to_home": "Vuelve a casa",
    "application_deadline_single_round": "Las aplicaciones cierran <strong>{{applicationDeadline}}</strong>.",
    "delete_identification": "¿Seguro que quieres eliminar la identificación ' {{file_name}} '?",
    "delete_transcript": "¿Seguro que quieres borrar la transcripción ' {{file_name}} '?",
    "verify_identity": "¿Seguro que desea verificar la identificación de {{email}} ? Esto eliminará su identificación de nuestro sistema.",
    "application_deadline_multiple_rounds": "Las {{roundIndex}} redondas {{roundIndex}} cierran <strong>{{applicationDeadline}}</strong> .",
    "application_deadline_single_round_with_decision_date": "Las aplicaciones cierran <strong>{{applicationDeadline}}</strong> . Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_with_decision_date": "Las {{roundIndex}} redondas {{roundIndex}} cierran <strong>{{applicationDeadline}}</strong> . Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "application_deadline_single_round_closed": "Las aplicaciones se cerraron en {{applicationDeadline}} y ahora están en revisión.",
    "application_deadline_single_round_closed_with_decision_date": "Las aplicaciones se cerraron en {{applicationDeadline}} y ahora están en revisión. Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_closed": "Las {{roundIndex}} Round {{roundIndex}} cerraron en {{applicationDeadline}} y ahora están en revisión.",
    "application_deadline_multiple_rounds_closed_with_decision_date": "Las {{roundIndex}} Round {{roundIndex}} cerraron en {{applicationDeadline}} y ahora están en revisión. Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "expelled_primary_message": "¡La próxima clase comenzará pronto! Aunque no pudo completar la clase actual, aproveche la oportunidad para volver a solicitar la siguiente. {{reapplyAfterDeadline}}",
    "re_apply_after_single_round": "Podrá volver a aplicar después del plazo de solicitud actual de {{applicationDeadline}} .",
    "re_apply_after_multiple_rounds": "Podrás volver a aplicar después de la {{roundIndex}} fecha límite de la aplicación de {{applicationDeadline}} .",
    "deadline_message_rolling_basis": "Repasamos sobre una base de balanceo, y por lo general volver a los solicitantes dentro de 5-10 días.",
    "application_deadline_multiple_rounds_final": "Las aplicaciones de la ronda final cierran <strong>{{applicationDeadline}}</strong> .",
    "application_deadline_multiple_rounds_final_with_decision_date": "Las aplicaciones de la ronda final cierran <strong>{{applicationDeadline}}</strong> . Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_closed_final": "Las aplicaciones de la ronda final se cerraron en {{applicationDeadline}} y ahora están en revisión.",
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "Las aplicaciones de la ronda final se cerraron en {{applicationDeadline}} y ahora están en revisión. Las decisiones de admisión se entregarán por correo electrónico en <strong>{{decisionDate}}</strong> .",
    "thank_you": "Gracias",
    "cn_thank_you": "Gracias por enviar su aplicación de red profesional. ¡Escuchará en alrededor de una semana!",
    "share_with_friends": "Compartir con amigos",
    "title": "{{programTitle}} Solicitud",
    "rejected_primary_message_career_network_only": "Lamentamos no poder acomodar su perfil en la Red profesional. Estaremos en contacto tan pronto como podamos ofrecerle oportunidades de calidad. <br><br> Mientras tanto, puede utilizar el siguiente botón para volver a presentar la solicitud si su perfil ha cambiado o si desea postularse a uno de nuestros programas de MBA.",
    "rejected_primary_message_certificates": "Lamentamos que su solicitud no se haya aceptado en el programa {{cohortTitle}} . Puede usar el botón a continuación para volver a presentar la solicitud en uno de nuestros otros programas o para revisar su perfil e intentar aplicar nuevamente al programa de certificación.",
    "rejected_primary_message_reapply_to_next": "¡La próxima clase comenzará pronto! Aunque no fuiste aceptado para la clase actual, aprovecha la oportunidad para volver a postularte para la próxima. {{reapplyAfterDeadline}}",
    "cn_share_with_friends": "Mientras tanto, comparte tu enlace de referencia personal con tus amigos y colegas.",
    "delete_english_language_proficiency_document": "¿Está seguro de que desea eliminar el documento de dominio del idioma inglés ' {{file_name}} '?",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "¡La próxima clase comenzará pronto! Aunque no pudo unirse a nosotros para la clase actual, aproveche la oportunidad para volver a solicitar la siguiente. {{reapplyAfterDeadline}}",
    "pending_primary_message_rounds": "Gracias por aplicar a {{brandName}} . Las solicitudes serán revisadas al final del ciclo de presentación actual. {{applicationDeadlineWithDecision}}",
    "pending_primary_message_rolling": "Gracias por aplicar a {{brandName}} . Su solicitud será revisada pronto.",
    "reapply": "Vuelva a aplicar a {{brandName}}",
    "accepted_primary_message_default": "¡Felicitaciones por ser aceptado en el programa {{acceptedPrimaryMessageDefaultBrandName}} {{programTitle}}!",
    "accepted_primary_message_career_network_only": "¡Felicitaciones por ser aceptado en la Red de Carreras {{brandName}} !",
    "deadline_passed_with_pending_application": "Gracias por aplicar a {{brandName}} . {{applicationDeadlineWithDecision}}",
    "rejected_primary_message_certificates_enable_quantic": "Lamentamos que su solicitud no haya sido aceptada en el programa {{cohortTitle}} . Puede usar el botón a continuación para volver a aplicar a uno de nuestros otros programas.",
    "failed_unfortunately": "Lamentablemente, no cumpliste con los requisitos de graduación. Conservará el acceso a la biblioteca de su curso. Para obtener más información, contáctenos en <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "rejected_primary_message_reapply_to_next_cant_reapply": "Aunque no se unirá al programa de grado, puede volver a presentar su solicitud a partir del {{reapplicationDate}} . Mientras tanto, mantendrá el acceso gratuito a los cursos abiertos.",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "Aunque no se unirá al programa de grado, puede volver a presentar su solicitud a partir del {{reapplicationDate}} . Mientras tanto, mantendrá el acceso gratuito a los cursos abiertos."
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.application_status = window.locales.it.settings.application_status || {};window.locales.it.settings.application_status = {...window.locales.it.settings.application_status, ...{
    "pending_secondary_message_degree_program": "Per dimostrare di essere pronto al diploma, completa i corsi nell'indirizzo <strong>{{foundationsPlaylistTitle}}</strong>.",           
    "pending_secondary_message_other": "Nel frattempo, puoi scoprire i nostri corsi gratuiti nell'indirizzo introduttivo <strong>{{foundationsPlaylistTitle}}</strong>, disponibile sulla schermata home.",           
    "deadline_message_network_only": "Gli esami avvengono a rotazione, e solitamente ricontattiamo i candidati entro qualche giorno.",           
    "deadline_message_rolling_basis": "Gli esami avvengono a rotazione, e solitamente ricontattiamo i candidati entro 5-10 giorni.",           
    "edit_application": "Modifica Candidatura",           
    "return_to_home": "Ritorna alla Home",           
    "rejected_primary_message_reapply_to_next": "La prossima classe comincerà fra poco! Anche se non sei stato ammesso a quella attuale, cogli l'opportunità per ricandidarti alla successiva. {{reapplyAfterDeadline}}",           
    "expelled_primary_message": "La prossima classe comincerà fra poco! Anche se non sei riuscito a completare quella attuale, cogli l'opportunità per ricandidarti alla successiva. {{reapplyAfterDeadline}}",           
    "application_deadline_single_round": "Le candidature chiudono <strong>{{applicationDeadline}}</strong>.",           
    "application_deadline_multiple_rounds": "Le candidature del turno {{roundIndex}} chiudono <strong>{{applicationDeadline}}</strong>.",           
    "application_deadline_single_round_with_decision_date": "Le candidature chiudono <strong>{{applicationDeadline}}</strong>. Le decisioni sull'ammissione saranno inviate via email il <strong>{{decisionDate}}</strong>.",           
    "application_deadline_multiple_rounds_with_decision_date": "Le candidature del turno {{roundIndex}} chiudono il {{applicationDeadline}} e sono adesso in fase di valutazione.",           
    "application_deadline_single_round_closed": "Le candidature si sono chiuse il {{applicationDeadline}} e sono adesso in fase di valutazione.",           
    "application_deadline_single_round_closed_with_decision_date": "Le candidature si sono chiuse il {{applicationDeadline}} e sono adesso in fase di valutazione. Le decisioni sull'ammissione saranno inviate via email il <strong>{{decisionDate}}</strong>.",           
    "application_deadline_multiple_rounds_closed": "Le candidature del turno {{roundIndex}} si sono chiuse il {{applicationDeadline}} e sono adesso in fase di valutazione.",           
    "application_deadline_multiple_rounds_closed_with_decision_date": "Le candidature del turno {{roundIndex}} si sono chiuse il {{applicationDeadline}} e sono adesso in fase di valutazione. Le decisioni sull'ammissione saranno inviate via email il <strong>{{decisionDate}}</strong>.",           
    "re_apply_after_single_round": "Potrai ricandidarti dopo l'attuale scadenza per le candidature del {{applicationDeadline}}.",           
    "re_apply_after_multiple_rounds": "Potrai ricandidarti dopo la scadenza del {{applicationDeadline}} per le candidature del turno {{roundIndex}}.",           
    "delete_identification": "Sei sicuro di voler eliminare il documento di identificazione '{{file_name}}'?",           
    "delete_transcript": "Sei sicuro di voler eliminare il certificato '{{file_name}}'?",           
    "verify_identity": "Sei sicuro di voler verificare l'identificazione per {{email}}? Questo cancellerà la sua identificazione dal nostro sistema.",           
    "application_deadline_multiple_rounds_final": "Le applicazioni finali di chiusura chiudono <strong>{{applicationDeadline}}</strong> .",
    "application_deadline_multiple_rounds_final_with_decision_date": "Le applicazioni finali di chiusura chiudono <strong>{{applicationDeadline}}</strong> . Le decisioni di ammissione saranno consegnate via email su <strong>{{decisionDate}}</strong> .",
    "application_deadline_multiple_rounds_closed_final": "Le applicazioni finali di chiusura sono state chiuse su {{applicationDeadline}} e sono in fase di revisione.",
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "Le applicazioni finali di chiusura sono state chiuse su {{applicationDeadline}} e sono in fase di revisione. Le decisioni di ammissione saranno consegnate via email su <strong>{{decisionDate}}</strong> .",
    "thank_you": "Grazie",
    "cn_thank_you": "Grazie per aver inviato la tua domanda di rete di carriera. Sentirai di nuovo in circa una settimana!",
    "share_with_friends": "Condividere con gli amici",
    "title": "{{programTitle}} Applicazione",
    "rejected_primary_message_career_network_only": "Siamo spiacenti di non poter ospitare il tuo profilo nella rete professionale. Ci metteremo in contatto non appena potremo offrirti opportunità di qualità. <br><br> Nel frattempo, puoi utilizzare il pulsante in basso per riapplicare la domanda se il tuo profilo è cambiato o se desideri candidarti a uno dei nostri programmi MBA.",
    "rejected_primary_message_certificates": "Siamo spiacenti che la tua domanda non sia stata accettata nel programma {{cohortTitle}} . Puoi usare il pulsante qui sotto per riapplicare ad uno dei nostri altri programmi, o per rivedere il tuo profilo e provare ad applicare nuovamente al programma di certificazione.",
    "cn_share_with_friends": "Nel frattempo, condividi il tuo link di riferimento personale con i tuoi amici e colleghi.",
    "delete_english_language_proficiency_document": "Sei sicuro di voler eliminare il documento di conoscenza della lingua inglese ' {{file_name}} '?",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "La prossima lezione inizierà presto! Anche se non sei stato in grado di unirti a noi per la classe attuale, cogli l'opportunità di riapplicare per il prossimo. {{reapplyAfterDeadline}}",
    "pending_primary_message_rounds": "Grazie per esserti candidato a {{brandName}} . Le domande saranno esaminate al termine dell'attuale ciclo di presentazione. {{applicationDeadlineWithDecision}}",
    "pending_primary_message_rolling": "Grazie per esserti candidato a {{brandName}} . La tua domanda verrà esaminata presto.",
    "reapply": "Riapplica a {{brandName}}",
    "accepted_primary_message_default": "Congratulazioni per essere stato accettato nel programma {{programTitle}} {{acceptedPrimaryMessageDefaultBrandName}} !",
    "accepted_primary_message_career_network_only": "Congratulazioni per essere stato accettato nella {{brandName}} Career Network!",
    "deadline_passed_with_pending_application": "Grazie per esserti candidato a {{brandName}} . {{applicationDeadlineWithDecision}}",
    "rejected_primary_message_certificates_enable_quantic": "Siamo spiacenti che la tua richiesta non sia stata accettata nel programma {{cohortTitle}} . Puoi utilizzare il pulsante qui sotto per riapplicare ad uno dei nostri altri programmi.",
    "failed_unfortunately": "Sfortunatamente, non hai soddisfatto i requisiti di laurea. Manterrai l'accesso alla tua biblioteca del corso. Per ulteriori informazioni, contattaci all'indirizzo <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a> .",
    "rejected_primary_message_reapply_to_next_cant_reapply": "Anche se non parteciperai al corso di laurea, puoi presentare nuovamente domanda a partire dal giorno {{reapplicationDate}} . Nel frattempo, manterrai libero l'accesso ai corsi aperti.",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "Anche se non parteciperai al corso di laurea, puoi presentare nuovamente domanda a partire dal giorno {{reapplicationDate}} . Nel frattempo, manterrai libero l'accesso ai corsi aperti."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.application_status = window.locales.zh.settings.application_status || {};window.locales.zh.settings.application_status = {...window.locales.zh.settings.application_status, ...{
    "deadline_message_network_only": "我们按顺序进行审核，通常几天内就会向申请人作出答复。",           
    "pending_secondary_message_degree_program": "同时，访问主页选项卡，开始学习<strong>{{foundationsPlaylistTitle}}</strong>课程。我们进行申请审核时，也会将你的进度考虑其中。",           
    "pending_secondary_message_other": "与此同时，你可以在首页的入门 <strong>{{foundationsPlaylistTitle}}</strong>合集中体验我们的免费课程。",           
    "edit_application": "编辑申请",           
    "return_to_home": "返回主页",           
    "application_deadline_single_round": "申请将于 <strong>{{applicationDeadline}}</strong>关闭。",           
    "delete_identification": "你确定要删除身份'{{file_name}}'？",           
    "delete_transcript": "你确定要删除成绩单'{{file_name}}'？",           
    "verify_identity": "你确定要验证{{email}}的身份吗？这将从我们的系统中删除其身份。",           
    "application_deadline_multiple_rounds": "第{{roundIndex}}轮申请将于<strong>{{applicationDeadline}}</strong>关闭。",           
    "application_deadline_single_round_with_decision_date": "申请将于 <strong>{{applicationDeadline}}</strong>关闭。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "application_deadline_multiple_rounds_with_decision_date": "第{{roundIndex}}轮申请将于<strong>{{applicationDeadline}}</strong>关闭。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "application_deadline_single_round_closed": "申请已于{{applicationDeadline}}关闭，现在正在审核中。",           
    "application_deadline_single_round_closed_with_decision_date": "申请已于{{applicationDeadline}}关闭，现在正在审核中。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "application_deadline_multiple_rounds_closed": "第{{roundIndex}}轮申请已于{{applicationDeadline}}关闭，现在正在审核中。",           
    "application_deadline_multiple_rounds_closed_with_decision_date": "第{{roundIndex}}轮申请已于{{applicationDeadline}}关闭，现在正在审核中。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "expelled_primary_message": "下节课马上就要开始了！即使你无法完成当前的课程，请抓住机会重新申请下一期课程。{{reapplyAfterDeadline}}",           
    "re_apply_after_single_round": "在当前申请截止日期{{applicationDeadline}}之后，你可以进行重新申请。",           
    "re_apply_after_multiple_rounds": "在整轮{{roundIndex}}的申请截止日期{{applicationDeadline}}之后，你可以重新进行申请。",           
    "deadline_message_rolling_basis": "我们按顺序进行审核，通常在5-10天内向申请人作出答复。",           
    "application_deadline_multiple_rounds_final": "最终的申请过程将于 <strong>{{applicationDeadline}}</strong>关闭。",           
    "application_deadline_multiple_rounds_final_with_decision_date": "最终的申请过程将于 <strong>{{applicationDeadline}}</strong>关闭。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "application_deadline_multiple_rounds_closed_final": "最终的完整申请过程已于{{applicationDeadline}}关闭，现在正在审核中。",           
    "application_deadline_multiple_rounds_closed_final_with_decision_date": "最终的完整申请过程已于{{applicationDeadline}}关闭，现在正在审核中。我们将于<strong>{{decisionDate}}</strong>通过电子邮件发送录取通知。",           
    "thank_you": "谢谢！",           
    "cn_thank_you": "感谢提交你的求职网络申请。你会在一周内收到回复！",           
    "share_with_friends": "与朋友分享",           
    "title": "{{programTitle}}申请",           
    "rejected_primary_message_career_network_only": "很抱歉，我们无法在求职网络中调整你的个人资料。一旦我们能为你提供优质机会，我们会尽快与你联系。<br><br>同时，如果你的个人资料发生变化，或者你想申请我们的工商管理硕士（MBA ）课程，你可以点击下面的按钮重新申请。",           
    "rejected_primary_message_certificates": "很遗憾，你对{{cohortTitle}}课程的申请未经通过。你可以点击下面的按钮重新申请我们的其他课程，或修改你的个人资料，并尝试再次申请证书课程。",           
    "rejected_primary_message_reapply_to_next": "下节课马上就要开始了！即使你未被当前的课程录取，请抓住机会重新申请下一期课程。{{reapplyAfterDeadline}}",           
    "cn_share_with_friends": "同时，与你的朋友和同事分享你的个人推荐链接。",           
    "delete_english_language_proficiency_document": "你确定要删除英语语言能力文件'{{file_name}}'吗？",           
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted": "下节课马上就要开始了！即使你无法加入当前课程，请抓住机会重新申请下一期课程。{{reapplyAfterDeadline}}",           
    "pending_primary_message_rounds": "感谢申请{{brandName}}的课程。我们将在当前提交周期结束时对你的申请进行审查。{{applicationDeadlineWithDecision}}",           
    "pending_primary_message_rolling": "感谢申请{{brandName}}的课程。我们将很快对你的申请进行审核。",           
    "reapply": "重新申请{{brandName}}",           
    "accepted_primary_message_default": "祝贺你已被{{acceptedPrimaryMessageDefaultBrandName}}高级工商管理硕士（{{programTitle}}）课程录取！",           
    "accepted_primary_message_career_network_only": "祝贺你已加入{{brandName}}求职网络！",           
    "deadline_passed_with_pending_application": "感谢申请{{brandName}}的课程。{{applicationDeadlineWithDecision}}",           
    "rejected_primary_message_certificates_enable_quantic": "很遗憾，你对{{cohortTitle}}课程的申请未经通过。你可以点击下面的按钮重新申请我们的其他课程。",           
    "failed_unfortunately": "很遗憾，你未达到毕业要求。你可以保留使用课程库的权限。更多相关信息，请通过<a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>联系我们。",           
    "rejected_primary_message_reapply_to_next_cant_reapply": "尽管您不会加入学位课程，但您可以从{{reapplicationDate}}开始重新申请。与此同时，您将继续免费访问开放课程。",
    "rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply": "尽管您不会加入学位课程，但您可以从{{reapplicationDate}}开始重新申请。与此同时，您将继续免费访问开放课程。"
}
};