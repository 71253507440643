window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.edit_notifications = window.locales.am.settings.edit_notifications || {};window.locales.am.settings.edit_notifications = {...window.locales.am.settings.edit_notifications, ...{
    "email_notifications": "የኢሜይል ማሳወቂያዎች",           
    "update_account_success": "'{{title}}’ ሰብስክሪፕሽን በተሳካ ሁኔታ ዘምኗል።",           
    "notify_email_newsletter": "{{brandName}} ዝመናዎች",           
    "notify_email_newsletter_desc": "ስለ አዳዲስ የኮርስ አቅርቦቶች እና አልፎ አልፎ ዜናዎችን ዝመና ከ {{brandName}} ማግኘት እፈልጋለሁ። <b>ማሳሰቢያ፡</b> ለ {{brandName}} ካመለከቱ እና የ {{brandName}} ዝመናዎችን ላለመቀበል መርጠቅ ከወጡ፣ ከእርስዎ የቅበላ ውሳኔ አሁንም ማሳወቂያዎችን ይቀበላሉ።",           
    "notify_email_newsletter_miya_miya": "Miya Miya ማሻሻያዎች",           
    "notify_email_newsletter_miya_miya_desc": "ስለ Miya Miya አልፎ አልፎ የዜና ዝመናዎችን ማግኘት እፈልጋለሁ።",           
    "notify_employer_communication_updates": "የአሰሪ ግንኙነቶች",           
    "notify_employer_communication_updates_desc": "ለመገናኘት ካሉ ጥያቄዎች እና ከአሰሪዎች የሚመጡ መልእክቶች ጋር ኢሜይሎችን መቀበል እፈልጋለሁ።",           
    "notify_employer_communication_updates_confirm_off": "የአሰሪ ግንኙነቶች ከእርስዎ መገለጫ በምርጫ በመውጣት እንቅስቃሴ አልባ ይሆናሉ።",           
    "notify_employer_communication_updates_confirm_on": "የአሰሪ ግንኙነቶችን ማንቃት የእርስዎ መገለጫ በ Career Network ውስጥ ለአሰሪዎች እንዲታይ ያደርጋል።",           
    "notify_featured_positions_updates": "የ Career Network ዝመናዎች",           
    "daily": "እለታዊ",           
    "weekly": "ሳምንታዊ",           
    "bi_weekly": "በሳምንት ሁለቴ",           
    "never": "በፍጹም",           
    "recommended_positions_info": "የእርስዎን መገለጫ የሚዛመዱ አዳዲስ ቦታዎች ሲገኙ ብቻ ኢሜይል ያገኛሉ።",           
    "enable": "ያንቁ",           
    "disable": "ያሰናክሉ",           
    "pref_allow_community_notifications": "የማህበረሰብ ማሳወቂያዎች",
    "pref_allow_community_notifications_desc": "የተማሪዎች እና የቀድሞ ተማሪዎች የውይይት መልዕክቶች"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.edit_notifications = window.locales.ar.settings.edit_notifications || {};window.locales.ar.settings.edit_notifications = {...window.locales.ar.settings.edit_notifications, ...{
    "email_notifications": "إخطارات البريد الإلكتروني",           
    "update_account_success": "تم تحديث '{{title}}' الاشتراك بنجاح.",           
    "notify_employer_communication_updates": "اتصالات صاحب العمل",
    "notify_employer_communication_updates_desc": "أرغب في تلقي رسائل البريد الإلكتروني مع طلبات للاتصال والرسائل من أرباب العمل.",
    "notify_employer_communication_updates_confirm_off": "عن طريق اختيار الخروج من اتصالات صاحب العمل سيتم إنشاء ملفك الشخصي غير نشط.",
    "notify_employer_communication_updates_confirm_on": "إن تمكين اتصالات أصحاب العمل سيجعل ملفك الشخصي مرئيًا لأصحاب العمل في شبكة التوظيف.",
    "notify_featured_positions_updates": "تحديثات الشبكة المهنية",
    "daily": "اليومي",
    "weekly": "أسبوعي",
    "bi_weekly": "كل أسبوعين",
    "never": "أبدا",
    "recommended_positions_info": "لن تتلقى سوى البريد الإلكتروني عندما تكون هناك مواضع جديدة تتطابق مع ملفك الشخصي.",
    "enable": "تمكين",
    "disable": "تعطيل",
    "notify_email_newsletter": "{{brandName}} التحديثات",
    "notify_email_newsletter_desc": "أرغب في تلقي تحديثات حول عروض الدورات التدريبية الجديدة والأخبار العرضية من {{brandName}} . <b>ملاحظة:</b> إذا كنت قد تقدمت إلى {{brandName}} عدم تلقي {{brandName}} تحديثات ، فستظل تتلقى إشعارًا بقرار القبول.",
    "notify_email_newsletter_miya_miya": "مية مية التحديثات",
    "notify_email_newsletter_miya_miya_desc": "أود تلقي تحديثات إخبارية من حين لآخر حول مية مية.",
    "pref_allow_community_notifications": "إشعارات المجتمع",
    "pref_allow_community_notifications_desc": "رسائل الدردشة من الطلاب والخريجين"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.edit_notifications = window.locales.en.settings.edit_notifications || {};window.locales.en.settings.edit_notifications = {...window.locales.en.settings.edit_notifications, ...{
    "email_notifications": "EMAIL NOTIFICATIONS",
    "update_account_success": "'{{title}}' subscription successfully updated.",
    "notify_email_newsletter": "{{brandName}} Updates",
    "notify_email_newsletter_desc": "I would like to receive updates about new course offerings and occasional news from {{brandName}}. <b>Note:</b> if you have applied to {{brandName}} and opt not to receive {{brandName}} Updates, you will still receive a notification with your admission decision.",
    "notify_email_newsletter_miya_miya": "Miya Miya Updates",
    "notify_email_newsletter_miya_miya_desc": "I would like to receive occasional news updates about Miya Miya.",
    "notify_employer_communication_updates": "Employer Communications",
    "notify_employer_communication_updates_desc": "I would like to receive emails with requests to connect and messages from Employers.",
    "notify_employer_communication_updates_confirm_off": "By opting out of employer communications your profile will be made inactive.",
    "notify_employer_communication_updates_confirm_on": "Enabling employer communications will make your profile visible to employers in the Career Network.",
    "notify_featured_positions_updates": "Career Network Updates",
    "pref_allow_community_notifications": "Community Notifications",
    "pref_allow_community_notifications_desc": "Chat messages from students and alumni",
    "daily": "Daily",
    "weekly": "Weekly",
    "bi_weekly": "Bi-weekly",
    "never": "Never",
    "recommended_positions_info": "You will only receive email when there are new positions that match your profile.",
    "enable": "Enable",
    "disable": "Disable"
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.edit_notifications = window.locales.es.settings.edit_notifications || {};window.locales.es.settings.edit_notifications = {...window.locales.es.settings.edit_notifications, ...{
    "email_notifications": "NOTIFICACIONES DE CORREO ELECTRÓNICO",           
    "update_account_success": "Suscripción a '{{title}}' actualizada con éxito.",           
    "notify_employer_communication_updates": "Comunicaciones del empleador",
    "notify_employer_communication_updates_desc": "Me gustaría recibir correos electrónicos con solicitudes de conexión y mensajes de Empleadores.",
    "notify_employer_communication_updates_confirm_off": "Al inhabilitar las comunicaciones del empleador, su perfil quedará inactivo.",
    "notify_employer_communication_updates_confirm_on": "Permitir las comunicaciones del empleador hará que su perfil sea visible para los empleadores en la Red de Carreras.",
    "notify_featured_positions_updates": "Actualizaciones de la red de carreras",
    "daily": "Diario",
    "weekly": "Semanal",
    "bi_weekly": "Dos veces por semana",
    "never": "Nunca",
    "recommended_positions_info": "Solo recibirás correos electrónicos cuando haya nuevas posiciones que coincidan con tu perfil.",
    "enable": "Habilitar",
    "disable": "Inhabilitar",
    "notify_email_newsletter": "Actualizaciones de {{brandName}}",
    "notify_email_newsletter_desc": "Me gustaría recibir actualizaciones sobre nuevas ofertas de cursos y noticias ocasionales de {{brandName}} . <b>Nota:</b> si ha aplicado a {{brandName}} y opta por no recibir las actualizaciones de {{brandName}} , seguirá recibiendo una notificación con su decisión de admisión.",
    "notify_email_newsletter_miya_miya": "Actualizaciones de Miya Miya",
    "notify_email_newsletter_miya_miya_desc": "Me gustaría recibir actualizaciones ocasionales de noticias sobre Miya Miya.",
    "pref_allow_community_notifications": "Notificaciones de la comunidad",
    "pref_allow_community_notifications_desc": "Mensajes de chat de estudiantes y exalumnos"
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.edit_notifications = window.locales.it.settings.edit_notifications || {};window.locales.it.settings.edit_notifications = {...window.locales.it.settings.edit_notifications, ...{
    "email_notifications": "NOTIFICHE EMAIL",           
    "update_account_success": "Iscrizione '{{title}}' aggiornata con successo.",           
    "notify_employer_communication_updates": "Comunicazioni del datore di lavoro",
    "notify_employer_communication_updates_desc": "Vorrei ricevere e-mail con richieste di connessione e messaggi da parte dei datori di lavoro.",
    "notify_employer_communication_updates_confirm_off": "Disattivando le comunicazioni del datore di lavoro, il tuo profilo sarà reso inattivo.",
    "notify_employer_communication_updates_confirm_on": "L'abilitazione delle comunicazioni dei datori di lavoro renderà visibile il tuo profilo ai datori di lavoro nella rete di carriera.",
    "notify_featured_positions_updates": "Aggiornamenti della rete di carriera",
    "daily": "Quotidiano",
    "weekly": "settimanalmente",
    "bi_weekly": "Bi-settimanale",
    "never": "Mai",
    "recommended_positions_info": "Riceverai solo email quando ci sono nuove posizioni che corrispondono al tuo profilo.",
    "enable": "Abilitare",
    "disable": "disattivare",
    "notify_email_newsletter": "{{brandName}} Aggiornamenti",
    "notify_email_newsletter_desc": "Vorrei ricevere aggiornamenti su nuove offerte di corsi e notizie occasionali da {{brandName}} . <b>Nota:</b> se hai fatto domanda per {{brandName}} e hai {{brandName}} non ricevere gli aggiornamenti {{brandName}} , riceverai comunque una notifica con la tua decisione di ammissione.",
    "notify_email_newsletter_miya_miya": "Aggiornamenti Miya Miya",
    "notify_email_newsletter_miya_miya_desc": "Vorrei ricevere aggiornamenti occasionali su Miya Miya",
    "pref_allow_community_notifications": "Notifiche della comunità",
    "pref_allow_community_notifications_desc": "Messaggi di chat da studenti ed ex studenti"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.edit_notifications = window.locales.zh.settings.edit_notifications || {};window.locales.zh.settings.edit_notifications = {...window.locales.zh.settings.edit_notifications, ...{
    "email_notifications": "电子邮件通知",           
    "update_account_success": "“{{title}}”的订阅已成功更新。",           
    "notify_employer_communication_updates": "雇主交流",           
    "notify_employer_communication_updates_desc": "我希望收到雇主要求联系和发送信息的电子邮件。",           
    "notify_employer_communication_updates_confirm_off": "如选择不与雇主进行沟通，你的个人资料将显示不活跃状态。",           
    "notify_employer_communication_updates_confirm_on": "与雇主沟通可以让雇主在求职网络中看到你的个人资料。",           
    "notify_featured_positions_updates": "求职网络更新",           
    "daily": "每日",           
    "weekly": "每周",           
    "bi_weekly": "每两周",           
    "never": "从不",           
    "recommended_positions_info": "只有当有新职位与你的简历相匹配时，你才会收到电子邮件。",           
    "enable": "允许",           
    "disable": "不允许",           
    "notify_email_newsletter": "{{brandName}}更新",           
    "notify_email_newsletter_desc": "我希望收到来自{{brandName}}的关于新课程服务和特定新闻的更新。<b>注：</b>如果你已申请{{brandName}} ，并且选择不接收{{brandName}}的更新信息，你仍然会收到录取决定通知。",           
    "notify_email_newsletter_miya_miya": "Miya Miya更新",           
    "notify_email_newsletter_miya_miya_desc": "我希望收到Miya Miya相关的特定新闻的更新",           
    "pref_allow_community_notifications": "社区通知",
    "pref_allow_community_notifications_desc": "来自学生和校友的聊天信息"
}
};