window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.sign_out = window.locales.am.settings.sign_out || {};window.locales.am.settings.sign_out = {...window.locales.am.settings.sign_out, ...{
    "signing_out": "በመውጣት ላይ..."           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.sign_out = window.locales.ar.settings.sign_out || {};window.locales.ar.settings.sign_out = {...window.locales.ar.settings.sign_out, ...{
    "signing_out": "جاري تسجيل الخروج"           
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.sign_out = window.locales.en.settings.sign_out || {};window.locales.en.settings.sign_out = {...window.locales.en.settings.sign_out, ...{
    "signing_out": "Signing out..."
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.sign_out = window.locales.es.settings.sign_out || {};window.locales.es.settings.sign_out = {...window.locales.es.settings.sign_out, ...{
    "signing_out": "Desconectándose..."           
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.sign_out = window.locales.it.settings.sign_out || {};window.locales.it.settings.sign_out = {...window.locales.it.settings.sign_out, ...{
    "signing_out": "Esco…"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.sign_out = window.locales.zh.settings.sign_out || {};window.locales.zh.settings.sign_out = {...window.locales.zh.settings.sign_out, ...{
    "signing_out": "正在注销..."           
}
};