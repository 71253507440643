window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.edit_account = window.locales.am.settings.edit_account || {};window.locales.am.settings.edit_account = {...window.locales.am.settings.edit_account, ...{
    "back_to_account": "ወደ መለያ ይመለሱ",           
    "change_password": "የይለፍ ቃል ይለውጡ",           
    "email": "ኢሜይል",           
    "phone": "ስልክ ቁጥር",           
    "name": "ስም",           
    "full_name": "ሙሉ ስም",           
    "nickname": "የመጀመሪያ ስም ወይም ቅፅል ስም",           
    "profile_update_failure": "የእርስዎን መገለጫ በሚዘምንበት ጊዜ ችግር ነበር። እባክዎ መረጃዎን ያረጋግጡ እና ደግመው ይሞክሩ።",           
    "profile_update_success": "የመገለጫ ዝርዝሮች በተሳካ ሁኔታ ዘምነዋል።",           
    "save_changes": "ለውጦችን ያስቀምጡ",           
    "save_change": "ለውጥን ያስቀምጡ",           
    "delete_account": "መለያን ይሰርዙ",           
    "should_you_wish_to_delete": "አካውንትዎን እና ከ {{brandName}} ያሉ የተገናኙ ሁሉንም ውሂብ መሰረዝ ሲፈልጉ፣ እባክዎ በርእሰ መስመር “የመለያ ስረዛ ጥያቄ” በሚል <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a>ን ያግኙ። የመለያ ስረዛ ጥያቄዎች <strong>ዘላቂ እና የማይመለሱ ናቸው</strong>።",           
    "defer_instead": "ከእርስዎ ተጠኝ ጋር ክትትል እያደረጉ ለመቆየት ችግሮች ካጋጠምዎ ወይም በቀላሉ ከ {{brandName}} እረፍት ከፈለጉ፣ የመለያ ስረዛ ሂደቱን ከመቀጠልዎ በፊት ለሌላ ጊዜ ማስተላለፍን መጠየቅ አለብዎት። ለሌላ ጊዜ የማስተላለፍ ሂደትን ለመጀመር በ <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> ላይ “ለሌላ ጊዜ የማስተላለፍ ጥያቄ” በሚል ርእስ ኢሜይል ያድርጉልን። ወደ ሌላ ጊዜ ስለማስተላለፍ <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">እዚህ</a> ጋር የበለጠ ማንበብ ይችላሉ።",           
    "student_id": "የተማሪ መታወቂያ",           
    "copy": "ይቅዱ",           
    "copied": "ተቀድቷል!",           
    "email_forwarding": "ኢሜይል ማስተላለፍ",           
    "email_forwarding_desc_quantic": "እንደ ንቁ {{brandName}} ተማሪ፣ እርስዎ ወደመረጡት አድራሻ የሚያስተላልፈውን የ{{brandName}} ኢሜይል አድራሻ ላይ ተመድበዋል። እርስዎ በዲግሪ ፕሮግራም ውስጥ ሲመዘገቡ ይህ የኢሜይል አድራሻ ንቁ ሆኖ ይቆያል። ከምረቃ በኋላ እንዳይሰራ ይደረጋል። {{brandName}} ተማሪዎች በነጻ ለመመዝገብ ወይም ከተሳታፊ አቅራቢዎች ጋር ቅናሽ የተደረጉባቸውን አገልግሎቶች ለማግኘት ይህን የኢሜይል አድራሻ ሊጠቀሙ ይችላሉ። ለበለጠ መረጃ፣ በተደጋጋሚ የሚጠየቁ ጥያቄዎች ጽሁፍን <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">እዚህ</a>ጋር ይመልከቱ።",           
    "your_student_email_address": "የእርስዎ የ {{brandName}} ኢሜይል አድራሻ፤",           
    "forwarding_address": "አድራሻን ማስተላለፍ፡",           
    "forwarding_address_desc": "ወደ እርስዎ {{brandName}} የኢሜይል አድራሻ የሚላኩ ኢሜይሎች እዚህ ጋር ይተላለፋሉ።",           
    "delete": "ሰርዝ",
    "delete_account_description": "የመለያ ስረዛዎች ቋሚ እና የማይመለሱ ናቸው።",
    "confirm_account_deletion_title": "የመለያ ስረዛን ያረጋግጡ",
    "confirm_account_deletion": "እባክዎ የመለያ ስረዛ ጥያቄዎን ያረጋግጡ። የመለያ ስረዛዎች ቋሚ እና የማይመለሱ ናቸው።",
    "account_scheduled_for_deletion": "መለያ ለመሰረዝ መርሐግብር ተይዞለታል።",
    "manage_digital_exam_signatures": "የዲጂታል ፈተና ፊርማዎችን ያቀናብሩ",
    "update_bio_sig_id": "BioSig-ID ያዘምኑ",
    "create_bio_sig_id": "BioSig-ID ይፍጠሩ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.edit_account = window.locales.ar.settings.edit_account || {};window.locales.ar.settings.edit_account = {...window.locales.ar.settings.edit_account, ...{
    "back_to_account": "العودة إلى الحساب",           
    "change_password": "تغيير كلمة المرور",           
    "email": "البريد الإلكتروني",           
    "phone": "رقم الهاتف",           
    "name": "الاسم",           
    "full_name": "الاسم بالكامل",           
    "profile_update_failure": "لقد كانت هناك مشكلة في تحديث ملفك الشخصي. يرجى التحقق من معلوماتك وإعادة المحاولة مرةً أخرى.",           
    "profile_update_success": "تم تحديث تفاصيل الملف الشخصي بنجاح.",           
    "save_changes": "حفظ التغييرات",           
    "nickname": "الاسم الأول أو الكنية",
    "delete_account": "حذف الحساب",
    "should_you_wish_to_delete": "إذا كنت ترغب في حذف حسابك وجميع البيانات المرتبطة به من {{brandName}} ، فيرجى الاتصال <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a> بعنوان سطر \"طلب حذف الحساب\". يرجى ملاحظة أن طلبات حذف الحساب <strong>دائمة ولا رجعة فيها</strong> .",
    "defer_instead": "إذا كنت تواجه مشكلات في الحفاظ على المسار الصحيح مع الفوج الخاص بك أو كنت بحاجة فقط إلى استراحة من {{brandName}} ، فيجب أن تطلب {{brandName}} قبل متابعة عملية حذف الحساب. ما عليك سوى إرسال بريد إلكتروني إلينا على <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> مع موضوع \"طلب تأجيل\" لبدء عملية التأجيل. يمكنك قراءة المزيد عن التأجيل <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">هنا</a> .",
    "student_id": "هوية الطالب",
    "copy": "نسخ",
    "copied": "نسخ!",
    "save_change": "حفظ التغيير",
    "email_forwarding": "إعادة توجيه البريد الإلكتروني",
    "email_forwarding_desc_quantic": "بصفتك {{brandName}} نشطًا في {{brandName}} ، تم تعيين عنوان بريد إلكتروني {{brandName}} والذي يقوم بإعادة التوجيه إلى عنوان من اختيارك. يظل عنوان البريد الإلكتروني هذا نشطًا أثناء تسجيلك في برنامج للحصول على درجة علمية. سيتم إلغاء تنشيطه بعد التخرج. يمكن لطلاب {{brandName}} استخدام عنوان البريد الإلكتروني هذا للاشتراك في الخدمات المجانية أو المخفضة مع مقدمي الخدمة المشاركين. لمزيد من المعلومات ، راجع مقالة الأسئلة الشائعة <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">هنا</a> .",
    "your_student_email_address": "عنوان بريدك الإلكتروني {{brandName}} :",
    "forwarding_address": "عنوان إعادة التوجيه:",
    "forwarding_address_desc": "ستتم إعادة توجيه رسائل البريد الإلكتروني المرسلة إلى عنوان بريدك الإلكتروني {{brandName}} هنا.",
    "delete": "حذف",           
    "delete_account_description": "عملية حذف الحساب دائمة ولا رجعة فيها.",           
    "confirm_account_deletion_title": "قم بتأكيد حذف الحساب",           
    "confirm_account_deletion": "يرجى تأكيد طلب حذف حسابك. عملية حذف الحساب دائمة ولا رجعة فيها.",           
    "account_scheduled_for_deletion": "تمت جدولة الحساب للحذف.",           
    "manage_digital_exam_signatures": "إدارة التوقيعات الرقمية للاختبار",
    "update_bio_sig_id": "تحديث BioSig-ID",
    "create_bio_sig_id": "إنشاء معرف BioSig"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.edit_account = window.locales.en.settings.edit_account || {};window.locales.en.settings.edit_account = {...window.locales.en.settings.edit_account, ...{
    "back_to_account": "BACK TO ACCOUNT",
    "change_password": "CHANGE PASSWORD",
    "email": "Email",
    "phone": "Phone Number",
    "name": "Name",
    "full_name": "Full Name",
    "nickname": "First Name or Nickname",
    "profile_update_failure": "There was a problem updating your profile. Please check your info and try again.",
    "profile_update_success": "Profile details successfully updated.",
    "save_changes": "SAVE CHANGES",
    "save_change": "SAVE CHANGE",
    "delete_account": "Delete Account",
    "delete": "Delete",
    "delete_account_description": "Account deletions are permanent and irreversible.",
    "confirm_account_deletion_title": "Confirm Account Deletion",
    "confirm_account_deletion": "Please confirm your account deletion request. Account deletions are permanent and irreversible.",
    "account_scheduled_for_deletion": "Account scheduled for deletion.",
    "should_you_wish_to_delete": "Should you wish to delete your account and all associated data from {{brandName}}, please contact <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a> with the subject line \"Account Deletion Request.\" Please note that account deletion requests are <strong>permanent and irreversible</strong>.",
    "defer_instead": "If you're having issues staying on track with your cohort or you simply need a break from {{brandName}}, you should ask for a deferral before proceeding with the account deletion process. Simply email us at <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> with the subject \"Deferral Request\" to begin the deferral process. You can read more about deferring <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">here</a>.",
    "student_id": "Student ID",
    "copy": "Copy",
    "copied": "Copied!",
    "email_forwarding": "Email Forwarding",
    "email_forwarding_desc_quantic": "As an active {{brandName}} student, you have been assigned a {{brandName}} Email Address that forwards to an address of your choosing. This email address remains active while you are enrolled in a degree program. It will be deactivated after graduation. {{brandName}} students may use this email address to sign up for free or discounted services with participating providers. For more information, see the FAQ article <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">here</a>.",
    "your_student_email_address": "Your {{brandName}} Email Address:",
    "forwarding_address": "Forwarding Address:",
    "forwarding_address_desc": "Emails sent to your {{brandName}} Email Address will be forwarded here.",
    "manage_digital_exam_signatures": "Manage digital exam signatures",
    "create_bio_sig_id": "Create BioSig-ID",
    "update_bio_sig_id": "Update BioSig-ID"
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.edit_account = window.locales.es.settings.edit_account || {};window.locales.es.settings.edit_account = {...window.locales.es.settings.edit_account, ...{
    "change_password": "CAMBIAR LA CONTRASEÑA",           
    "email": "Correo electrónico",           
    "profile_update_failure": "Ha habido un problema al actualizar tu perfil. Por favor, verifica tu información y vuelve a intentarlo.",           
    "profile_update_success": "Detalles del perfil actualizados correctamente.",           
    "save_changes": "GUARDAR CAMBIOS",           
    "phone": "Número de teléfono",           
    "name": "Nombre",           
    "full_name": "Nombre completo",           
    "back_to_account": "VOLVER A LA CUENTA",           
    "nickname": "En primer nombre o apodo",
    "delete_account": "Borrar cuenta",
    "should_you_wish_to_delete": "Si desea eliminar su cuenta y todos los datos asociados de {{brandName}} , comuníquese con <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a> con el asunto \"Solicitud de eliminación de cuenta\". Tenga en cuenta que las solicitudes de eliminación de cuenta son <strong>permanentes e irreversibles</strong> .",
    "defer_instead": "Si tiene problemas para seguir el {{brandName}} su cohorte o simplemente necesita un descanso de {{brandName}} , debe solicitar un aplazamiento antes de continuar con el proceso de eliminación de la cuenta. Simplemente envíenos un correo electrónico a <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> con el asunto \"Solicitud de aplazamiento\" para comenzar el proceso de aplazamiento. Puedes leer más sobre aplazar <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">aquí</a> .",
    "student_id": "Identificación del Estudiante",
    "copy": "Copiar",
    "copied": "Copiado!",
    "save_change": "GUARDAR CAMBIOS",
    "email_forwarding": "Reenvío de correo electrónico",
    "email_forwarding_desc_quantic": "Como estudiante activo de {{brandName}} , se le ha asignado una dirección de correo electrónico de {{brandName}} que se reenvía a una dirección de su elección. Esta dirección de correo electrónico permanece activa mientras está inscrito en un programa de grado. Se desactivará después de la graduación. {{brandName}} estudiantes de {{brandName}} pueden usar esta dirección de correo electrónico para inscribirse en servicios gratuitos o con descuento con los proveedores participantes. Para obtener más información, consulte el artículo de preguntas frecuentes <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">aquí</a> .",
    "your_student_email_address": "Su dirección de correo electrónico de {{brandName}} :",
    "forwarding_address": "Dirección de reenvío:",
    "forwarding_address_desc": "Los correos electrónicos enviados a su dirección de correo electrónico de {{brandName}} se reenviarán aquí.",
    "delete": "Borrar",
    "delete_account_description": "Las eliminaciones de cuentas son permanentes e irreversibles.",
    "confirm_account_deletion_title": "Confirmar eliminación de cuenta",
    "confirm_account_deletion": "Confirme su solicitud de eliminación de cuenta. Las eliminaciones de cuentas son permanentes e irreversibles.",
    "account_scheduled_for_deletion": "Cuenta programada para eliminación.",
    "manage_digital_exam_signatures": "Administrar firmas de exámenes digitales",
    "update_bio_sig_id": "Actualizar BioSig-ID",
    "create_bio_sig_id": "Crear BioSig-ID"
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.edit_account = window.locales.it.settings.edit_account || {};window.locales.it.settings.edit_account = {...window.locales.it.settings.edit_account, ...{
    "back_to_account": "TORNA ALL'ACCOUNT",           
    "change_password": "CAMBIA PASSWORD",           
    "email": "Email",           
    "phone": "Numero di telefono",           
    "name": "Nome",           
    "full_name": "Nome e Cognome",           
    "nickname": "Nome di battesimo o Nickname",           
    "profile_update_failure": "Si è verificato un problema durante l'aggiornamento del tuo profilo. Per favore, controlla le informazioni e riprova.",           
    "profile_update_success": "Dettagli profilo aggiornati con successo.",           
    "save_changes": "SALVA MODIFICHE",           
    "delete_account": "Eliminare l'account",
    "should_you_wish_to_delete": "Se desideri eliminare il tuo account e tutti i dati associati da {{brandName}} , contatta <a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a> con l'oggetto \"Richiesta di cancellazione dell'account\". Tieni presente che le richieste di cancellazione dell'account sono <strong>permanenti e irreversibili</strong> .",
    "defer_instead": "Se hai problemi a rimanere in pista con la tua coorte o hai semplicemente bisogno di una pausa da {{brandName}} , dovresti chiedere un differimento prima di procedere con il processo di cancellazione dell'account. Basta inviarci un'e-mail all'indirizzo <a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a> con l'oggetto \"Richiesta di differimento\" per iniziare il processo di differimento. Puoi leggere di più sul differimento <a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">qui</a> .",
    "student_id": "ID studente",
    "copy": "copia",
    "copied": "Copiato!",
    "save_change": "SALVA MODIFICA",
    "email_forwarding": "Inoltro di posta elettronica",
    "email_forwarding_desc_quantic": "In qualità di studente {{brandName}} attivo, ti è stato assegnato un indirizzo email {{brandName}} che inoltra a un indirizzo di tua scelta. Questo indirizzo email resta attivo durante l'iscrizione ad un corso di laurea. Verrà disattivato dopo la laurea. {{brandName}} studenti {{brandName}} possono utilizzare questo indirizzo email per registrarsi a servizi gratuiti o scontati con i fornitori partecipanti. Per ulteriori informazioni, vedere l'articolo delle domande frequenti <a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">qui</a> .",
    "your_student_email_address": "Il tuo indirizzo email {{brandName}} :",
    "forwarding_address": "Indirizzo di spedizione:",
    "forwarding_address_desc": "Le email inviate al tuo indirizzo email {{brandName}} verranno inoltrate qui.",
    "delete": "Elimina",
    "delete_account_description": "Le cancellazioni dell'account sono permanenti e irreversibili.",
    "confirm_account_deletion_title": "Conferma l'eliminazione dell'account",
    "confirm_account_deletion": "Conferma la tua richiesta di cancellazione dell'account. Le cancellazioni dell'account sono permanenti e irreversibili.",
    "account_scheduled_for_deletion": "Account pianificato per l'eliminazione.",
    "manage_digital_exam_signatures": "Gestisci le firme digitali degli esami",
    "update_bio_sig_id": "Aggiorna l'ID BioSig",
    "create_bio_sig_id": "Crea BioSig-ID"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.edit_account = window.locales.zh.settings.edit_account || {};window.locales.zh.settings.edit_account = {...window.locales.zh.settings.edit_account, ...{
    "change_password": "更改密码",           
    "email": "电子邮件",           
    "profile_update_failure": "更新您的资料时出现问题。请检查您的信息，然后重试。",           
    "profile_update_success": "资料详细信息已成功更新。",           
    "save_changes": "保存更改",           
    "phone": "电话号码",           
    "name": "名称",           
    "full_name": "全名",           
    "back_to_account": "返回账户",           
    "nickname": "名字或昵称",           
    "delete_account": "删除账号",           
    "should_you_wish_to_delete": "如果你想删除自己的账号和{{brandName}}中的所有相关数据，请发送标题行为“账号删除请求”的邮件至<a href=\"mailto:{{brandEmail}}?subject=Account%20Deletion%20Request\">{{brandEmail}}</a>与我们进行联系。请注意，账号删除后<strong>永久有效且不可撤销</strong>。",           
    "defer_instead": "如果你无法与同期组保持联系，或只是想暂停{{brandName}}计划，你应该在继续删除账号之前申请延期。只需发送主题为“延期请求”的电子邮件至<a href=\"mailto:{{brandEmail}}?subject=Deferral%20Request\">{{brandEmail}}</a>以开始延期流程。点击<a ng-click=\"loadFaqUrl('/help/what-does-it-mean-to-defer', '_blank')\">此处</a>了解更多关于延期的信息。",           
    "student_id": "学员证号",           
    "copy": "复制",           
    "copied": "已复制！",           
    "save_change": "保存变化",           
    "email_forwarding": "邮件转发",           
    "email_forwarding_desc_quantic": "作为活跃的{{brandName}}学员，我们已为你分配一个 {{brandName}}邮箱地址，可转发到你选择的地址。当你注册学位课程时，仍可使用该邮箱地址。它将在你毕业后失效。{{brandName}}学员可以使用此电子邮件地址注册相关供应商提供的免费或折扣服务。欲了解更多信息，请点击<a ng-click=\"loadFaqUrl('/help/student-discounts', '_blank')\">此处</a>查看常见问题文章。",           
    "your_student_email_address": "你的{{brandName}}邮箱地址：",           
    "forwarding_address": "转发地址：",           
    "forwarding_address_desc": "发送到你{{brandName}}邮箱地址的电子邮件将转发到这里。",           
    "delete": "删除",
    "delete_account_description": "帐户删除是永久且不可逆转的。",
    "confirm_account_deletion_title": "确认帐户删除",
    "confirm_account_deletion": "请确认您的帐户删除请求。帐户删除是永久且不可逆转的。",
    "account_scheduled_for_deletion": "计划删除的帐户。",
    "manage_digital_exam_signatures": "管理数字考试签名",
    "update_bio_sig_id": "更新 BioSig-ID",
    "create_bio_sig_id": "创建 BioSig-ID"
}
};