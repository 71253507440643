window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.paid_program_welcome_package = window.locales.am.settings.paid_program_welcome_package || {};window.locales.am.settings.paid_program_welcome_package = {...window.locales.am.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "ከተመዘገቡ የክፍል ልጆች ጥቂቶቹ እነዚህ ናቸው።",           
    "headline_video": "የእኛ ተማሪዎች የ {{brandName}} ልዩነትን ይገልጻሉ።",           
    "download": "አውርድ",           
    "program_guide": "የፕሮግራም መመሪያ",           
    "headline_meet_additional_students": "ተጨማሪ ተማሪዎችን ያግኙ እና ሁሉንም ዝርዝሮች ያግኙ።",           
    "headline_these_students_registered": "እነዚህ ተማሪዎች በ {{programTitle}} ውስጥ በቅርቡ ተመዝግበዋል",           
    "headline_these_students_registered_temp": "እነዚህ ተማሪዎች ለ {{program}} በቅርቡ ተመዝግበዋል",
    "headline_these_students_registered_generic": "እነዚህ ተማሪዎች በቅርቡ ተመዝግበዋል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.paid_program_welcome_package = window.locales.ar.settings.paid_program_welcome_package || {};window.locales.ar.settings.paid_program_welcome_package = {...window.locales.ar.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "هنا عدد قليل من زملائك المسجلين.",
    "headline_video": "يوضح {{brandName}} الفرق {{brandName}}",
    "download": "تحميل",
    "program_guide": "دليل البرنامج",
    "headline_meet_additional_students": "قابل طلابًا إضافيين واحصل على جميع التفاصيل",
    "headline_these_students_registered": "تم تسجيل هؤلاء الطلاب مؤخرًا في {{programTitle}}",
    "headline_these_students_registered_temp": "لقد سجل هؤلاء الطلاب مؤخرًا في {{program}}",
    "headline_these_students_registered_generic": "لقد سجل هؤلاء الطلاب مؤخرًا"
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.paid_program_welcome_package = window.locales.en.settings.paid_program_welcome_package || {};window.locales.en.settings.paid_program_welcome_package = {...window.locales.en.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "Here are a few of your registered classmates.",
    "headline_video": "Our students explain the {{brandName}} difference",
    "download": "Download",
    "program_guide": "Program Guide",
    "headline_meet_additional_students": "Meet additional students and get all the details",
    "headline_these_students_registered": "These students have recently registered in the {{programTitle}}",
    "headline_these_students_registered_temp": "These students have recently registered for the {{program}}",
    "headline_these_students_registered_generic": "These students have recently registered"
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.paid_program_welcome_package = window.locales.es.settings.paid_program_welcome_package || {};window.locales.es.settings.paid_program_welcome_package = {...window.locales.es.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "Aquí están algunos de sus compañeros de clase registrados.",
    "download": "Descargar",
    "program_guide": "Guía del programa",
    "headline_meet_additional_students": "Conoce estudiantes adicionales y obtén todos los detalles",
    "headline_video": "Nuestros estudiantes explican la diferencia {{brandName}}",
    "headline_these_students_registered": "Estos estudiantes se han registrado recientemente en {{programTitle}}",
    "headline_these_students_registered_temp": "Estos estudiantes se han registrado recientemente en el {{program}}",
    "headline_these_students_registered_generic": "Estos estudiantes se han registrado recientemente"
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.paid_program_welcome_package = window.locales.it.settings.paid_program_welcome_package || {};window.locales.it.settings.paid_program_welcome_package = {...window.locales.it.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "Ecco alcuni dei tuoi compagni di classe registrati.",
    "headline_video": "I nostri studenti spiegano la differenza di {{brandName}}",
    "download": "Scarica",
    "program_guide": "Guida ai programmi",
    "headline_meet_additional_students": "Incontra altri studenti e ottieni tutti i dettagli",
    "headline_these_students_registered": "Questi studenti si sono registrati di recente in {{programTitle}}",
    "headline_these_students_registered_temp": "Questi studenti si sono registrati di recente al {{program}}",
    "headline_these_students_registered_generic": "Questi studenti si sono recentemente iscritti"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.paid_program_welcome_package = window.locales.zh.settings.paid_program_welcome_package || {};window.locales.zh.settings.paid_program_welcome_package = {...window.locales.zh.settings.paid_program_welcome_package, ...{
    "headline_few_of_your_registered_classmates": "这是一些你已进行注册的同学。",           
    "headline_video": "我们的学员可阐明{{brandName}}的区别",           
    "download": "下载",           
    "program_guide": "课程指南",           
    "headline_meet_additional_students": "与其他学员见面并了解所有细节",           
    "headline_these_students_registered": "这些学员最近已注册{{programTitle}}",           
    "headline_these_students_registered_temp": "这些学生最近注册了{{program}}",
    "headline_these_students_registered_generic": "这些学生最近已注册"
}
};